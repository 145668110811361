/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reply': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 9q4.875.703 7.523 3.727t3.492 7.289q-3.609-5.109-11.016-5.109v4.078l-6.984-6.984 6.984-6.984v3.984z"/>'
  }
})
