/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_mma': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9.984v-3H6.984v3H15zm3-3q.422 0 .703.305t.281.727v2.766q0 .281-.047.422l-.797 3.984q-.188.797-.938.797H6.796q-.797 0-.938-.797l-.797-3.984q-.047-.141-.047-.422V5.016q0-.797.586-1.406t1.383-.609h8.016q.797 0 1.406.609t.609 1.406v3q0-.422.281-.727t.703-.305zM6.984 20.016v-3h10.031v3q0 .422-.305.703t-.727.281H8.014q-.422 0-.727-.281t-.305-.703z"/>'
  }
})
