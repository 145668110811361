/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contactless': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.672 17.344q1.313-2.672 1.313-5.344 0-2.719-1.313-5.344l-1.359.703Q14.485 9.656 14.485 12t-1.172 4.688zm-3.141-1.313q.984-1.734.984-3.891 0-2.203-.984-4.078l-1.313.656q.797 1.688.797 3.422 0 1.688-.797 3.141zm-3.093-1.593q.563-1.219.563-2.484 0-1.125-.563-2.391l-1.359.609q.422.844.422 1.781 0 .984-.422 1.875zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
