/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_downward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 12L12 20.016 3.984 12l1.453-1.406 5.578 5.578V3.984h1.969v12.188l5.625-5.578z"/>'
  }
})
