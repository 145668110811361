/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 5.016H18v13.969h-3.984V5.016zM6 18.984V5.015h3.984v13.969H6z"/>'
  }
})
