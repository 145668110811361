/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drag_handle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 15v-2.016h16.031V15H3.984zm16.032-6v2.016H3.985V9h16.031z"/>'
  }
})
