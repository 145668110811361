/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table_view': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984H9q-.844 0-1.43.586T6.984 9v9.984q0 .844.586 1.43T9 21h9.984q.844 0 1.43-.586t.586-1.43V9q0-.844-.586-1.43t-1.43-.586zm0 2.016v2.016H9V9h9.984zm-6 6v-2.016H15V15h-2.016zM15 17.016v1.969h-2.016v-1.969H15zM11.016 15H9v-2.016h2.016V15zm6-2.016h1.969V15h-1.969v-2.016zM9 17.016h2.016v1.969H9v-1.969zm8.016 1.968v-1.969h1.969v1.969h-1.969zM6 17.016h-.984q-.844 0-1.43-.586T3 15V5.016q0-.844.586-1.43T5.016 3H15q.844 0 1.43.586t.586 1.43V6H15v-.984H5.016V15H6v2.016z"/>'
  }
})
