/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'motion_photos_paused': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12q0 2.063-.773 3.867t-2.156 3.188-3.188 2.156-3.867.773-3.867-.773-3.188-2.156-2.156-3.188T2.016 12q0-.891.141-1.734t.422-1.641l1.922.656Q3.985 10.594 3.985 12q0 1.641.633 3.094t1.734 2.555 2.555 1.734 3.094.633 3.094-.633 2.555-1.734 1.734-2.555.633-3.094-.633-3.094-1.734-2.555-2.555-1.734-3.094-.633q-1.406 0-2.672.516l-.703-1.922q.797-.281 1.641-.422t1.734-.141q2.063 0 3.867.773t3.188 2.156 2.156 3.188.773 3.867zm-16.5-8.016q-.609 0-1.055.445t-.445 1.055q0 .656.445 1.078t1.055.422q.656 0 1.078-.422t.422-1.078q0-.609-.422-1.055t-1.078-.445zM18 12q0 1.641-.82 3T15 17.18 12 18t-3-.82T6.82 15 6 12t.82-3T9 6.82 12 6t3 .82T17.18 9t.82 3zm-6.984-3H9v6h2.016V9zM15 9h-2.016v6H15V9z"/>'
  }
})
