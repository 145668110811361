/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'skip_next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 6H18v12h-2.016V6zM6 18V6l8.484 6z"/>'
  }
})
