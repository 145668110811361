/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account_balance_wallet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 13.5q.609 0 1.055-.422T17.484 12t-.445-1.078-1.055-.422-1.055.422T14.484 12t.445 1.078 1.055.422zM12 15.984V8.015h9.984v7.969H12zM21 18v.984q0 .797-.609 1.406t-1.406.609H5.016q-.844 0-1.43-.586T3 18.983V5.014q0-.844.586-1.43t1.43-.586h13.969q.797 0 1.406.609T21 5.013v.984h-9q-.844 0-1.43.586t-.586 1.43v7.969q0 .844.586 1.43t1.43.586h9z"/>'
  }
})
