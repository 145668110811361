/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin_panel_settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 11.016q.234 0 .492.023t.492.07V6.281L10.5 3 3 6.281v4.922q0 1.688.563 3.281t1.57 2.93 2.391 2.273T10.501 21q.422-.094.82-.234t.773-.328q-.516-.703-.797-1.594t-.281-1.828q0-1.688.797-3.047t2.156-2.156 3.047-.797zm0 1.968q-1.125 0-2.039.539t-1.453 1.453-.539 2.039q0 1.078.539 1.992t1.453 1.453 2.039.539q1.078 0 1.992-.539t1.453-1.453.539-1.992q0-1.125-.539-2.039t-1.453-1.453-1.992-.539zm0 1.407q.469 0 .797.328t.328.797q0 .422-.352.773t-.773.352q-.469 0-.797-.352t-.328-.773q0-.469.328-.797t.797-.328zm0 5.343q-.703 0-1.289-.305t-.961-.867q0-.328.422-.586t.938-.375.891-.117q.328 0 .867.117t.938.375.398.586q-.375.563-.938.867t-1.266.305z"/>'
  }
})
