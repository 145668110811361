/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'science': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.781 18.422l-5.766-7.734V6.516l1.313-1.688q.234-.281.094-.563t-.469-.281H9.047q-.328 0-.469.281t.094.563l1.313 1.688v4.172l-5.766 7.734q-.281.328-.234.68t.328.633.703.281h13.969q.422 0 .703-.281t.328-.633-.234-.68z"/>'
  }
})
