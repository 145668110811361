/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wrong_location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 9.984V3.281Q12.985 3 12 3q-1.594 0-3.023.586T6.422 5.25 4.641 7.828t-.656 3.375q0 4.969 8.016 11.813 8.016-6.844 8.016-11.813 0-.328-.023-.609t-.07-.609h-5.906zm-2.016 3q-.844 0-1.43-.586t-.586-1.383q0-.844.586-1.43T12 8.999t1.43.586.586 1.43q0 .797-.586 1.383t-1.43.586zM22.547 2.859l-1.406-1.406-2.156 2.156-2.109-2.156-1.406 1.406 2.109 2.156-2.109 2.109 1.406 1.406 2.109-2.109 2.156 2.109 1.406-1.406-2.156-2.109z"/>'
  }
})
