/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tap_and_play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 1.031q.797 0 1.383.586T18.985 3v17.016q0 .797-.586 1.383t-1.383.586h-2.109q-.141-2.016-.938-3.984h3.047V5.017H6.985v6q-1.172-.516-1.969-.656V3.002q0-.797.586-1.406T6.985.987zM2.016 12q4.547 0 7.758 3.211t3.211 7.805h-1.969q0-3.703-2.648-6.352t-6.352-2.648V12zm0 8.016q1.219 0 2.109.891t.891 2.109h-3v-3zm0-4.032q2.906 0 4.945 2.063T9 23.016H6.984q0-2.063-1.453-3.539T2.015 18v-2.016z"/>'
  }
})
