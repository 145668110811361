/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'psychology': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 8.578q-.563 0-.984.422t-.422.984q0 .609.422 1.031t.984.422q.609 0 1.031-.422t.422-1.031q0-.563-.422-.984t-1.031-.422zm0-5.578q-1.406 0-2.625.516t-2.18 1.43-1.523 2.109T6 9.657l-1.922 2.531q-.188.281-.047.539t.469.258H6v3q0 .844.586 1.43t1.43.586H9v3h6.984v-4.688q1.781-.844 2.906-2.531t1.125-3.797q0-1.453-.563-2.719t-1.523-2.227-2.227-1.5T12.983 3zm3 6.984v.422l.844.656q.094.094.047.234l-.797 1.406q-.094.141-.281.094l-.984-.422q-.281.234-.656.375l-.141 1.078q-.047.188-.234.188h-1.594q-.141 0-.188-.188l-.141-1.078q-.375-.141-.703-.375l-.984.422q-.141.047-.234-.094l-.797-1.406q-.094-.141.047-.234l.844-.656q0-.094-.023-.211t-.023-.211.023-.188.023-.188l-.844-.656q-.141-.094-.047-.281l.797-1.359q.094-.141.234-.094l1.031.422q.328-.281.656-.422L12 6.187q.047-.188.188-.188h1.594q.188 0 .234.188l.141 1.031q.328.141.656.422l.984-.422q.188-.047.281.094l.797 1.359q.094.188-.047.281l-.891.656q.047.141.047.375z"/>'
  }
})
