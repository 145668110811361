/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scatter_plot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.594 17.578q0-1.219.891-2.109t2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891-2.109-.891-.891-2.109zM8.016 6q0-1.219.891-2.109T11.016 3t2.109.891T14.016 6t-.891 2.109T11.016 9t-2.109-.891T8.016 6zm-4.032 8.016q0-1.219.891-2.109t2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891-2.109-.891-.891-2.109z"/>'
  }
})
