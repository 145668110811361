/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'superscript': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 6.984h-1.969v1.031h3v.984h-4.031V6.983q0-.375.305-.68t.727-.305h1.969v-.984h-3V3.983h3q.422 0 .727.305t.305.727v.984q0 .422-.305.703t-.727.281zM5.859 20.016h2.672l3.422-5.438h.094l3.422 5.438h2.672l-4.688-7.266L17.812 6H15.14l-3.094 4.969h-.094L8.858 6H6.186l4.313 6.75z"/>'
  }
})
