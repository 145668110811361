/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'offline_pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.313 14.016l6.703-6.703-1.406-1.406-5.297 5.297-1.922-1.922-1.406 1.406zM17.016 18v-2.016H6.985V18h10.031zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
