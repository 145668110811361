/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copyright': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm-.141 7.125q-1.875 0-1.875 2.719v.281q0 2.719 1.875 2.719.703 0 1.172-.398t.469-1.008h1.781q0 1.172-1.031 2.063-.984.844-2.391.844-1.875 0-2.859-1.125t-.984-3.094v-.281q0-1.922.938-3 1.125-1.266 2.906-1.266 1.547 0 2.438.891.984.984.984 2.297h-1.781q0-.328-.141-.609-.234-.469-.328-.563-.469-.469-1.172-.469z"/>'
  }
})
