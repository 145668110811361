/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_lock_landscape': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.781 9.984v1.031h2.438V9.984q0-.469-.352-.82T12 8.812t-.867.352-.352.82zm-.797 6q-.422 0-.703-.281T9 15v-3q0-.422.281-.703t.703-.281V9.985q0-.844.586-1.406T12 8.016t1.43.563.586 1.406v1.031q.422 0 .703.281T15 12v3q0 .422-.281.703t-.703.281H9.985zm9 1.032V6.985H5.015v10.031h13.969zm2.016-12q.797 0 1.406.586t.609 1.383v10.031q0 .797-.609 1.383T21 18.985H3q-.797 0-1.406-.586t-.609-1.383V6.985q0-.797.609-1.383T3 5.016h18z"/>'
  }
})
