/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pan_tool': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 5.484v14.531q0 1.641-1.195 2.813T18.985 24h-7.266q-1.688 0-2.859-1.172L.985 14.812q1.266-1.219 1.313-1.219.328-.281.797-.281.328 0 .609.141l4.313 2.438V3.985q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055v7.031h.984V1.5q0-.656.422-1.078T13.501 0t1.078.422.422 1.078v9.516h.984V2.485q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055v8.531h1.031V5.485q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055z"/>'
  }
})
