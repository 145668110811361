/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not_accessible': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 11.063l-3.422-3.422q.516-.563 1.219-.609.375-.047.656.023t.563.211l.281.188.328.281 1.313 1.453q.703.797 1.805 1.313t2.227.516v1.969q-1.313 0-2.672-.563t-2.297-1.359zM12 6q.844 0 1.43-.586t.586-1.43q0-.797-.586-1.383T12 2.015t-1.43.586-.586 1.383q0 .844.586 1.43T12 6zM2.813 2.813L1.407 4.219l8.578 8.625V15q0 .844.586 1.43t1.43.586h2.156l5.625 5.578 1.406-1.406zm7.171 17.203q-.797 0-1.477-.422t-1.102-1.102-.422-1.477q0-.984.563-1.758t1.453-1.102v-2.063q-1.125.234-2.039.938t-1.43 1.734-.516 2.25q0 1.359.656 2.508t1.805 1.805 2.508.656q1.219 0 2.25-.516t1.734-1.43.938-2.039h-2.063q-.328.891-1.102 1.453t-1.758.563z"/>'
  }
})
