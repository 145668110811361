/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_photosphere': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 8.531q.516.281.516.797v5.344q0 .563-.516.844-.469.234-1.406.609-1.031 2.297-2.906 3.773t-4.031 1.875-4.313 0-4.031-1.875-2.906-3.773q-.469-.188-1.406-.656-.516-.234-.516-.797V9.328q0-.563.516-.797.938-.469 1.406-.656Q4.079 5.25 6.54 3.633t5.461-1.617 5.461 1.617 3.633 4.242q.469.188 1.406.656zM12 3.797q-3.984 0-6.422 3.141 6.375-1.875 12.844 0Q15.891 3.797 12 3.797zm0 16.406q1.641 0 3.516-.914t2.906-2.227q-6.516 1.875-12.844 0Q8.062 20.203 12 20.203zm9.422-5.953v-4.5q-9.469-4.453-18.844 0v4.5q.047 0 .141.047t.141.047q3.656 1.828 9.141 1.828 3.563 0 6.047-.75 2.344-.656 3.375-1.172z"/>'
  }
})
