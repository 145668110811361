/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkroom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.609 18.188l-8.625-6.422v-.938q.844-.234 1.453-.844t.891-1.43.094-1.758q-.188-.984-.914-1.734t-1.711-.984q-1.125-.234-2.109.164T9.094 5.531 8.485 7.5h2.016q0-.609.445-1.055T12.001 6t1.055.445.445 1.055-.445 1.055T11.954 9q-.375 0-.656.281t-.281.703v1.781l-8.625 6.422q-.375.281-.398.727t.258.773.75.328h18q.469 0 .75-.328t.258-.773-.398-.727zM6 18l6-4.5 6 4.5H6z"/>'
  }
})
