/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'offline_bolt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.484 20.016l4.875-9.75h-3.375V3.985l-4.969 9.75h3.469v6.281zm.516-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
