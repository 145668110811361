/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rowing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 21l-3 3-3-3v-1.5l-7.078-7.078q-.328.047-.938.047v-2.156q1.219.047 2.531-.539t2.156-1.477l1.406-1.547q.75-.75 1.641-.75h.047q.891 0 1.57.656t.68 1.594v5.766q0 1.219-.938 2.156l-3.563-3.563v-2.297q-.938.797-2.297 1.406l6.281 6.281h1.5zM15 .984q.797 0 1.406.609t.609 1.406-.609 1.406T15 5.014t-1.406-.609-.609-1.406.609-1.406T15 .984zm-6.516 13.5l2.531 2.531H8.999l-3.516 3.469-1.5-1.5z"/>'
  }
})
