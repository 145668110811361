/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cases': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 9v11.016h16.031q0 .844-.586 1.406t-1.43.563H3.983q-.844 0-1.406-.563t-.563-1.406V9h1.969zm12-3v-.984q0-.375-.328-.703T15 3.985h-3q-.375 0-.68.305t-.305.727v.984h4.969zM9 6V3.984l2.016-1.969h4.969l2.016 1.969V6h3.984v11.016q0 .797-.586 1.383t-1.383.586H6.985q-.797 0-1.383-.586t-.586-1.383V6H9z"/>'
  }
})
