/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vignette': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18q3.328 0 5.672-1.758T20.016 12t-2.344-4.242T12 6 6.328 7.758 3.984 12t2.344 4.242T12 18zm9-15q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18z"/>'
  }
})
