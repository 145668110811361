/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause_circle_filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 15.984V8.015h-2.016v7.969H15zm-3.984 0V8.015H9v7.969h2.016zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
