/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'verified_user': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 17.016L18 9l-1.406-1.406-6.609 6.563-2.578-2.578-1.406 1.406zM12 .984l9 4.031v6q0 4.172-2.578 7.617T12 23.015q-3.844-.938-6.422-4.383T3 11.015v-6z"/>'
  }
})
