/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'favorite_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.094 18.563q2.25-2.016 3.328-3.047t2.344-2.438 1.758-2.484.492-2.109q0-1.5-1.008-2.484T16.5 5.017q-1.172 0-2.18.656t-1.383 1.688h-1.875q-.375-1.031-1.383-1.688t-2.18-.656q-1.5 0-2.508.984T3.983 8.485q0 1.031.492 2.109t1.758 2.484 2.344 2.438 3.328 3.047l.094.094zM16.5 3q2.344 0 3.914 1.594t1.57 3.891q0 1.359-.516 2.648t-1.898 2.883-2.508 2.672-3.609 3.328L12 21.329l-1.453-1.266q-3.234-2.906-4.664-4.359t-2.648-3.422-1.219-3.797q0-2.297 1.57-3.891T7.5 3q2.719 0 4.5 2.109Q13.781 3 16.5 3z"/>'
  }
})
