/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'history': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 8.016h1.5v4.219l3.516 2.109-.75 1.219L12 12.985V8.016zM12.984 3q3.703 0 6.352 2.625T21.984 12t-2.648 6.375T12.984 21q-1.547 0-3.398-.773t-2.93-1.852l1.406-1.453q2.063 2.063 4.922 2.063 2.906 0 4.969-2.039t2.063-4.945-2.063-4.945-4.969-2.039-4.945 2.039T6 12.001h3l-4.031 4.031-.094-.141L.984 12h3q0-3.75 2.648-6.375T12.984 3z"/>'
  }
})
