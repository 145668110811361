/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'record_voice_over': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.063 2.016q2.906 3.047 2.906 7.055t-2.906 6.914l-1.641-1.594Q20.485 12 20.485 8.93t-2.063-5.32zm-3.282 3.328q1.5 1.641 1.5 3.703t-1.5 3.563l-1.688-1.688q.656-.891.656-1.945t-.656-1.945zM9 15q2.531 0 5.273 1.102t2.742 2.883v2.016H.984v-2.016q0-1.781 2.742-2.883T8.999 15zM5.016 9q0-1.641 1.172-2.813t2.813-1.172 2.813 1.172T12.986 9t-1.172 2.813-2.813 1.172-2.813-1.172T5.016 9z"/>'
  }
})
