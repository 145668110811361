/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_cricket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.484q0-1.453 1.031-2.461t2.484-1.008 2.461 1.008 1.008 2.461-1.008 2.484-2.461 1.031-2.484-1.031T15 5.484zm-.656 12.282l1.406-1.406 4.266 4.219-1.453 1.406zm.703-4.969q.281.281.281.703t-.281.703l-2.813 2.859q-.281.281-.703.281t-.703-.281L2.297 8.578q-.281-.281-.281-.703t.281-.703l2.859-2.859q.281-.281.703-.281t.703.281z"/>'
  }
})
