/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check_box': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563L5.015 12zm9-14.016q.844 0 1.43.586T21 5.016v13.969q0 .844-.586 1.43t-1.43.586H5.015q-.844 0-1.43-.586t-.586-1.43V5.016q0-.844.586-1.43T5.015 3h13.969z"/>'
  }
})
