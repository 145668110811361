/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3.984V6H5.015V3.984h3.469L9.515 3h4.969l1.031.984h3.469zM6 18.984v-12h12v12q0 .797-.609 1.406t-1.406.609H8.016q-.797 0-1.406-.609t-.609-1.406z"/>'
  }
})
