/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'content_cut': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3h3v.984L15 11.015l-2.016-2.016zM12 12.516q.516 0 .516-.516T12 11.484t-.516.516.516.516zm-6 7.5q.797 0 1.406-.586T8.015 18t-.609-1.43T6 15.984t-1.406.586T3.985 18t.609 1.43T6 20.016zm0-12q.797 0 1.406-.586T8.015 6t-.609-1.43T6 3.984t-1.406.586T3.985 6t.609 1.43T6 8.016zm3.656-.375l12.328 12.375V21h-3L12 14.016 9.656 16.36q.328.703.328 1.641 0 1.641-1.172 2.813t-2.813 1.172-2.813-1.172-1.172-2.813 1.172-2.813 2.813-1.172q.938 0 1.641.328L9.984 12 7.64 9.656q-.703.328-1.641.328-1.641 0-2.813-1.172T2.014 5.999t1.172-2.813 2.813-1.172 2.813 1.172 1.172 2.813q0 .938-.328 1.641z"/>'
  }
})
