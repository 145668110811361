/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shop_two': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15l5.484-3.984-5.484-3V15zm0-12v2.016h3.984V3H12zm6 2.016h5.016v10.969q0 .844-.586 1.43t-1.43.586H6.984q-.844 0-1.406-.586t-.563-1.43V5.016h4.969V3q0-.844.586-1.43T12 .984h3.984q.844 0 1.43.586T18 3v2.016zM3 9v11.016h15.984q0 .844-.563 1.406t-1.406.563H2.999q-.844 0-1.43-.563t-.586-1.406V9h2.016z"/>'
  }
})
