/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'military_tech': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 10.453V2.015H6.985v8.438q0 .563.516.844l4.172 2.484-.984 2.344-3.422.328 2.578 2.203-.797 3.328 2.953-1.734 2.953 1.734-.797-3.328 2.578-2.203-3.422-.328-.984-2.344 4.172-2.484q.516-.281.516-.844zm-4.032 1.781l-.984.609-.984-.609V3h1.969v9.234z"/>'
  }
})
