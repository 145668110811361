/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 21.984q-.844 0-1.43-.586t-.586-1.383h3.984q0 .844-.563 1.406t-1.406.563zm6-10.968v4.969l2.016 2.016v.984H3.985v-.984l2.016-2.016v-4.969q0-2.344 1.195-4.078t3.305-2.25v-.703q0-.609.422-1.055t1.078-.445 1.078.445.422 1.055v.703q2.109.516 3.305 2.25t1.195 4.078zm1.969-.516q-.094-1.688-1.125-3.563t-2.391-2.859l1.406-1.406q3.891 3 4.125 7.828h-2.016zM7.594 4.078q-1.359.984-2.414 2.859T4.032 10.5H2.016q.234-4.828 4.125-7.828z"/>'
  }
})
