/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_esports': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984q.422 0 .703-.281T18 12t-.281-.703-.703-.281-.727.281-.305.703.305.703.727.281zm-2.016-3q.422 0 .703-.281T15.984 9t-.281-.703T15 8.016t-.703.281-.281.703.281.703.703.281zm-3.984 1.032V9.985H9V8.016h-.984v1.969H6v1.031h2.016v1.969H9v-1.969h2.016zm10.547 5.062q.188 1.172-.586 2.039t-1.945.867q-1.031 0-1.781-.75l-2.25-2.25h-6l-2.25 2.25q-.75.75-1.828.75t-1.805-.727-.727-1.805q0-.047.023-.188t.023-.188l1.078-7.641q.188-1.453 1.313-2.438t2.625-.984h9.094q1.453 0 2.578 1.008t1.359 2.414z"/>'
  }
})
