/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_album': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 18.984h12l-3.844-5.109-3 3.844L9 15.141zm0-15V12l2.484-1.5 2.531 1.5V3.984H5.999zm12-1.968q.797 0 1.406.586t.609 1.383v16.031q0 .797-.609 1.383T18 21.985H6q-.797 0-1.406-.586t-.609-1.383V3.985q0-.797.609-1.383T6 2.016h12z"/>'
  }
})
