/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12.984h3L12 8.015l-5.016 4.969h3v4.031h4.031v-4.031zm5.343-2.953q1.922.141 3.281 1.57t1.359 3.398q0 2.063-1.477 3.539t-3.539 1.477H5.999q-2.484 0-4.242-1.758t-1.758-4.242q0-2.203 1.57-3.961t3.773-1.992q.984-1.828 2.766-2.953t3.891-1.125q2.531 0 4.711 1.781t2.648 4.266z"/>'
  }
})
