/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mark_email_unread': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 9v9q0 .844-.586 1.43t-1.383.586H3.984q-.797 0-1.383-.586T2.015 18V6q0-.844.586-1.43t1.383-.586h10.125q-.047.234-.07.492t-.023.539q0 1.078.445 2.039t1.195 1.664L12 11.015 3.984 5.999v2.016L12 12.984l5.297-3.281q.422.141.844.211t.844.07q.844 0 1.617-.258t1.383-.727zm-6-3.984q0 .797.422 1.477t1.102 1.102 1.477.422q.844 0 1.523-.422t1.078-1.102.398-1.477q0-.844-.398-1.523t-1.078-1.078-1.523-.398q-.797 0-1.477.398t-1.102 1.078-.422 1.523z"/>'
  }
})
