/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memory': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 17.016V6.985H6.985v10.031h10.031zm3.984-6h-2.016v1.969H21v2.016h-2.016v2.016q0 .797-.586 1.383t-1.383.586h-2.016v2.016h-2.016v-2.016h-1.969v2.016H8.998v-2.016H6.982q-.797 0-1.383-.586t-.586-1.383v-2.016H2.997v-2.016h2.016v-1.969H2.997V9h2.016V6.984q0-.797.586-1.383t1.383-.586h2.016V2.999h2.016v2.016h1.969V2.999h2.016v2.016h2.016q.797 0 1.383.586t.586 1.383V9H21v2.016zm-8.016 1.968v-1.969h-1.969v1.969h1.969zM15 9v6H9V9h6z"/>'
  }
})
