/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stars': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 18l-1.125-4.828 3.75-3.234-4.922-.422L12 5.016l-1.922 4.547-4.922.375 3.75 3.234L7.781 18 12 15.469zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
