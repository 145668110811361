/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'engineering': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 15q-.797 0-1.852.164t-2.133.492-1.992.797-1.477 1.102-.563 1.43v2.016h16.031v-2.016q0-.797-.563-1.43t-1.477-1.102-1.992-.797-2.133-.492T8.997 15zm13.078-8.156q.047-.094.047-.164v-.164q0-.188-.047-.375l.75-.563q.141-.094.047-.234l-.703-1.172q-.047-.141-.234-.094l-.844.328q-.234-.188-.563-.328l-.141-.938q-.047-.141-.188-.141h-1.406q-.141 0-.188.141l-.094.938q-.328.141-.609.328l-.844-.328q-.188-.047-.234.094l-.703 1.172q-.047.141.047.234l.75.563q-.047.188-.047.375 0 .141.047.328l-.75.563q-.141.094-.047.234l.703 1.219q.047.094.234.094l.844-.375q.234.188.609.328l.094.938q.047.141.188.141h1.406q.141 0 .188-.141l.094-.938q.328-.094.609-.328l.844.375q.188.047.234-.094l.703-1.219q.047-.141-.047-.234zm-2.578.89q-.516 0-.891-.352t-.375-.867.375-.891.891-.375.891.375.375.891-.375.867-.891.352zm.422 3.938l-.516-.844q-.047-.141-.141-.094l-.609.281q-.188-.188-.422-.234l-.094-.703q-.047-.094-.141-.094h-.984q-.141 0-.141.141l-.094.656q-.094.047-.211.094t-.211.141l-.609-.234q-.094-.047-.141.047l-.516.844q-.047.094.047.188l.516.422v.469l-.516.422q-.094.047-.047.141l.516.891q.047.094.141.047l.609-.281q.188.188.422.281l.094.656q0 .094.141.094h.984q.141 0 .141-.094l.047-.656q.141-.047.234-.117t.188-.164l.656.281q.094.047.141-.047l.516-.891q.047-.094-.047-.141l-.516-.422v-.469l.516-.422q.094-.094.047-.188zm-2.438 1.641q-.328 0-.563-.234t-.234-.563q0-.375.234-.609t.563-.234q.375 0 .609.234t.234.609q0 .328-.234.563t-.609.234zM4.734 9h8.531q.188 0 .352-.141t.164-.328v-.047q0-.188-.164-.328t-.352-.141h-.281q0-1.125-.539-2.039t-1.43-1.43v.938q0 .234-.164.375T10.499 6t-.352-.141-.164-.375V4.125l-.469-.094-.516-.047-.516.047-.469.094v1.359q0 .234-.164.375T7.497 6t-.352-.141-.164-.375v-.938q-.891.516-1.43 1.43t-.539 2.039h-.281q-.188 0-.328.141t-.141.328v.047q0 .188.141.328T4.731 9zM9 12.984q1.406 0 2.461-.844t1.383-2.156H5.156q.328 1.313 1.383 2.156T9 12.984z"/>'
  }
})
