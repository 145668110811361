/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_ic_call': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 6v2.016h-3v3h-2.016v-3h-3V6h3V3H18v3h3zm-.984 9.516q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.094.188.094.328 0 .469-.328.703l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563z"/>'
  }
})
