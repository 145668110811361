/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical_align_top': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 3h16.031v2.016H3.984V3zm4.032 8.016L12 6.985l3.984 4.031h-3V21h-1.969v-9.984h-3z"/>'
  }
})
