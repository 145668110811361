/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 17.016h16.031v1.969h-4.031v4.031h-1.969v-4.031H6.984q-.797 0-1.383-.586t-.586-1.383V6.985H.984V5.016h4.031V.985h1.969v16.031zM17.016 15V6.984H9V5.015h8.016q.797 0 1.383.586t.586 1.383V15h-1.969z"/>'
  }
})
