/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import_export': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 17.016h3L15 21l-3.984-3.984h3V9.985h1.969v7.031zM9 3l3.984 3.984h-3v7.031H8.015V6.984h-3z"/>'
  }
})
