/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_printshop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3v3.984H6V3h12zm.984 9q.422 0 .727-.281t.305-.703-.305-.727-.727-.305-.703.305-.281.727.281.703.703.281zm-3 6.984v-4.969H8.015v4.969h7.969zm3-10.968q1.219 0 2.109.891t.891 2.109v6H18V21H6v-3.984H2.016v-6q0-1.219.891-2.109t2.109-.891h13.969z"/>'
  }
})
