/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q2.906 0 4.945-2.063t2.039-4.969-2.039-4.945T12 6 7.055 8.039t-2.039 4.945 2.039 4.969T12 20.016zm7.031-12.61q.797 1.031 1.383 2.648t.586 2.93q0 3.703-2.625 6.352T12 21.984t-6.375-2.648T3 12.984t2.625-6.352T12 3.984q1.266 0 2.93.609t2.695 1.406l1.406-1.453q.75.609 1.406 1.406zm-8.015 6.61v-6h1.969v6h-1.969zM15 .984V3H9V.984h6z"/>'
  }
})
