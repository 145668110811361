/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'computer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 6v9.984h16.031V6H3.984zm16.032 12H24v2.016H0V18h3.984q-.797 0-1.383-.609t-.586-1.406V6.001q0-.797.586-1.406t1.383-.609h16.031q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406T20.015 18z"/>'
  }
})
