/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'graphic_eq': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9.984H21v4.031h-2.016V9.984zM15 18V6h2.016v12H15zM3 14.016V9.985h2.016v4.031H3zm8.016 7.968V2.015h1.969v19.969h-1.969zM6.984 18V6H9v12H6.984z"/>'
  }
})
