/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'golf_course': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 5.906l-6 3.094v9.047q2.156.094 3.563.656t1.406 1.313q0 .797-1.758 1.383t-4.242.586-4.242-.586-1.758-1.383q0-1.172 3-1.734v1.734h2.016v-18zM18 19.5q0-.656.422-1.078T19.5 18t1.078.422T21 19.5t-.422 1.078T19.5 21t-1.078-.422T18 19.5z"/>'
  }
})
