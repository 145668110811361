/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'visibility_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.859 9H12q1.219 0 2.109.891T15 12v.188zm-4.312.797Q6.984 10.922 6.984 12q0 2.063 1.477 3.539T12 17.016q1.078 0 2.203-.563l-1.547-1.547Q12.281 15 12 15q-1.219 0-2.109-.891T9 12q0-.281.094-.656zM2.016 4.266L3.282 3l17.719 17.719-1.266 1.266q-.234-.234-1.477-1.453t-1.898-1.875q-2.016.844-4.359.844-3.703 0-6.703-2.063T.985 12q.516-1.219 1.617-2.695t2.133-2.273q-.563-.563-1.57-1.594T2.017 4.266zM12 6.984q-.938 0-1.828.375L8.016 5.203Q9.844 4.5 12 4.5q3.703 0 6.68 2.063t4.289 5.438q-1.125 2.766-3.422 4.734l-2.906-2.906q.375-.891.375-1.828 0-2.063-1.477-3.539T12 6.985z"/>'
  }
})
