/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_auto': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.313 15.984h1.875L9 6.984H6.984l-3.188 9h1.922l.703-1.969h3.188zm11.671-9h1.828l-2.063 9h-1.734l-1.5-6.094-1.5 6.094h-1.781l-.094-.422q-.984 2.016-2.906 3.234t-4.219 1.219q-3.328 0-5.672-2.367T-.001 12t2.344-5.648 5.672-2.367q3.844 0 6.234 3h.75l1.219 6.328 1.5-6.328h1.594l1.5 6.328zm-15.14 5.672L8.016 9l1.125 3.656H6.844z"/>'
  }
})
