/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop_screen_share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15q1.359-1.875 3.656-2.438l-1.594-1.594q-1.5 1.453-2.063 4.031zM2.391 1.734l19.734 19.734-1.266 1.266-2.719-2.719H-.001v-2.016h3.984q-.844 0-1.406-.563t-.563-1.406V5.999q0-.891.656-1.453L1.123 2.999zm19.593 14.297q0 1.172-1.031 1.734l-5.531-5.531 1.594-1.5-4.031-3.703V9.14q-.094.047-.258.047t-.258.047l-5.25-5.203h12.797q.844 0 1.406.563T21.985 6v10.031zM21.234 18H24v2.016h-.797z"/>'
  }
})
