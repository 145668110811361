/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'donut_large': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 18.938q2.25-.328 3.961-2.016t2.039-3.938h3q-.375 3.75-2.813 6.211t-6.188 2.789v-3.047zm6-7.922q-.328-2.25-2.039-3.938t-3.961-2.016V2.015q3.75.375 6.188 2.813t2.813 6.188h-3zm-7.968-5.953q-2.344.375-4.172 2.391t-1.828 4.547 1.828 4.547 4.172 2.391v3.047q-3.797-.375-6.398-3.234t-2.602-6.75 2.602-6.75 6.398-3.234v3.047z"/>'
  }
})
