/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'room_service': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.828 7.781q2.953.609 4.969 2.883T21 15.984H3q.188-3.047 2.203-5.32t4.969-2.883q-.188-.375-.188-.797 0-.844.586-1.406T12 5.015t1.43.563.586 1.406q0 .422-.188.797zM2.016 17.016h19.969v1.969H2.016v-1.969z"/>'
  }
})
