/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'align_vertical_bottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 21.984H2.015v-1.969h19.969v1.969zm-12-19.968h-3V18h3V2.016zm7.032 6h-3V18h3V8.016z"/>'
  }
})
