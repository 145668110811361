/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 17.016q1.266-1.266 3-1.266t3 1.266l-3 3zM2.016 3.047l1.266-1.266L20.251 18.75l-1.266 1.266-7.078-7.078q-1.172 0-2.625.609T6.985 15l-1.969-1.969v-.047q1.828-1.828 4.406-2.531L7.172 8.25Q4.875 9.188 3 11.016L.984 9q1.828-1.828 4.078-2.906zm16.968 9.937l-.938.984-3.563-3.516q2.672.703 4.5 2.531zM22.969 9L21 11.016q-3.938-4.031-9.656-3.703L8.86 4.782q3.797-.797 7.523.281t6.586 3.938z"/>'
  }
})
