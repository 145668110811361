/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'storage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 11.016v1.969H6v-1.969H3.984zm-1.968 3V9.985h19.969v4.031H2.016zM6 6.984V5.015H3.984v1.969H6zm-3.984-3h19.969v4.031H2.016V3.984zm1.968 13.032v1.969H6v-1.969H3.984zm-1.968 3v-4.031h19.969v4.031H2.016z"/>'
  }
})
