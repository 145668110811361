/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 14.016V9.985h-1.969v4.031h1.969zm0 3.984v-2.016h-1.969V18h1.969zm-12 3L12 2.016 23.016 21H.985z"/>'
  }
})
