/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'public': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.906 17.391q2.109-2.25 2.109-5.391 0-2.484-1.383-4.5t-3.633-2.906v.422q0 .797-.609 1.383t-1.406.586h-1.969v2.016q0 .422-.305.703t-.727.281H8.014v2.016h6q.422 0 .703.281t.281.703v3h.984q1.406 0 1.922 1.406zm-6.89 2.531V18q-.797 0-1.406-.609t-.609-1.406v-.984L4.22 10.22q-.234.938-.234 1.781 0 3.047 2.039 5.297t4.992 2.625zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
