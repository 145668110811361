/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subscript': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 18h-1.969v.984h3v1.031h-4.031v-2.016q0-.422.305-.703t.727-.281h1.969v-1.031h-3V15h3q.422 0 .727.305t.305.68v1.031q0 .375-.305.68t-.727.305zM5.859 18h2.672l3.422-5.438h.094L15.469 18h2.672l-4.688-7.266 4.359-6.75H15.14L12.046 9h-.094L8.858 3.984H6.186l4.313 6.75z"/>'
  }
})
