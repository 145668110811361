/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_stories': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984.984L14.015 6v11.016l4.969-4.5V.985zM.984 6v14.672q0 .188.164.328t.352.141q.094 0 .141-.023t.094-.023q1.031-.469 2.344-.773t2.438-.305q1.453 0 2.93.328t2.555 1.172V6.001q-1.078-.844-2.555-1.172t-2.93-.328q-1.5 0-2.953.328T.986 6.001zm22.032 13.5V6q-.469-.328-.961-.563T21 5.015v13.5q-.844-.281-1.711-.398T17.484 18q-.844 0-1.852.188t-1.992.539-1.641.773v2.016q.656-.422 1.641-.773t1.992-.539 1.852-.188q1.266 0 2.484.234t2.297.797q.094.047.234.047.188 0 .352-.164t.164-.352V19.5z"/>'
  }
})
