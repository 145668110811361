/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phonelink_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 8.016q.422 0 .703.281T24 9v9.984q0 .422-.281.727t-.703.305h-.188l-3-3h2.156V9.985H18v5.203l-2.016-2.016V9q0-.422.305-.703t.727-.281h6zM3.984 6.281v10.734h10.734zm-2.062-4.64q3.797 3.797 10.805 10.828t8.602 8.625l-1.266 1.266-2.344-2.344H0v-3h2.016V6q0-.703.469-1.266L.657 2.906zM21.984 6H8.812L6.796 3.984h15.188V6z"/>'
  }
})
