/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip_to_front': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 21v-2.016H9V21H6.984zm4.032 0v-2.016h1.969V21h-1.969zm7.968-6V5.016H9V15h9.984zm0-12q.797 0 1.406.609t.609 1.406v9.984q0 .797-.609 1.406t-1.406.609H9q-.844 0-1.43-.586t-.586-1.43V5.014q0-.844.586-1.43T9 2.998h9.984zM15 21v-2.016h2.016V21H15zM3 9V6.984h2.016V9H3zm2.016 12q-.844 0-1.43-.586T3 18.984h2.016V21zM3 17.016V15h2.016v2.016H3zm0-4.032v-1.969h2.016v1.969H3z"/>'
  }
})
