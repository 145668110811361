/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pending': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zM6.984 13.5q-.609 0-1.055-.445T5.484 12t.445-1.055 1.055-.445q.656 0 1.078.445T8.484 12t-.422 1.055-1.078.445zm5.016 0q-.609 0-1.055-.445T10.5 12t.445-1.055T12 10.5t1.055.445T13.5 12t-.445 1.055T12 13.5zm5.016 0q-.656 0-1.078-.445T15.516 12t.422-1.055 1.078-.445q.609 0 1.055.445T18.516 12t-.445 1.055-1.055.445z"/>'
  }
})
