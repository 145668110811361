/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'find_in_page': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12.984q0-1.219.891-2.109T12 9.984t2.109.891.891 2.109-.891 2.109-2.109.891-2.109-.891T9 12.984zm11.016 6.61l-3.844-3.844q.844-1.313.844-2.766 0-2.063-1.477-3.516T12 8.015 8.461 9.468t-1.477 3.516 1.477 3.539T12 18q1.453 0 2.766-.844l4.406 4.453q-.516.375-1.172.375H6q-.797 0-1.406-.586t-.609-1.383l.047-16.031q0-.797.586-1.383t1.383-.586h8.016l6 6v11.578z"/>'
  }
})
