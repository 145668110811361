/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airplanemode_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15.984L12.984 13.5v5.484l2.016 1.5v1.5L11.484 21l-3.469.984v-1.5l1.969-1.5V13.5l-7.969 2.484v-1.969l7.969-5.016V3.515q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055v5.484L21 14.015v1.969z"/>'
  }
})
