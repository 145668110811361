/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'watch': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 12q0 2.484 1.758 4.242T12 18t4.242-1.758T18 12t-1.758-4.242T12 6 7.758 7.758 6 12zm14.016 0q0 1.641-.891 3.469t-2.156 2.813l-.984 5.719H8.016l-.984-5.719q-3.047-2.344-3.047-6.281T7.032 5.72L8.016.001h7.969l.984 5.719q3.047 2.438 3.047 6.281z"/>'
  }
})
