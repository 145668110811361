/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'build_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zm4.922 13.453l-1.406 1.406q-.188.188-.375.188t-.328-.188L11.391 13.5q-.938.281-1.922.094t-1.688-.938q-.844-.797-.984-1.945t.375-2.133l2.344 2.344 1.406-1.406-2.344-2.344q.984-.516 2.133-.375t1.992.984q.703.703.914 1.688t-.117 1.922l3.422 3.422q.141.141.141.328t-.141.328z"/>'
  }
})
