/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_photography': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.922 8.109L7.5 4.64 9 2.999h6l1.828 2.016h3.188q.797 0 1.383.586t.586 1.383v12.188l-5.109-5.109q.141-.516.141-1.078 0-1.359-.68-2.508t-1.828-1.805-2.508-.656q-.516 0-1.078.094zm9.562 15.188L18.187 21H3.984q-.797 0-1.383-.586t-.586-1.43v-12q0-.422.188-.82t.469-.633L.703 3.515l1.406-1.406L21.89 21.89zm-6-6l-1.5-1.5q-.234.094-.469.141t-.516.047q-.844 0-1.523-.398t-1.078-1.078T9 12.986q0-.234.047-.492t.141-.492l-1.5-1.5q-.703 1.172-.703 2.484 0 1.406.68 2.531t1.828 1.805 2.508.68q.703 0 1.313-.188t1.172-.516z"/>'
  }
})
