/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drive_file_move': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6H12L9.984 3.984h-6q-.797 0-1.383.586T2.015 6v12q0 .844.586 1.43t1.383.586h16.031q.797 0 1.383-.586t.586-1.43V8.016q0-.844-.586-1.43T20.015 6zm-6 12v-3H9.985v-3.984h4.031v-3l4.969 4.969z"/>'
  }
})
