/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ad_units': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016.984H6.985q-.797 0-1.383.586T5.016 3v18q0 .844.586 1.43t1.383.586h10.031q.797 0 1.383-.586t.586-1.43V3q0-.844-.586-1.43T17.016.984zm0 18H6.985V5.015h10.031v13.969zM8.016 6h7.969v2.016H8.016V6z"/>'
  }
})
