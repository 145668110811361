/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch_camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 15.516L18.516 12 15 8.484v2.531H9V8.484L5.484 12 9 15.516v-2.531h6v2.531zm5.016-11.532q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h3.188l1.828-1.969h6l1.828 1.969h3.188z"/>'
  }
})
