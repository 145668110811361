/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home_work': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3h13.031v18h-6v-3.984h1.969V15h-1.969v-2.016h1.969v-1.969h-1.969V9.187l-2.016-1.359v-.844h-1.266Q12.139 5.953 9.983 4.5V3zM8.156 5.719L15 10.266V21H9.984v-8.016H6V21H.984V10.5z"/>'
  }
})
