/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close_fullscreen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 3.422l-5.297 5.297L20.015 12h-8.016V3.984l3.281 3.328 5.297-5.297zM3.422 21.984l5.297-5.297L12 20.015v-8.016H3.984l3.328 3.281-5.297 5.297z"/>'
  }
})
