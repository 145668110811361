/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_tab': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6h1.969v12h-1.969V6zm-8.438 1.406L12.984 6l6 6-6 6-1.406-1.406 3.609-3.609H.984v-1.969h14.203z"/>'
  }
})
