/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shuffle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.813 13.406l3.141 3.141 2.063-2.063v5.531h-5.531l2.063-2.063-3.141-3.141zm-.329-9.422h5.531v5.531l-2.063-2.063L5.389 20.015l-1.406-1.406L16.546 6.046zm-3.89 5.204l-1.406 1.406-5.203-5.203 1.406-1.406z"/>'
  }
})
