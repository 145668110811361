/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vpn_lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 20.953v-1.969q-.797 0-1.383-.586t-.586-1.383v-1.031l-4.828-4.781q-.188.75-.188 1.781 0 3.047 2.016 5.32t4.969 2.648zM18.938 12h2.016q.047.328.047.984 0 4.172-2.93 7.102t-7.055 2.93q-4.172 0-7.102-2.93t-2.93-7.102q0-4.125 2.93-7.055t7.102-2.93q1.453 0 3 .469v2.531q0 .797-.609 1.406t-1.406.609H9.985v1.969q0 .422-.281.727t-.703.305H6.985v1.969h6q.422 0 .727.305t.305.727v3h.984q1.453 0 1.922 1.359 2.063-2.203 2.063-5.391 0-.656-.047-.984zm2.25-8.016v-.469q0-.703-.492-1.219t-1.195-.516-1.195.516-.492 1.219v.469h3.375zm.796 0q.422 0 .727.305t.305.727V9q0 .422-.305.703t-.727.281h-4.969q-.422 0-.727-.281T15.983 9V5.016q0-.422.305-.727t.727-.305v-.469q0-1.031.727-1.781T19.5.984t1.758.75.727 1.781v.469z"/>'
  }
})
