/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_drop_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 14.016L12 9l5.016 5.016H6.985z"/>'
  }
})
