/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact_phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.859 14.016q-.375-.984-.375-2.016t.375-2.016H19.5L21 8.015l-1.969-2.016q-.844.656-1.664 1.828t-1.102 2.156-.281 2.016.281 2.016 1.102 2.156 1.664 1.828L21 15.983l-1.5-1.969h-1.641zM14.016 18v-.984q0-1.359-2.063-2.227t-3.938-.867-3.938.867-2.063 2.227V18h12zm-6-12q-1.219 0-2.109.891T5.016 9t.891 2.109T8.016 12t2.109-.891T11.016 9t-.891-2.109T8.016 6zm13.968-3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H2.015q-.797 0-1.406-.609T0 18.984V5.015q0-.797.609-1.406T2.015 3h19.969z"/>'
  }
})
