/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_paint': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984h3V12h-8.016v9q0 .422-.281.703t-.703.281H9.984q-.422 0-.703-.281T9 21V9.984h9.984V6H18v.984q0 .422-.281.727t-.703.305h-12q-.422 0-.727-.305t-.305-.727V3q0-.422.305-.703t.727-.281h12q.422 0 .703.281T18 3v.984z"/>'
  }
})
