/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closed_caption_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.016h-1.5V10.5h-2.016v3H16.5v-.516H18v1.031q0 .422-.281.703t-.703.281h-3q-.422 0-.727-.281t-.305-.703V9.984q0-.422.305-.703T14.016 9h3q.422 0 .703.281t.281.703v1.031zm-6.984 0h-1.5V10.5H7.5v3h2.016v-.516h1.5v1.031q0 .422-.305.703t-.727.281h-3q-.422 0-.703-.281T6 14.015V9.984q0-.422.281-.703T6.984 9h3q.422 0 .727.281t.305.703v1.031zm7.968-7.032q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43v-12q0-.844.586-1.43t1.43-.586h13.969zm.516 1.5h-15v13.031h15V5.484z"/>'
  }
})
