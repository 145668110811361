/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort_by_alpha': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.75 16.125h6.094v1.594h-8.531v-1.266l5.906-8.578H13.36V6.281h8.297v1.266zM4.969 13.641H8.86L6.891 8.438zm1.125-7.36h1.641l4.5 11.438h-1.828l-.938-2.438H4.36l-.938 2.438H1.594zm4.172 13.078h4.641l-2.344 2.344zm4.687-14.718h-4.734l2.344-2.344z"/>'
  }
})
