/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15.375q.422 0 .703.281t.281.703v3.469Q21 21 20.016 21q-6.984 0-12-5.016T3 3.984Q3 3 4.172 3h3.469q.422 0 .703.281t.281.703q0 1.828.563 3.516.188.609-.234 1.031l-1.922 1.688q2.156 4.5 6.891 6.797l1.547-1.969q.281-.281.703-.281.234 0 .328.047 1.688.563 3.516.563z"/>'
  }
})
