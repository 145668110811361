/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_golf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 18.984v-1.969h10.031v1.969h-2.016q-.797 0-1.406.609t-.609 1.406v.984h-1.969v-.984q0-.797-.609-1.406T9 18.984H6.984zM11.016 6q0-.422.281-.703T12 5.016t.703.281.281.703-.281.703-.703.281-.703-.281T11.016 6zm1.968 2.016q0-.422.305-.727t.727-.305.703.305.281.727-.281.703-.703.281-.727-.281-.305-.703zM9 8.016q0-.422.281-.727t.703-.305.727.305.305.727-.305.703T9.984 9t-.703-.281T9 8.016zm3-4.032q-2.063 0-3.539 1.477T6.984 9t1.477 3.539T12 14.016t3.539-1.477T17.016 9t-1.477-3.539T12 3.984zm0 12q-2.906 0-4.945-2.039T5.016 9t2.039-4.945T12 2.016t4.945 2.039T18.984 9t-2.039 4.945T12 15.984z"/>'
  }
})
