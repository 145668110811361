/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'developer_board': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.016h3.984v6H12v-6zM6 6.984h5.016V12H6V6.984zm6 0h3.984v3H12v-3zm-6 6h5.016v4.031H6v-4.031zm12 6V5.015H3.984v13.969H18zM21.984 9h-1.969v2.016h1.969v1.969h-1.969v2.016h1.969v2.016h-1.969v1.969q0 .797-.609 1.406T18 21.001H3.984q-.797 0-1.383-.609t-.586-1.406V5.017q0-.797.586-1.406t1.383-.609H18q.797 0 1.406.609t.609 1.406v1.969h1.969v2.016z"/>'
  }
})
