/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_iridescent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.969 19.969l-1.406-1.453 1.781-1.781 1.406 1.406zM3.563 4.453l1.406-1.406L6.75 4.828 5.344 6.234zm16.875 14.11l-1.406 1.406-1.781-1.828 1.406-1.406zm-7.454 3.89h-1.969V19.5h1.969v2.953zm6.047-19.406l1.406 1.406-1.781 1.781-1.406-1.406zM11.016.563h1.969v2.953h-1.969V.563zm-6 13.921v-6h13.969v6H5.016z"/>'
  }
})
