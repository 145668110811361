/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_day': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 3H21v3H2.016V3zm18 5.016q.422 0 .703.281T21 9v6q0 .422-.281.703t-.703.281H3q-.422 0-.703-.281T2.016 15V9q0-.422.281-.703T3 8.016h17.016zM2.016 21v-3H21v3H2.016z"/>'
  }
})
