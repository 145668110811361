/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fiber_pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15V9H18.75v3.516L16.266 9H15v6h1.266v-3.516L18.797 15h1.219zm-7.5 0V9h-1.5v6h1.5zM9 11.484V10.5q0-.656-.422-1.078T7.5 9H3.984v6h1.5v-2.016H7.5q.656 0 1.078-.445T9 11.484zm11.016-7.5q.844 0 1.406.586T21.985 6v12q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586T2.016 18V6q0-.844.563-1.43t1.406-.586h16.031zM5.484 10.5H7.5v.984H5.484V10.5z"/>'
  }
})
