/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 6.984v3.188L12 8.203V3h6v3.984h-3.984zM4.266 3L21 19.734 19.734 21l-5.719-5.719v1.734q0 1.641-1.195 2.813T9.984 21t-2.813-1.172-1.172-2.813 1.172-2.836 2.813-1.195q.984 0 2.016.563v-.281l-9-9z"/>'
  }
})
