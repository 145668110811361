/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'escalator': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm-1.968 6h-1.734l-4.969 9H6.985q-.609 0-1.055-.445T5.485 16.5t.445-1.055T6.985 15h1.734l4.969-9h3.328q.609 0 1.055.445t.445 1.055-.445 1.055T17.016 9z"/>'
  }
})
