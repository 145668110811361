/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'confirmation_number': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 8.484V6.515h-1.969v1.969h1.969zm0 4.5v-1.969h-1.969v1.969h1.969zm0 4.5v-1.969h-1.969v1.969h1.969zm9-7.5q-.797 0-1.383.609t-.586 1.406.586 1.406 1.383.609v3.984q0 .797-.586 1.406t-1.383.609H3.984q-.797 0-1.383-.609t-.586-1.406v-3.984q.844 0 1.406-.586t.563-1.43q0-.797-.586-1.406t-1.383-.609V5.999q0-.844.586-1.43t1.383-.586h16.031q.844 0 1.406.586t.563 1.43v3.984z"/>'
  }
})
