/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'meeting_room': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 11.016v1.969H12v-1.969H9.984zM14.016 6v15H3v-2.016h2.016V3h9v.984h4.969v15h2.016V21h-3.984V6h-3z"/>'
  }
})
