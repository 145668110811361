/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984V15h-6v-3.984q0-.844.563-1.43T12.985 9h2.016V6.984h-3.984V5.015h3.984q.844 0 1.43.563t.586 1.406V9q0 .844-.586 1.43t-1.43.586h-2.016v1.969h4.031zM21 17.016V3H6.984v14.016H21zM21 .984q.797 0 1.406.609t.609 1.406v14.016q0 .797-.609 1.383T21 18.984H6.984q-.797 0-1.383-.586t-.586-1.383V2.999q0-.797.586-1.406T6.984.984H21zM3 5.016V21h15.984v2.016H3q-.797 0-1.406-.609t-.609-1.406V5.017h2.016z"/>'
  }
})
