/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_bike': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 20.484q1.453 0 2.484-1.008t1.031-2.461-1.031-2.484-2.484-1.031-2.461 1.031-1.008 2.484 1.008 2.461 2.461 1.008zm0-8.484q2.109 0 3.563 1.453T24 17.016t-1.453 3.539-3.563 1.43-3.539-1.43-1.43-3.539 1.43-3.563T18.984 12zm-8.203-1.5l2.203 2.297v6.188h-1.969v-4.969l-3.234-2.813q-.563-.375-.563-1.406 0-.844.563-1.406l2.813-2.813q.375-.563 1.406-.563.891 0 1.594.563L15.516 7.5q1.5 1.5 3.563 1.5v2.016q-2.953 0-5.063-2.109l-.797-.797zm-5.765 9.984q1.453 0 2.461-1.008t1.008-2.461-1.008-2.484T5.016 13.5t-2.484 1.031-1.031 2.484 1.031 2.461 2.484 1.008zm0-8.484q2.109 0 3.539 1.453t1.43 3.563-1.43 3.539-3.539 1.43-3.563-1.43T0 17.016t1.453-3.563T5.016 12zm10.5-6.516q-.797 0-1.406-.586t-.609-1.383.609-1.406 1.406-.609 1.383.609.586 1.406-.586 1.383-1.383.586z"/>'
  }
})
