/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compare_arrows': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 12.984L11.016 9 15 5.016v3h6.984v1.969H15v3zm-6 1.032v-3L12.984 15 9 18.984v-3H2.016v-1.969H9z"/>'
  }
})
