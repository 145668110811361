/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_recline_extra': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 15l5.766 4.5-1.5 1.5-3.797-3H9.844q-1.031 0-1.898-.703t-1.055-1.734L5.532 9.657q0-.047-.023-.188t-.023-.188q0-.797.563-1.453t1.313-.797h.047q.047 0 .164-.023t.164-.023q.797 0 1.406.469l1.641 1.266q2.25 1.734 4.688 1.266v2.156q-2.25.375-5.156-1.219l1.031 4.078h4.875zm-.235 3.984V21H8.953q-1.781 0-3.234-1.219t-1.734-3L2.016 6.984h1.969l1.969 9.469q.188 1.078 1.031 1.805t1.969.727h7.031zM5.344 5.625q-.656-.469-.797-1.289t.328-1.477 1.289-.82 1.477.305q.656.516.82 1.336t-.305 1.477-1.313.797-1.5-.328z"/>'
  }
})
