/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'streetview': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.484 6q0 1.125.563 2.461t1.359 2.133l-9.797 9.797Q3 19.782 3 18.985V5.016q0-.797.609-1.406t1.406-.609h7.219q-.75 1.406-.75 3zm1.5 0q0-2.063 1.477-3.539T18 .984t3.539 1.477T23.016 6t-1.477 3.539T18 11.016t-3.539-1.477T12.984 6zm-.421 8.344q2.438-1.828 5.438-1.828 1.547 0 3 .516v5.953q0 .797-.609 1.406T18.986 21h-6.984v-5.484q0-.703.563-1.172z"/>'
  }
})
