/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image_not_supported': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.891 21.891L2.11 2.11.704 3.516l2.297 2.297v13.172q0 .844.586 1.43t1.43.586h13.172l2.297 2.297zM5.016 18l3.469-4.5 2.531 3 1.172-1.5 3 3H5.016zM21 18.188L5.812 3h13.172q.844 0 1.43.586T21 5.016v13.172z"/>'
  }
})
