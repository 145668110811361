/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_style': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9V6.984H21V9h-2.016zM3 3h18v2.016H5.016V21H3V3zm15.984 9.984v-1.969H21v1.969h-2.016zm0 4.032V15H21v2.016h-2.016zM11.016 21v-2.016h1.969V21h-1.969zm-4.032 0v-2.016H9V21H6.984zm12 0v-2.016H21V21h-2.016zM15 21v-2.016h2.016V21H15z"/>'
  }
})
