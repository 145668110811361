/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tune': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9V3h2.016v2.016H21v1.969h-3.984v2.016H15zm6 3.984h-9.984v-1.969H21v1.969zM6.984 9H9v6H6.984v-2.016H3v-1.969h3.984V8.999zm6 12h-1.969v-6h1.969v2.016H21v1.969h-8.016v2.016zM3 5.016h9.984v1.969H3V5.016zm0 12h6v1.969H3v-1.969z"/>'
  }
})
