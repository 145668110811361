/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'note': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.484v5.531h5.484zm6.984 4.5V18q0 .797-.586 1.383t-1.383.586l-16.031.047q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h12z"/>'
  }
})
