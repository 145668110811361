/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'domain_verification': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.594 10.875l-1.406-1.406-4.266 4.219-2.109-2.109-1.406 1.406 3.516 3.563zm2.39-6.891H5.015q-.844 0-1.43.586T2.999 6v12q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586T21 18V6q0-.844-.586-1.43t-1.43-.586zm0 14.016H5.015V8.016h13.969V18z"/>'
  }
})
