/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star_rate': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.438 9.984L12 2.015 9.562 9.984H2.015l6.188 4.406-2.391 7.594L12 17.296l6.188 4.688-2.344-7.594 6.141-4.406h-7.547z"/>'
  }
})
