/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'restaurant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 6q0-1.406 1.523-2.695t3.492-1.289v19.969h-2.484v-7.969h-2.531V6zm-4.968 3V2.016h1.969V9q0 1.594-1.078 2.742t-2.672 1.242v9H6.751v-9q-1.594-.094-2.672-1.242T3.001 9V2.016h2.016V9h1.969V2.016h2.016V9h2.016z"/>'
  }
})
