/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fiber_new': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.484 14.016V9h-1.219v4.5H18.14V9.984h-1.266V13.5h-1.125V9h-1.266v5.016q0 .422.305.703t.727.281h3.984q.422 0 .703-.281t.281-.703zm-6.984-3.75V9H9.516v6H13.5v-1.266h-2.484v-1.078H13.5V11.39h-2.484v-1.125H13.5zM8.484 15V9H7.265v3.516L4.734 9H3.515v6h1.219v-3.516L7.312 15h1.172zM20.016 3.984q.844 0 1.406.586T21.985 6v12q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586T2.016 18V6q0-.844.563-1.43t1.406-.586h16.031z"/>'
  }
})
