/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'api': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12L12 14.016 9.984 12 12 9.984zM12 6l2.109 2.109 2.531-2.484L11.999.984 7.358 5.625l2.531 2.484zm-6 6l2.109-2.109L5.625 7.36.984 12.001l4.641 4.641 2.484-2.531zm12 0l-2.109 2.109 2.484 2.531 4.641-4.641-4.641-4.641-2.484 2.531zm-6 6l-2.109-2.109-2.531 2.484 4.641 4.641 4.641-4.641-2.531-2.484z"/>'
  }
})
