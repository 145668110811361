/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redeem': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 14.016v-6h-5.109l2.109 2.813-1.641 1.172q-3-4.078-3.375-4.594-.375.516-3.375 4.594l-1.641-1.172 2.109-2.813H3.984v6h16.031zm0 4.968v-1.969H3.985v1.969h16.031zM9 3.984q-.422 0-.703.305t-.281.727.281.703T9 6t.703-.281.281-.703-.281-.727T9 3.984zm6 0q-.422 0-.703.305t-.281.727.281.703T15 6t.703-.281.281-.703-.281-.727T15 3.984zM20.016 6q.844 0 1.406.586t.563 1.43v10.969q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586t-.563-1.43V8.016q0-.844.563-1.43T3.985 6h2.203Q6 5.344 6 5.016q0-1.219.891-2.109T9 2.016q1.547 0 2.484 1.313l.516.703.516-.703q.328-.563 1.078-.938T15 2.016q1.219 0 2.109.891T18 5.016q0 .328-.188.984h2.203z"/>'
  }
})
