/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr_code': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 11.016h8.016V3H3v8.016zm2.016-6H9V9H5.016V5.016zM3 21h8.016v-8.016H3V21zm2.016-6H9v3.984H5.016V15zm7.968-12v8.016H21V3h-8.016zm6 6H15V5.016h3.984V9zm0 9.984H21V21h-2.016v-2.016zm-6-6H15V15h-2.016v-2.016zM15 15h2.016v2.016H15V15zm-2.016 2.016H15v1.969h-2.016v-1.969zM15 18.984h2.016V21H15v-2.016zm2.016-1.968h1.969v1.969h-1.969v-1.969zm0-4.032h1.969V15h-1.969v-2.016zM18.984 15H21v2.016h-2.016V15z"/>'
  }
})
