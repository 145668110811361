/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 21v-2.016H21q0 .797-.609 1.406t-1.406.609zm0-8.016v-1.969H21v1.969h-2.016zM15 5.016V3h2.016v2.016H15zm3.984 12V15H21v2.016h-2.016zm-7.968 6V.985h1.969v22.031h-1.969zM18.984 3q.797 0 1.406.609t.609 1.406h-2.016V2.999zM3 5.016q0-.797.609-1.406t1.406-.609h3.984v2.016H5.015v13.969h3.984v2.016H5.015q-.797 0-1.406-.609T3 18.987V5.018zM18.984 9V6.984H21V9h-2.016zM15 21v-2.016h2.016V21H15z"/>'
  }
})
