/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'functions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 3.984v3h-6.984L15.985 12l-4.969 5.016H18v3H6V18l6.516-6L6 6V3.984h12z"/>'
  }
})
