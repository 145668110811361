/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'restore_from_trash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 14.016h1.969l-3.984-4.031-3.984 4.031h1.969V18h4.031v-3.984zM6 6.984h12v12q0 .797-.609 1.406t-1.406.609H8.016q-.797 0-1.406-.609t-.609-1.406v-12zm12.984-3V6H5.015V3.984h3.469L9.515 3h4.969l1.031.984h3.469z"/>'
  }
})
