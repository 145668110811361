/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'surround_sound': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 9.984zm5.672 7.688q2.344-2.344 2.344-5.672v-.047q0-1.359-.68-3.023t-1.664-2.602l-1.453 1.453Q18 9.562 18 12q0 2.531-1.734 4.266zM12 15.984q1.641 0 2.813-1.172t1.172-2.813-1.172-2.813T12 8.014 9.187 9.186t-1.172 2.813 1.172 2.813T12 15.984zm-4.219.235Q6 14.438 6 12q0-2.531 1.734-4.266L6.328 6.328Q3.984 8.672 3.984 12v.047q0 1.359.68 3.023t1.664 2.602zM20.016 3.984q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031z"/>'
  }
})
