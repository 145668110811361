/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 11.016L20.016 6H3.985zM20.016 18V8.016L12 12.985 3.984 8.016V18h16.031zm0-14.016q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031z"/>'
  }
})
