/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'content_paste': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 20.016V3.985h-1.969v3H6.984v-3H5.015v16.031h13.969zM12 2.016q-.422 0-.703.281T11.016 3t.281.703.703.281.703-.281.281-.703-.281-.703T12 2.016zm6.984 0q.797 0 1.406.586t.609 1.383v16.031q0 .797-.609 1.383t-1.406.586H5.015q-.797 0-1.406-.586T3 20.016V3.985q0-.797.609-1.383t1.406-.586h4.172q.328-.891 1.078-1.453T11.999 0t1.734.563 1.078 1.453h4.172z"/>'
  }
})
