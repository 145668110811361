/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'moped': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984q0-.797-.586-1.383t-1.383-.586h-3v1.969h3v2.672l-3.516 4.359H9.983V8.999H5.999q-1.125 0-2.016.539t-1.43 1.453-.539 1.992v3h1.969q0 .844.422 1.523t1.102 1.078 1.477.398q.844 0 1.523-.398t1.078-1.078.398-1.523h4.5l4.5-5.625V6.983zm-12 10.032q-.375 0-.68-.305t-.305-.727h2.016q0 .422-.305.727t-.727.305zM5.016 6h4.969v2.016H5.016V6zm13.968 6.984q-.797 0-1.477.422t-1.102 1.102-.422 1.477q0 .844.422 1.523t1.102 1.078 1.477.398q.844 0 1.523-.398t1.078-1.078.398-1.523q0-.797-.398-1.477t-1.078-1.102-1.523-.422zm0 4.032q-.375 0-.68-.305t-.305-.727q0-.375.305-.68t.68-.305q.422 0 .727.305t.305.68q0 .422-.305.727t-.727.305z"/>'
  }
})
