/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'restore_page': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18q2.063 0 3.539-1.477t1.477-3.539-1.477-3.516T12 8.015q-2.719 0-4.219 2.297L6.515 8.999v3.984h3.984l-1.594-1.594q.375-.75 1.289-1.313t1.805-.563q1.453 0 2.484 1.008t1.031 2.461-1.031 2.484-2.484 1.031q-1.828 0-2.859-1.5H7.406q.563 1.359 1.805 2.18t2.789.82zm2.016-15.984l6 6v12q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383l.047-16.031q0-.797.586-1.383t1.383-.586h8.016z"/>'
  }
})
