/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 14.016h-.797l-.281-.281q.75-.844 1.148-1.945t.398-2.273q0-1.359-.492-2.531t-1.383-2.063-2.086-1.406-2.508-.516q-1.734 0-3.164.797t-2.32 2.156-.984 3.047h2.016q.094-1.125.727-2.039t1.594-1.43 2.133-.516q1.219 0 2.25.609t1.641 1.617.609 2.273q0 1.219-.609 2.25t-1.641 1.641-2.25.609q-.141 0-.258-.023t-.258-.023v2.016h.516q1.172 0 2.273-.398t1.945-1.148l.281.281v.797l4.969 4.969 1.5-1.5zm-9.047-3.188l-2.484 2.484-2.438-2.484-.75.703 2.484 2.484-2.484 2.438.75.75 2.438-2.484 2.484 2.484.703-.75-2.484-2.438 2.484-2.484z"/>'
  }
})
