/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'art_track': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 15l-2.25-3-1.734 2.25-1.266-1.5L3.516 15H10.5zM12 9v6q0 .797-.609 1.406t-1.406.609h-6q-.797 0-1.383-.609T2.016 15V9q0-.797.586-1.406t1.383-.609h6q.797 0 1.406.609T12 9zm2.016 8.016V15h7.969v2.016h-7.969zm7.968-10.032V9h-7.969V6.984h7.969zm0 6h-7.969v-1.969h7.969v1.969z"/>'
  }
})
