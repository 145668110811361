/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit_to_app': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43v-3.984h2.016v3.984h13.969V5.014H5.015v3.984H2.999V5.014q0-.844.586-1.43t1.43-.586h13.969zm-8.906 12.609l2.578-2.625H3v-1.969h9.656L10.078 8.39l1.406-1.406L16.5 12l-5.016 5.016z"/>'
  }
})
