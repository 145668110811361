/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'set_meal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.047 17.578l-17.953.938-.094-1.5 18-.938zM21 19.5H3V21h18v-1.5zm.984-14.484V12q0 .844-.586 1.43t-1.383.586H3.984q-.797 0-1.383-.586T2.015 12V5.016q0-.844.586-1.43T3.984 3h16.031q.797 0 1.383.586t.586 1.43zM20.016 6q-1.266 0-2.18.656T16.781 8.25q-.234-.281-.75-.75t-1.313-.914-1.922-.773-2.531-.328q-1.781 0-3.047.469T5.132 7.009 3.913 8.04l-.398.445.398.469 1.219 1.055 2.086 1.031 3.047.445q1.406 0 2.531-.305t1.922-.773 1.313-.914.75-.727q.141.938 1.055 1.594t2.18.656V6z"/>'
  }
})
