/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_wifi_0_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 21.469L23.625 6.985q-.141-.094-.727-.516t-1.617-1.008-2.414-1.148-3.117-.938T12 3t-3.75.375-3.117.938-2.414 1.148-1.617 1.008-.727.516L12 21.469z"/>'
  }
})
