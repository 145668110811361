/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'how_to_reg': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.469 20.484L12 17.015l1.406-1.406 2.063 2.063 5.109-5.156 1.406 1.406zM11.016 12q-1.641 0-2.836-1.172T6.985 8.015 8.18 5.179t2.836-1.195 2.813 1.195 1.172 2.836-1.172 2.813T11.016 12zM9 17.016l3 3H3V18q0-1.781 2.742-2.883t5.273-1.102q.703 0 .984.047z"/>'
  }
})
