/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 18h16.031V6H3.984v12zM2.016 3.984h19.969v16.031H2.016V3.984zm15 10.032V9.985h-1.031v4.031h1.031zm.984-6q.422 0 .703.281t.281.703v6q0 .422-.281.703t-.703.281h-3q-.422 0-.703-.281T14.016 15V9q0-.422.281-.703T15 8.016h3zm-6.984 6V9.985H9.985v4.031h1.031zm.984-6q.422 0 .703.281t.281.703v6q0 .422-.281.703t-.703.281H9q-.422 0-.703-.281T8.016 15V9q0-.422.281-.703T9 8.016h3zm-6.984 0h1.969v7.969H5.016V8.016z"/>'
  }
})
