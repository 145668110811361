/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blur_circular': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12.984q.422 0 .703.305t.281.727-.281.703-.703.281-.727-.281-.305-.703.305-.727.727-.305zm0 3.516q.469 0 .469.516 0 .469-.469.469-.516 0-.516-.469 0-.516.516-.516zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm5.016 7.5q.469 0 .469.469 0 .516-.469.516-.516 0-.516-.516 0-.469.516-.469zm0 3.984q.469 0 .469.516 0 .469-.469.469-.516 0-.516-.469 0-.516.516-.516zm-3-6q-.516 0-.516-.516 0-.469.516-.469.469 0 .469.469 0 .516-.469.516zm0 1.5q.422 0 .703.281t.281.703-.281.727-.703.305-.727-.305-.305-.727.305-.703.727-.281zM9.984 7.5q-.469 0-.469-.516 0-.469.469-.469.516 0 .516.469 0 .516-.516.516zm-3 6q.516 0 .516.516 0 .469-.516.469-.469 0-.469-.469 0-.516.469-.516zm3 3q.516 0 .516.516 0 .469-.516.469-.469 0-.469-.469 0-.516.469-.516zm-3-6.984q.516 0 .516.469 0 .516-.516.516-.469 0-.469-.516 0-.469.469-.469zm3 3.468q.422 0 .727.305t.305.727-.305.703-.727.281-.703-.281T9 14.016t.281-.727.703-.305zm0-3.984q.422 0 .727.281t.305.703-.305.727-.727.305-.703-.305T9 9.984t.281-.703T9.984 9z"/>'
  }
})
