/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera_enhance': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.016l-1.266-2.766-2.719-1.266 2.719-1.219L12 8.999l1.266 2.766 2.719 1.219-2.719 1.266zM12 18q2.063 0 3.539-1.477t1.477-3.539-1.477-3.516T12 8.015 8.461 9.468t-1.477 3.516 1.477 3.539T12 18zM9 3h6l1.828 2.016h3.188q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 21H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h3.188z"/>'
  }
})
