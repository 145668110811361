/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'center_focus_strong': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V15H21v3.984q0 .797-.609 1.406t-1.406.609h-3.984v-2.016h3.984zm0-15.984q.797 0 1.406.609t.609 1.406v3.984h-2.016V5.015h-3.984V2.999h3.984zM5.016 5.016V9H3V5.016q0-.797.609-1.406t1.406-.609h3.984v2.016H5.015zm0 9.984v3.984H9V21H5.016q-.797 0-1.406-.609t-.609-1.406v-3.984h2.016zM12 8.016q1.641 0 2.813 1.172t1.172 2.813-1.172 2.813T12 15.986t-2.813-1.172-1.172-2.813 1.172-2.813T12 8.016z"/>'
  }
})
