/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microwave': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.797 10.594L5.391 9.188q.234-.281.867-.727t1.477-.445q.609 0 1.055.211t.773.445q.469.328.703.328.281 0 .563-.234t.375-.375l1.406 1.406q-.281.328-.891.773t-1.453.445q-.609 0-1.055-.234t-.773-.422q-.234-.188-.398-.281t-.305-.094q-.281 0-.563.234t-.375.375zM7.734 15q.141 0 .305.094t.398.234q.328.234.773.445t1.055.211q.844 0 1.453-.445t.891-.727l-1.406-1.406q-.094.141-.398.375t-.539.234-.703-.328q-.328-.234-.773-.469t-1.055-.234q-.844 0-1.477.445t-.867.773l1.406 1.406q.094-.141.375-.375t.563-.234zm14.25-9v12q0 .844-.586 1.43t-1.383.586H3.984q-.797 0-1.383-.586T2.015 18V6q0-.844.586-1.43t1.383-.586h16.031q.797 0 1.383.586T21.984 6zm-7.968 0H3.985v12h10.031V6zm4.968 9.984q0-.375-.281-.68T18 14.999t-.703.305-.281.68q0 .422.281.727t.703.305.703-.305.281-.727zm0-3.984q0-.422-.281-.703T18 11.016t-.703.281-.281.703.281.703.703.281.703-.281.281-.703zm0-5.016h-1.969V9h1.969V6.984z"/>'
  }
})
