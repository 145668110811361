/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_up_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.844 12.891l-2.672 6.141q-.609.984-1.734.984H9.329q-.938 0-1.641-.703t-.703-1.641V9.844q0-.844.609-1.453l6.422-6.375.422.469q.563.563.891 1.219.141.328.094.656l-.938 4.641h5.531q.797 0 1.383.609t.586 1.406v1.078q0 .422-.141.797zM2.016 20.016V9h1.969q.422 0 .727.281t.305.703v9q0 .422-.305.727t-.727.305H2.016z"/>'
  }
})
