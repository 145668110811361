/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loyalty': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.25 15.281q.75-.75.75-1.781t-.727-1.758-1.758-.727q-1.078 0-1.781.703l-.75.75-.703-.75q-.703-.703-1.781-.703-1.031 0-1.758.727T8.015 13.5q0 1.078.703 1.781l4.266 4.266zM5.484 6.984q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm15.938 4.594q.563.563.563 1.406t-.563 1.406l-7.031 7.031q-.563.563-1.406.563t-1.406-.563l-9-9q-.563-.563-.563-1.406V3.984q0-.797.586-1.383t1.383-.586h7.031q.844 0 1.406.563z"/>'
  }
})
