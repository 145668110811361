/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_pizza': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15q.797 0 1.406-.609t.609-1.406-.609-1.383T12 11.016t-1.406.586-.609 1.383.609 1.406T12 15zM6.984 6.984q0 .797.609 1.406t1.406.609 1.406-.609.609-1.406-.609-1.383-1.406-.586-1.406.586-.609 1.383zM12 2.016q5.391 0 9 3.984l-9 15.984L3 6q3.609-3.984 9-3.984z"/>'
  }
})
