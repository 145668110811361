/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dynamic_feed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12v6.984h9V21h-9q-.797 0-1.383-.609t-.586-1.406v-6.984h1.969zm16.032-.984V6.985H12v4.031h8.016zm0-8.016q.797 0 1.383.609t.586 1.406v6q0 .797-.586 1.383t-1.383.586H12q-.797 0-1.406-.586t-.609-1.383v-6q0-.797.609-1.406T12 3h8.016zm-12 5.016V15h9v2.016h-9q-.797 0-1.406-.609t-.609-1.406V8.017h2.016z"/>'
  }
})
