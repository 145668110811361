/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'library_books': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6.984V5.015H9v1.969h9.984zM15 15v-2.016H9V15h6zm3.984-3.984V9H9v2.016h9.984zm1.032-9q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12zM3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969z"/>'
  }
})
