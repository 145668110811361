/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mark_chat_unread': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 6.984v9q0 .844-.586 1.43T20.015 18H5.999l-3.984 3.984v-18q0-.797.586-1.383t1.383-.586h10.125q-.047.234-.07.492t-.023.492q0 1.359.656 2.508t1.805 1.828 2.508.68q.844 0 1.617-.281t1.383-.75zm-6-3.984q0 .844.422 1.523t1.102 1.078 1.477.398q.844 0 1.523-.398t1.078-1.078T21.984 3t-.398-1.523T20.508.399 18.985.001q-.797 0-1.477.398t-1.102 1.078T15.984 3z"/>'
  }
})
