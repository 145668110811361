/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stacked_line_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 19.969l7.5-7.5L13.5 16.5l7.078-7.969 1.406 1.406L13.5 19.5l-3.984-4.031-6 6zm1.5-4.5l6-6L13.5 13.5l8.484-9.563-1.406-1.406L13.5 10.5 9.516 6.469l-7.5 7.5z"/>'
  }
})
