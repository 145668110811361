/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_agenda': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3q.422 0 .703.281t.281.703v6q0 .422-.281.727t-.703.305H3q-.422 0-.703-.305t-.281-.727v-6q0-.422.281-.703T3 3h17.016zm0 9.984q.422 0 .703.305t.281.727v6q0 .422-.281.703t-.703.281H3q-.422 0-.703-.281t-.281-.703v-6q0-.422.281-.727T3 12.984h17.016z"/>'
  }
})
