/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure_neg_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 11.016h7.969v1.969H2.016v-1.969zm13.031 5.297H21v1.688h-8.625v-1.5l4.172-4.547q.844-.844 1.453-1.875.328-.563.328-1.313 0-.609-.094-.844-.469-1.219-1.781-1.219-2.156 0-2.156 2.297h-2.156q0-1.688 1.125-2.813 1.172-1.172 3.188-1.172h.094q2.766 0 3.703 2.063.234.516.234 1.453 0 .656-.188 1.172-.328.891-.516 1.172-.797 1.266-1.875 2.344z"/>'
  }
})
