/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layers': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15.984l-3.82-2.977Q4.782 10.358 3 8.999l9-6.984 9 6.984q-1.781 1.359-5.156 3.984t-3.844 3zm0 2.579l7.359-5.766L21 14.063l-9 6.984-9-6.984 1.641-1.266z"/>'
  }
})
