/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'short_text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12.984h10.031V15H3.984v-2.016zm0-3.984h16.031v2.016H3.984V9z"/>'
  }
})
