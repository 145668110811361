/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'polymer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3.984l4.5 8.016-4.5 8.016H15L19.5 12l-2.625-4.641L9 20.015H5.016l-4.5-8.016 4.5-8.016H9l-4.5 8.016 2.625 4.641L15 3.984h3.984z"/>'
  }
})
