/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_control': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T12 9.984zm6 0q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.406-.609-.609-1.406.609-1.406T18 9.984zm-12 0q.797 0 1.406.609t.609 1.406-.609 1.406T6 14.014t-1.406-.609-.609-1.406.609-1.406T6 9.984z"/>'
  }
})
