/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drive_file_move_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9l3.984 4.031L12 17.015l-1.453-1.406 1.594-1.594H8.016v-2.016h4.125l-1.547-1.594zm8.016 9V8.016H3.985V18h16.031zm0-12q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h6L12.001 6h8.016z"/>'
  }
})
