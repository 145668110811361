/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airplanemode_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 5.25l1.266-1.266 15.75 15.75L18.75 21l-5.766-5.719v3.703l2.016 1.5v1.5L11.484 21l-3.469.984v-1.5l1.969-1.5V13.5l-7.969 2.484v-1.969l5.953-3.75zM12.984 9L21 14.016v1.969l-3.188-.984-7.828-7.828V3.517q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055v5.484z"/>'
  }
})
