/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15V5.016H8.015V15h7.969zm0-13.969q.797 0 1.406.586T17.999 3v14.016q0 .797-.609 1.383t-1.406.586H8.015q-.797 0-1.406-.586T6 17.016V3q0-.797.609-1.406T8.015.985zM8.016 23.016V21h7.969v2.016H8.016z"/>'
  }
})
