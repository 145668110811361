/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'history_toggle_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.094 19.359l.984 1.734q-1.453.703-3.094.844v-2.016q1.125-.141 2.109-.563zM4.078 12.984H2.062q.141 1.641.844 3.094l1.734-.984q-.422-.984-.563-2.109zm11.016-8.343l.984-1.734q-1.453-.703-3.094-.844v2.016q1.125.141 2.109.563zm4.828 6.375h2.016q-.141-1.641-.844-3.094l-1.734.984q.422.984.563 2.109zM8.906 19.359l-.984 1.734q1.453.703 3.094.844v-2.016q-1.125-.141-2.109-.563zm2.11-15.281V2.062q-1.641.141-3.094.844l.984 1.734q.984-.422 2.109-.563zm7.359 3.094l1.734-1.031q-.984-1.313-2.297-2.25l-.984 1.734q.891.656 1.547 1.547zM4.641 8.906l-1.734-.984q-.703 1.453-.844 3.094h2.016q.141-1.125.563-2.109zm15.281 4.078q-.141 1.125-.563 2.109l1.734.984q.703-1.453.844-3.094h-2.016zm-3.094 5.391l1.031 1.734q1.313-.984 2.25-2.297l-1.734-.984q-.656.891-1.547 1.547zM7.172 5.625l-.984-1.734q-1.359.984-2.297 2.297l1.734.984q.656-.844 1.547-1.547zM5.625 16.828l-1.734.984q.984 1.313 2.297 2.297l.984-1.734q-.844-.656-1.547-1.547zm7.359-9.844h-1.969v5.438l4.266 4.266 1.406-1.406-3.703-3.703V6.985z"/>'
  }
})
