/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_vertical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 12.984v-1.969h2.016v1.969H15zM15 21v-2.016h2.016V21H15zm0-15.984V3h2.016v2.016H15zM18.984 9V6.984H21V9h-2.016zm0-6H21v2.016h-2.016V3zm0 9.984v-1.969H21v1.969h-2.016zm0 8.016v-2.016H21V21h-2.016zm-7.968 0V3h1.969v18h-1.969zm7.968-3.984V15H21v2.016h-2.016zm-12-12V3H9v2.016H6.984zM3 17.016V15h2.016v2.016H3zM3 21v-2.016h2.016V21H3zm0-8.016v-1.969h2.016v1.969H3zm3.984 0v-1.969H9v1.969H6.984zm0 8.016v-2.016H9V21H6.984zM3 5.016V3h2.016v2.016H3zM3 9V6.984h2.016V9H3z"/>'
  }
})
