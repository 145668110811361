/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'horizontal_rule': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 11.016h16.031v1.969H3.984v-1.969z"/>'
  }
})
