/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15V9l-3 2.016V9H6.985v6h7.031v-2.016zm3-12.984q.797 0 1.383.586t.586 1.383v8.016q0 4.219-3 7.102t-7.266 2.883q-3.938 0-6.82-2.906t-2.883-6.797q0-4.266 2.906-7.266t7.078-3h8.016z"/>'
  }
})
