/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data_usage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.984q1.359 0 2.953-.773t2.484-1.852l2.625 1.547q-3 4.078-8.063 4.078-4.125 0-7.055-2.93t-2.93-7.055q0-3.891 2.602-6.727t6.398-3.211v3q-2.531.375-4.266 2.344t-1.734 4.594q0 2.906 2.039 4.945t4.945 2.039zm.984-16.921q3.797.375 6.398 3.211t2.602 6.727q0 2.25-.844 4.078l-2.625-1.547q.469-1.313.469-2.531 0-2.625-1.734-4.594t-4.266-2.344v-3z"/>'
  }
})
