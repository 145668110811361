/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_cellular_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 9h3v11.016h-3V9zm-6 5.016h3v6h-3v-6zm12-10.032h3v16.031h-3V3.984z"/>'
  }
})
