/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alarm_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.547 14.531l4.922-4.969 1.078 1.078-6 6-3.188-3.188 1.031-1.031zM12 20.016q2.906 0 4.945-2.063t2.039-4.969-2.039-4.945T12 6 7.055 8.039t-2.039 4.945 2.039 4.969T12 20.016zm0-16.032q3.75 0 6.375 2.648T21 12.984t-2.625 6.352T12 21.984t-6.375-2.648T3 12.984t2.625-6.352T12 3.984zm-4.125-.609L3.281 7.219l-1.266-1.5 4.594-3.844zm14.109 2.344l-1.266 1.547-4.594-3.891 1.266-1.5z"/>'
  }
})
