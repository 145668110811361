/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'model_training': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 13.5q0 .75-.375 1.406t-.891 1.266-.891 1.195-.375 1.148h-1.969q0-.563-.375-1.148t-.891-1.195-.891-1.266-.375-1.406q0-.984.492-1.758t1.266-1.266 1.758-.492q.984 0 1.758.492t1.266 1.266.492 1.758zm-2.532 6h-1.969V21h1.969v-1.5zm6-6.516q0 1.266-.422 2.391t-1.125 2.063l1.406 1.406q.984-1.172 1.57-2.672t.586-3.188q0-2.063-.867-3.82t-2.273-3.023l-1.453 1.453q1.172.938 1.875 2.344t.703 3.047zm-3-7.968L12 .985v3q-1.875 0-3.492.703T5.649 6.633 3.704 9.516t-.703 3.469q0 1.688.586 3.188t1.57 2.672l1.406-1.406q-.703-.938-1.125-2.063t-.422-2.391q0-1.453.539-2.719t1.5-2.227 2.227-1.5T12.001 6v3z"/>'
  }
})
