/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insights': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 8.016q-.703 0-1.195.398t-.703.984-.023 1.102l-3.563 3.563q-.516-.141-1.031 0l-2.531-2.531q.141-.563-.047-1.148t-.68-.984-1.242-.398q-.703 0-1.195.398t-.68.984-.047 1.148l-4.547 4.547q-.563-.188-1.125.023t-.984.703T.985 18q0 .844.586 1.43t1.43.586q.703 0 1.195-.422t.703-.984.023-1.125l4.547-4.547q.516.141 1.031 0l2.578 2.531q-.188.563 0 1.148t.68.984 1.242.398q.703 0 1.195-.398t.703-.984.023-1.148l3.563-3.516q.563.141 1.125-.047t.984-.68.422-1.242q0-.797-.586-1.383t-1.43-.586zM15 9l.938-2.063 2.063-.938-2.063-.938L15 2.998l-.938 2.063-2.063.938 2.063.938zM3.516 11.016L3.985 9l2.016-.516-2.016-.469-.469-2.016L3 8.015l-2.016.469L3 9z"/>'
  }
})
