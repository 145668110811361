/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_size_select_large': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 18.984h9.984l-3.188-4.266-2.484 3.234-1.828-2.156zM.984 11.016H15V21H3q-.797 0-1.406-.609t-.609-1.406v-7.969zM5.016 3h1.969v2.016H5.016V3zM9 3h2.016v2.016H9V3zM3 3v2.016H.984q0-.75.633-1.383T3 3zm14.016 15.984h1.969V21h-1.969v-2.016zm0-15.984h1.969v2.016h-1.969V3zM.984 6.984H3V9H.984V6.984zM21 3q.75 0 1.383.633t.633 1.383H21V3zm0 3.984h2.016V9H21V6.984zM12.984 3H15v2.016h-2.016V3zm10.032 15.984q0 .75-.633 1.383T21 21v-2.016h2.016zM21 11.016h2.016v1.969H21v-1.969zM21 15h2.016v2.016H21V15z"/>'
  }
})
