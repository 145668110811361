/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device_unknown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.109 15.516h1.781v1.781h-1.781v-1.781zM12 6.703q1.5 0 2.508 1.008t1.008 2.461q0 1.172-1.313 2.344t-1.313 2.109h-1.781q0-.984.398-1.664t.914-.984.914-.773.398-1.031q0-.703-.516-1.219t-1.219-.516-1.219.516-.516 1.219H8.482q0-1.453 1.008-2.461t2.508-1.008zm5.016 12.281V5.015H6.985v13.969h10.031zm0-18q.797 0 1.383.609t.586 1.406v18q0 .797-.586 1.406t-1.383.609H6.985q-.797 0-1.383-.609t-.586-1.406v-18q0-.797.586-1.406T6.985.984h10.031z"/>'
  }
})
