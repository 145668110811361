/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grain': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609-1.383-.609-.586-1.406.586-1.406 1.383-.609zm4.032 4.032q.797 0 1.383.586t.586 1.383-.586 1.406-1.383.609-1.406-.609-.609-1.406.609-1.383 1.406-.586zM18 12q.797 0 1.406.609t.609 1.406-.609 1.383-1.406.586-1.406-.586-.609-1.383.609-1.406T18 12zm-3.984 3.984q.797 0 1.383.609t.586 1.406-.586 1.406-1.383.609-1.406-.609-.609-1.406.609-1.406 1.406-.609zM18 8.016q-.797 0-1.406-.609t-.609-1.406.609-1.406T18 3.986t1.406.609.609 1.406-.609 1.406T18 8.016zM6 15.984q.797 0 1.406.609t.609 1.406-.609 1.406T6 20.014t-1.406-.609-.609-1.406.609-1.406T6 15.984zm0-7.968q.797 0 1.406.586t.609 1.383-.609 1.406T6 12t-1.406-.609-.609-1.406.609-1.383T6 8.016zM9.984 12q.797 0 1.406.609t.609 1.406-.609 1.383-1.406.586-1.383-.586-.586-1.383.586-1.406T9.984 12z"/>'
  }
})
