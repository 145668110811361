/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leave_bags_at_home': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.813 9h.938v.938l1.734 1.734V9h1.5v4.172l3 3V8.016q0-.844-.586-1.43T17.016 6H15V3q0-.422-.305-.703t-.68-.281H9.984q-.375 0-.68.281T8.999 3v3h-.188zm-.797-5.016h1.969V6h-1.969V3.984zm10.172 17.204L2.813 2.813 1.407 4.219l3.609 3.609v11.156q0 .844.586 1.43T6.985 21q0 .422.305.703t.727.281q.375 0 .68-.281T9.002 21h6q0 .422.305.703t.68.281q.422 0 .727-.281t.305-.703q.469 0 .938-.234l1.828 1.828zM8.016 18v-7.172l1.5 1.5V18h-1.5zm3.234 0v-3.938l1.5 1.5V18h-1.5zm3.234 0v-.656l.703.656h-.703z"/>'
  }
})
