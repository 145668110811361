/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hearing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.484 9q0-1.031.75-1.758t1.781-.727 1.758.727T16.5 9t-.727 1.758-1.758.727-1.781-.727T11.484 9zM7.641 2.625Q5.016 5.25 5.016 9t2.625 6.375l-1.406 1.406Q3.001 13.547 3.001 9t3.234-7.781zm9.375 17.391q.797 0 1.383-.609t.586-1.406h2.016q0 1.641-1.172 2.813t-2.813 1.172q-.891 0-1.641-.328-1.922-.984-2.766-3.563-.328-1.031-1.688-2.063-1.922-1.406-2.859-3.141Q6.984 10.969 6.984 9q0-2.953 2.039-4.969t4.992-2.016 4.969 2.016T21 9h-2.016q0-2.109-1.43-3.563t-3.539-1.453-3.563 1.453T8.999 9q0 1.5.797 2.953.656 1.266 2.344 2.531 1.875 1.406 2.391 3 .609 1.781 1.688 2.344.375.188.797.188z"/>'
  }
})
