/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove_shopping_cart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 18q.797 0 1.406.609t.609 1.406-.609 1.383-1.406.586-1.383-.586-.586-1.383.586-1.406T6.984 18zm8.579-5.016l-9-9h13.453q.422 0 .703.305t.281.727q0 .047-.141.469l-3.563 6.469q-.563 1.031-1.734 1.031zM7.406 15h5.063l-2.016-2.016H8.109l-.891 1.641-.047.141q0 .234.234.234zm15.328 7.734L21.468 24l-2.859-2.859q-.609.844-1.594.844-.797 0-1.406-.586T15 20.016q0-1.031.844-1.641l-1.406-1.359H6.985q-.797 0-1.383-.609t-.586-1.406q0-.469.234-.938l1.359-2.484-2.203-4.641L0 2.532l1.266-1.266z"/>'
  }
})
