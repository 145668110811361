/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hdr_weak': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15.984q1.641 0 2.813-1.172t1.172-2.813-1.172-2.813-2.813-1.172-2.836 1.172-1.195 2.813 1.195 2.813 2.836 1.172zm0-9.984q2.484 0 4.242 1.758T23.016 12t-1.758 4.242T17.016 18t-4.242-1.758T11.016 12t1.758-4.242T17.016 6zm-12 2.016q1.641 0 2.813 1.172t1.172 2.813-1.172 2.813-2.813 1.172-2.836-1.172-1.195-2.813T2.18 9.188t2.836-1.172z"/>'
  }
})
