/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_remote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0q4.641 0 7.781 3.234L18.375 4.64Q15.75 2.015 12 2.015T5.625 4.64L4.219 3.234Q7.453 0 12 0zM7.031 6.047Q9.094 3.984 12 3.984t4.969 2.063l-1.406 1.406q-.609-.609-1.664-1.031T12.001 6t-1.898.422-1.664 1.031zM12 15q.797 0 1.406-.609t.609-1.406-.609-1.383T12 11.016t-1.406.586-.609 1.383.609 1.406T12 15zm3-6q.422 0 .703.281t.281.703v12q0 .422-.281.727t-.703.305H9q-.422 0-.703-.305t-.281-.727v-12q0-.422.281-.703T9 9h6z"/>'
  }
})
