/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_horizontal_select': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.422 3.984q-.141 0-.281.094-2.203.797-4.523 1.219t-4.617.422q-4.688 0-9.141-1.641-.141-.094-.281-.094-.563 0-.563.656v14.719q0 .656.563.656.141 0 .281-.094 2.203-.797 4.523-1.219T12 18.28q4.688 0 9.141 1.641.141.094.281.094.563 0 .563-.656V4.64q0-.656-.563-.656z"/>'
  }
})
