/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.625 5.859L21.984 12l-4.359 6.141q-.609.844-1.641.844H5.015q-.797 0-1.406-.586T3 17.016V6.985q0-.797.609-1.383t1.406-.586h10.969q1.031 0 1.641.844z"/>'
  }
})
