/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'usb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 6.984h3.984v4.031H18v1.969q0 .844-.586 1.43t-1.43.586h-3v3.047q1.219.656 1.219 1.969 0 .891-.633 1.547t-1.57.656-1.57-.656-.633-1.547q0-1.313 1.219-1.969V15h-3q-.844 0-1.43-.586T6 12.984v-2.063q-1.219-.656-1.219-1.922 0-.938.656-1.57t1.547-.633 1.547.633.656 1.57q0 1.313-1.172 1.922v2.063h3V5.015H8.999l3-4.031 3 4.031h-2.016v7.969h3v-1.969h-.984V6.984z"/>'
  }
})
