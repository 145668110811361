/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send_to_mobile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 17.016h1.969V21q0 .844-.586 1.43t-1.383.586H6.985q-.797 0-1.383-.586T5.016 21V3q0-.844.586-1.406t1.383-.563L17.016.984q.797 0 1.383.586T18.985 3v3.984h-1.969V6H6.985v12h10.031v-.984zM21.984 12L18 8.016v3h-5.016v1.969H18v3z"/>'
  }
})
