/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gps_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.266 17.531L6.469 7.734q-.609.797-1.031 2.039T5.016 12q0 2.906 2.039 4.945T12 18.984q.984 0 2.227-.422t2.039-1.031zM3 4.266L4.266 3 21 19.734 19.734 21l-2.063-2.063q-2.109 1.734-4.688 2.016v2.063h-1.969v-2.063q-2.953-.328-5.297-2.672t-2.672-5.297H.982v-1.969h2.063q.094-1.078.703-2.461t1.313-2.227zm17.953 6.75h2.063v1.969h-2.063q-.234 1.828-.984 3.188l-1.5-1.5q.516-1.266.516-2.672 0-2.906-2.039-4.945t-4.945-2.039q-1.406 0-2.672.516l-1.5-1.5q1.453-.75 3.188-.984V.986h1.969v2.063q2.953.328 5.297 2.672t2.672 5.297z"/>'
  }
})
