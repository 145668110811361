/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'repeat_one': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 15h-1.5v-3.984h-1.5V9.985L12 9.001h.984v6zm4.032 2.016v-4.031h1.969v6h-12v3l-3.984-3.984 3.984-3.984v3h10.031zM6.984 6.984v4.031H5.015v-6h12v-3l3.984 3.984-3.984 3.984v-3H6.984z"/>'
  }
})
