/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'soap': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.141 5.016l-7.219 6.797q-.938.844-.938 2.156v6.047q0 .797.422 1.477t1.102 1.102 1.477.422h13.781q.516 0 .867-.375t.352-.891-.352-.891-.867-.375H12v-.984h7.734q.516 0 .891-.375t.375-.891-.375-.867-.891-.352H12v-1.031h8.766q.516 0 .867-.352t.352-.867-.352-.891-.867-.375H12v-.984h6.75q.516 0 .891-.375t.375-.891-.375-.891-.891-.375H8.859q.328-.563.68-1.148t.586-1.008l.234-.422q.141-.281.141-.563 0-.375-.281-.703l-.164-.164-.375-.398-.375-.398-.164-.164zm4.875 1.218q.281 0 .516.234t.234.516q0 .328-.234.539t-.516.211q-.328 0-.539-.211t-.211-.539q0-.281.211-.516t.539-.234zm0-1.5q-.938 0-1.594.656t-.656 1.594.656 1.594 1.594.656 1.594-.656.656-1.594-.656-1.594-1.594-.656zm5.718.75q.234 0 .375.164T20.25 6t-.141.352-.375.164q-.188 0-.328-.164T19.265 6t.141-.352.328-.164zm0-1.5q-.797 0-1.383.586T17.765 6t.586 1.43 1.383.586q.844 0 1.43-.586T21.75 6t-.586-1.43-1.43-.586zm-3.234-3q-.609 0-1.055.445T15 2.484q0 .656.445 1.078t1.055.422 1.055-.422T18 2.484q0-.609-.445-1.055T16.5.984z"/>'
  }
})
