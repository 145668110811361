/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event_seat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984H6.985V5.015q0-.797.609-1.406T9 3h6q.797 0 1.406.609t.609 1.406v7.969zm-15-3h3v3h-3v-3zm16.968 0h3v3h-3v-3zM3.984 21v-6h16.031v6h-3v-3H6.984v3h-3z"/>'
  }
})
