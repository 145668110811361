/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_data_setting': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 20.484q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm3.704-.984l1.078.797q.094.094.094.234l-.047.047v.047l-.984 1.734q-.141.234-.328.141l-1.219-.516q-.141.141-.844.469l-.188 1.359q0 .188-.281.188H18q-.281 0-.281-.188l-.188-1.359q-.375-.188-.844-.469l-1.219.516q-.234.094-.328-.141l-.984-1.734v-.047l-.047-.047q0-.141.094-.234l1.078-.797q0-.094-.023-.281t-.023-.234.023-.234.023-.234l-1.078-.844q-.141-.141-.047-.328l.984-1.734q.094-.188.328-.094l1.219.516q.563-.375.844-.516l.188-1.313q0-.188.281-.188h1.969q.281 0 .281.188l.188 1.313q.188.094.844.516l1.219-.516q.234-.094.328.094l.984 1.734v.047q.047.047.047.094 0 .094-.094.188l-1.078.844q.047.141.047.469 0 .094-.023.281t-.023.234zm-3.704-8.016q-3.094 0-5.297 2.203t-2.203 5.297q0 .375.094 1.031H0L20.016-.001l-.047 11.578q-.656-.094-.984-.094z"/>'
  }
})
