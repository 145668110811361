/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wrap_text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 11.016q1.641 0 2.813 1.172t1.172 2.813-1.172 2.813-2.813 1.172H15v2.016l-3-3 3-3v2.016h2.25q.797 0 1.406-.609t.609-1.406-.609-1.406-1.406-.609H3.984v-1.969h13.031zm3-6v1.969H3.985V5.016h16.031zM3.984 18.984v-1.969h6v1.969h-6z"/>'
  }
})
