/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 14.016V9H6.985v5.016h10.031zM12 18.516q.656 0 1.078-.445t.422-1.055-.422-1.055T12 15.516t-1.078.445-.422 1.055.422 1.055 1.078.445zm6.984-1.594q0 1.313-.68 2.203t-1.898.891h.094l1.5 1.5v.469h-2.016l-1.969-1.969h-3.797l-1.969 1.969h-2.25v-.469l1.594-1.594q-1.078-.234-1.828-1.078t-.75-1.922V8.484q0-1.922 1.594-2.648t4.359-.82l.797-1.5H6.984v-1.5h10.031v1.5h-3.281l-.75 1.5q2.953.094 4.477.797t1.523 2.672v8.438z"/>'
  }
})
