/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flight_takeoff': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.078 9.656q.141.609-.164 1.125t-.914.703q-5.813 1.547-9.656 2.578l-5.297 1.406-1.594.469-2.625-4.5 1.453-.375 1.969 1.5 4.969-1.313-4.125-7.172 1.922-.516 6.891 6.422 5.344-1.406q.609-.188 1.148.141t.68.938zM2.484 18.984h19.031V21H2.484v-2.016z"/>'
  }
})
