/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subway': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15.891V9q0-1.781-1.547-2.391T12 6q-2.766 0-4.383.609T6 9v6.891q0 1.078.773 1.852t1.852.773L7.5 19.641v.375h1.688l1.5-1.5h2.813l1.5 1.5h1.5v-.375l-1.125-1.125q1.078 0 1.852-.773t.773-1.852zm-.187-13.078q2.016.75 3.094 2.344t1.078 3.703v13.125H2.016V8.86q0-2.109 1.078-3.703t3.094-2.344q2.016-.797 5.813-.797t5.813.797zM7.031 9h9.984v5.016H7.031V9zm.469 6.984q0-.422.281-.703T8.484 15t.727.281.305.703-.305.727-.727.305-.703-.305-.281-.727zm6.984 0q0-.422.305-.703t.727-.281.703.281.281.703-.281.727-.703.305-.727-.305-.305-.727z"/>'
  }
})
