/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_box': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12.984v-1.969h-4.031V6.984h-1.969v4.031H6.985v1.969h4.031v4.031h1.969v-4.031h4.031zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43V5.014q0-.844.586-1.43t1.43-.586h13.969z"/>'
  }
})
