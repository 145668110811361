/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563L5.015 12zm2.016-15q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
