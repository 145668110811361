/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stacked_bar_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 9h3v3h-3V9zm0 3.984h3v7.031h-3v-7.031zm4.968 0h3V15h-3v-2.016zm0 3h3v4.031h-3v-4.031zM6 5.016h3V9H6V5.016zm0 4.968h3v10.031H6V9.984z"/>'
  }
})
