/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collections_bookmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 12V3.984H15V12l2.484-1.5zm0-9.984q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12zM3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969z"/>'
  }
})
