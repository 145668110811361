/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_scan_wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 8.016h1.969V6h-1.969v2.016zm1.968 7.968v-6h-1.969v6h1.969zM12 3q6.375 0 12 4.266L12 21.985 0 7.219Q5.531 3 12 3z"/>'
  }
})
