/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'night_shelter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3L3.984 9v12h16.031V9zm-2.25 9.516q.516 0 .891.352t.375.867-.375.891-.891.375-.891-.375-.375-.891.375-.867.891-.352zM17.016 18h-1.031v-1.5H8.016V18H6.985v-6.984h1.031v4.5h3.469V12h3.516q.844 0 1.43.586t.586 1.43V18z"/>'
  }
})
