/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 4.078q2.953.375 4.992 2.625T20.015 12t-2.039 5.297-4.992 2.625v-2.016q2.156-.375 3.586-2.039T18 12t-1.43-3.867-3.586-2.039v3.891L8.437 5.532 12.984.985v3.094zm-5.906 14.25l1.453-1.453q1.078.797 2.484 1.031v2.016q-2.25-.281-3.938-1.594zm-.984-5.344q.234 1.359.984 2.484l-1.406 1.406q-1.313-1.734-1.594-3.891h2.016zm1.031-4.453q-.844 1.219-1.031 2.484H4.078q.281-2.109 1.641-3.891z"/>'
  }
})
