/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_outer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 11.016v1.969H6.984v-1.969H9zM12.984 15v2.016h-1.969V15h1.969zm6 3.984V5.015H5.015v13.969h13.969zM3 3h18v18H3V3zm14.016 8.016v1.969H15v-1.969h2.016zm-4.032 0v1.969h-1.969v-1.969h1.969zm0-4.032V9h-1.969V6.984h1.969z"/>'
  }
})
