/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'high_quality': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 13.5v-3H16.5v3h-2.016zm3.516.516V9.985q0-.422-.281-.703t-.703-.281h-3q-.422 0-.727.281t-.305.703v4.031q0 .422.305.703t.727.281h.75v1.5h1.5V15h.75q.422 0 .703-.281t.281-.703zM11.016 15V9h-1.5v2.484H7.5V9H6v6h1.5v-2.016h2.016V15h1.5zm7.968-11.016q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43v-12q0-.844.586-1.43t1.43-.586h13.969z"/>'
  }
})
