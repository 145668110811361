/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flash_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 9.984l-1.547 2.672-8.484-8.484V2.016h10.031l-4.031 7.969h4.031zM3.281 3l15.703 15.75-1.266 1.266-4.125-4.172-3.609 6.141v-9h-3V9.282L2.015 4.266z"/>'
  }
})
