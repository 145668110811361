/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_emotions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 11.016q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zM12 18q1.688 0 3.047-1.102t1.969-2.883H6.985q.609 1.781 1.969 2.883T12.001 18zM8.484 8.016q-.609 0-1.055.445t-.445 1.055.445 1.055 1.055.445 1.055-.445.445-1.055-.445-1.055-1.055-.445zm3.516-6q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
