/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leaderboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 21H2.016V9H7.5v12zm7.266-18H9.235v18h5.531V3zm7.218 8.016H16.5V21h5.484v-9.984z"/>'
  }
})
