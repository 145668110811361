/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file_download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18h13.969v2.016H5.016V18zm13.968-9L12 15.984 5.016 9H9V3h6v6h3.984z"/>'
  }
})
