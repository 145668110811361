/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi_calling': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 4.969q-.047-.094-.469-.398t-1.148-.656-1.711-.633-2.156-.281-2.156.281-1.711.633-1.148.656-.469.398L16.5 12zm-1.968 10.547q-1.875 0-3.563-.563-.609-.234-1.031.234l-2.203 2.203q-2.109-1.078-3.82-2.789t-2.789-3.82l2.203-2.203q.422-.422.234-1.031-.563-1.688-.563-3.563 0-.375-.281-.68T7.5 2.999H3.984q-.375 0-.68.305t-.305.68q0 2.813.867 5.367t2.414 4.688 3.68 3.68 4.688 2.414 5.367.867q.375 0 .68-.305t.305-.68v-3.516q0-.422-.305-.703t-.68-.281z"/>'
  }
})
