/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar_view_day': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 6h18v2.016H3V6zm0 3.984h18V15H3V9.984zm0 7.032h18v1.969H3v-1.969z"/>'
  }
})
