/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'receipt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 21.984V2.015l1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5v19.969l-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5zM18 9V6.984H6V9h12zm0 3.984v-1.969H6v1.969h12zm0 4.032V15H6v2.016h12z"/>'
  }
})
