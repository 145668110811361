/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping_basket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.016q.797 0 1.406-.609t.609-1.406-.609-1.406T12 12.986t-1.406.609-.609 1.406.609 1.406 1.406.609zM9 9h6l-3-4.406zm8.203 0h4.781q.422 0 .727.281t.305.703q-.469 1.875-1.406 5.273t-1.172 4.289q-.422 1.453-1.922 1.453H5.485q-1.5 0-1.922-1.453l-2.531-9.281q-.047-.094-.047-.281 0-.422.305-.703T2.017 9h4.781l4.359-6.563q.281-.422.844-.422.609 0 .844.422z"/>'
  }
})
