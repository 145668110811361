/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unarchive': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.109 5.016H18.89l-.938-1.031h-12zM12 9.516L6.516 15h3.469v2.016h4.031V15h3.469zm8.531-4.313Q21 5.766 21 6.516v12.469q0 .797-.609 1.406T18.985 21H5.016q-.844 0-1.43-.586T3 18.984V6.515q0-.75.469-1.313l1.359-1.641Q5.297 2.998 6 2.998h12q.703 0 1.172.563z"/>'
  }
})
