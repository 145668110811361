/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_wide_angle_select': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3.984q-2.063 0-3.984.188t-3.984.563l-.891.141-.281.891q-.844 3.141-.844 6.234t.844 6.234l.281.891.891.141q2.063.375 3.984.563t3.984.188 3.984-.188 3.984-.563l.891-.141.281-.891q.844-3.141.844-6.234t-.844-6.234l-.281-.891-.891-.141q-2.063-.375-3.984-.563T12 3.984z"/>'
  }
})
