/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder_special': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.953 17.016l-.797-3.328 2.578-2.25-3.375-.281L15 8.016l-1.359 3.141-3.375.281 2.578 2.25-.797 3.328L15 15.282zM20.016 6q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h6L12.001 6h8.016z"/>'
  }
})
