/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'north': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 9l1.406 1.406 4.594-4.594v16.172h1.969V5.812l4.594 4.594L18.985 9l-6.984-6.984z"/>'
  }
})
