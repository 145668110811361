/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dvr': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 12v2.016H5.015V12h1.969zm0-3.984v1.969H5.015V8.016h1.969zm12 3.984v2.016H8.015V12h10.969zm0-3.984v1.969H8.015V8.016h10.969zm2.016 9v-12H3v12h18zM21 3q.797 0 1.406.609t.609 1.406l-.047 12q0 .797-.586 1.383t-1.383.586h-5.016V21H8.014v-2.016H2.998q-.797 0-1.406-.586t-.609-1.383v-12q0-.797.609-1.406T2.998 3h18z"/>'
  }
})
