/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_motorsports': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.938 11.203q.328 3.563-2.039 6.188t-5.883 2.625H3.985q-.797 0-1.383-.609t-.586-1.406v-.797q0-.891.188-2.203h8.156q1.453 0 2.531-1.078t1.078-2.531q0-2.391-2.156-3.328L7.032 6.048q2.531-1.828 5.953-2.016 3.375-.234 6 1.852t2.953 5.32zM12 11.391q0 .656-.469 1.125t-1.125.469H2.625q.891-3.281 2.813-5.438l5.578 2.344q.984.375.984 1.5z"/>'
  }
})
