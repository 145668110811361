/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reduce_capacity': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 3.984q0-.797.586-1.383T18 2.015t1.43.586.586 1.383q0 .844-.586 1.43T18 6t-1.43-.586-.586-1.43zm4.782 3.61q-.609-.281-1.313-.445T18 6.985q-.984 0-1.922.281.938.891.938 2.156v.563h4.969v-.563q0-.609-.328-1.102t-.891-.727zM6 6q.844 0 1.43-.586t.586-1.43q0-.797-.586-1.383T6 2.015t-1.43.586-.586 1.383q0 .844.586 1.43T6 6zm1.922 1.266Q6.984 6.985 6 6.985q-.75 0-1.453.164t-1.313.445q-.563.234-.891.727t-.328 1.102v.563h4.969v-.563q0-1.266.938-2.156zm2.062-3.282q0-.797.586-1.383T12 2.015t1.43.586.586 1.383q0 .844-.586 1.43T12 6t-1.43-.586-.586-1.43zm6 6H8.015v-.563q0-.609.328-1.102t.891-.727q.609-.281 1.313-.445T12 6.983t1.453.164 1.313.445q.563.234.891.727t.328 1.102v.563zm-.984 6q0-.797.586-1.383t1.43-.586q.797 0 1.383.586t.586 1.383q0 .844-.586 1.43T17.016 18q-.844 0-1.43-.586T15 15.984zm6 6h-8.016v-.563q0-.609.352-1.102t.867-.727q.656-.281 1.359-.445t1.453-.164 1.43.164 1.336.445q.563.234.891.727T21 21.421v.563zm-15.984-6q0-.797.586-1.383t1.383-.586q.844 0 1.43.586t.586 1.383q0 .844-.586 1.43T6.985 18q-.797 0-1.383-.586t-.586-1.43zm6 6H3v-.563q0-.609.328-1.102t.891-.727q.656-.281 1.336-.445t1.43-.164 1.453.164 1.359.445q.516.234.867.727t.352 1.102v.563zm1.734-9v-1.969h-1.5v1.969H9l3 3 3-3h-2.25z"/>'
  }
})
