/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_system_daydream': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 19.031V4.968H3v14.063h18zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18zM9 15.984q-1.219 0-2.109-.891T6 12.984q0-1.125.773-1.992t1.898-.961h.188Q9.843 8.015 12 8.015q1.313 0 2.297.867t1.172 2.133h.047q1.031 0 1.758.727t.727 1.758-.727 1.758-1.758.727H9z"/>'
  }
})
