/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_legroom_extra': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.828 17.25q.328.563.094 1.172t-.797.891l-3.703 1.688L15 14.017H8.016q-1.219 0-2.109-.891t-.891-2.109V3.001h6v6h3.516q.516 0 1.031.328t.75.797l3.375 6.984 1.125-.516q.563-.234 1.148-.047t.867.703zM3.984 12q0 1.219.891 2.109T6.984 15h6v2.016h-6q-2.063 0-3.516-1.477T2.015 12V3h1.969v9z"/>'
  }
})
