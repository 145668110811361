/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_with': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 15v3h3L12 23.016 6.984 18h3v-3h4.031zm9-3L18 17.016v-3h-3V9.985h3v-3zM9 9.984v4.031H6v3L.984 11.999 6 6.983v3h3zM9.984 9V6h-3L12 .984 17.016 6h-3v3H9.985z"/>'
  }
})
