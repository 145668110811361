/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'square_foot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 17.016h5.766L6.984 11.25v5.766zm10.688.656l2.344 2.344H6q-.797 0-1.406-.609t-.609-1.406V3.985l2.344 2.344-1.031 1.078.703.703 1.031-1.078 1.969 1.969-1.078 1.031.703.75 1.078-1.078 1.969 1.969-1.078 1.031.703.703 1.078-1.031 1.922 1.922-1.078 1.078.75.703 1.031-1.078 1.969 1.969-1.078 1.031.703.703z"/>'
  }
})
