/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic_external_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.234 6.984H4.781q-.375-.375-.586-.891t-.211-1.078q0-.844.422-1.523t1.102-1.078 1.477-.398q.844 0 1.523.398t1.078 1.078.398 1.523q0 .563-.211 1.078t-.539.891zm6.75-4.968q1.125 0 2.039.539t1.453 1.43.539 2.016v15.984h-2.016V6.001q0-.844-.586-1.43t-1.43-.586q-.797 0-1.383.586t-.586 1.43v12q0 1.125-.539 2.016t-1.453 1.43-2.039.539q-1.078 0-1.992-.539t-1.453-1.43-.539-2.016h-.984L3.984 8.017h6L9 18.001h-.984q0 .844.586 1.43t1.383.586q.844 0 1.43-.586t.586-1.43v-12q0-1.125.539-2.016t1.453-1.43 1.992-.539z"/>'
  }
})
