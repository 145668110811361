/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attachment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 12.516q0-2.297 1.594-3.914t3.891-1.617h10.5q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813-2.813 1.172H9.517q-1.031 0-1.781-.727t-.75-1.758.75-1.781 1.781-.75h7.5v2.016H9.423q-.422 0-.422.492t.422.492h8.578q.797 0 1.406-.586t.609-1.383-.609-1.406-1.406-.609h-10.5q-1.453 0-2.484 1.031t-1.031 2.484 1.031 2.461 2.484 1.008h9.516v2.016H7.501q-2.297 0-3.891-1.594t-1.594-3.891z"/>'
  }
})
