/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toggle_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15q1.219 0 2.109-.891T20.016 12t-.891-2.109T17.016 9t-2.109.891T14.016 12t.891 2.109 2.109.891zm0-8.016q2.063 0 3.516 1.477T21.985 12t-1.453 3.539-3.516 1.477H6.985q-2.063 0-3.516-1.477T2.016 12t1.453-3.539 3.516-1.477h10.031z"/>'
  }
})
