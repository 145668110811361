/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery_unknown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.297 12.703Q15 12 15 11.015q0-1.219-.891-2.109T12 8.015t-2.109.891T9 11.015h1.5q0-.609.422-1.055T12 9.515t1.078.445.422 1.055-.422 1.031l-.938.938q-.938.938-.938 2.016h1.594q0-.75.844-1.594zm-1.359 5.25v-1.922h-1.875v1.922h1.875zm2.718-13.969q.563 0 .961.398t.398.961v15.328q0 .563-.398.938t-.961.375H8.343q-.563 0-.961-.375t-.398-.938V5.343q0-.563.398-.961t.961-.398h1.641V2.015h4.031v1.969h1.641z"/>'
  }
})
