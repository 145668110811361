/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'park': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 12L21 18h-7.031v3.984h-3.938V18H3.093l3.891-6H5.062L12 2.016 18.984 12h-1.969z"/>'
  }
})
