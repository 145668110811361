/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speaker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12q1.219 0 2.109.891T15 15t-.891 2.109T12 18t-2.109-.891T9 15t.891-2.109T12 12zm0 8.016q2.063 0 3.539-1.477T17.016 15t-1.477-3.539T12 9.984t-3.539 1.477T6.984 15t1.477 3.539T12 20.016zm0-16.032q-.844 0-1.43.586T9.984 6t.586 1.43 1.43.586q.797 0 1.406-.609t.609-1.406-.609-1.406T12 3.986zm5.016-1.968q.797 0 1.383.586t.586 1.383v16.031q0 .797-.586 1.383t-1.383.586H6.985q-.797 0-1.383-.586t-.586-1.383V3.985q0-.797.586-1.383t1.383-.586h10.031z"/>'
  }
})
