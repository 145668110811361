/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube_searched_for': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 14.016l4.969 4.969-1.453 1.5-5.016-5.016v-.75l-.281-.281q-.75.609-1.992 1.078t-2.227.469q-1.875 0-3.328-.891l1.5-1.453q.938.375 1.828.375 1.875 0 3.188-1.313t1.313-3.188-1.313-3.188-3.188-1.313-3.188 1.313-1.313 3.188h3.469l-4.125 3.984-3.844-3.984h2.484q0-2.672 1.922-4.594t4.594-1.922q2.719 0 4.617 1.898t1.898 4.617q0 .984-.469 2.227t-1.125 1.992l.281.281h.797z"/>'
  }
})
