/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transfer_within_a_station': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.766 8.906L3 23.015h2.109l1.734-8.016 2.156 2.016v6h2.016v-7.547l-2.063-2.063.609-3q2.109 2.578 5.438 2.578v-1.969q-1.359 0-2.508-.68t-1.852-1.805l-.938-1.594q-.516-.938-1.688-.938-.422 0-.75.141l-5.25 2.156v4.688h1.969V9.654l1.781-.75zm3.75-3.422q-.797 0-1.406-.586t-.609-1.383.609-1.406T9.516 1.5t1.383.609.586 1.406-.586 1.383-1.383.586zm9.984 14.25V18l2.484 2.484-2.484 2.531v-1.781h-5.484v-1.5H19.5zm-3-4.218h5.484v1.5H16.5v1.734l-2.484-2.484 2.484-2.531v1.781z"/>'
  }
})
