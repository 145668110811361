/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'read_more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 6.984h9V9h-9V6.984zm0 8.016h9v2.016h-9V15zm3-3.984h6v1.969h-6v-1.969zm-3 .984L8.015 6.984v4.031h-6v1.969h6v4.031z"/>'
  }
})
