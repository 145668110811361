/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plagiarism': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 2.016H6q-.844 0-1.43.586t-.586 1.383v16.031q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383v-12zm1.031 17.437l-1.875-1.875q-1.031.516-2.156.375t-1.969-.984q-.703-.656-.938-1.57t0-1.805.938-1.547q.656-.703 1.547-.938t1.805 0 1.57.938q.844.844.984 1.969t-.375 2.156l1.875 1.875zM12.984 9V3.516L18.515 9h-5.531zm-3 5.484q0-.609.445-1.055t1.055-.445q.656 0 1.078.445t.422 1.055q0 .656-.422 1.078t-1.078.422q-.609 0-1.055-.422t-.445-1.078z"/>'
  }
})
