/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table_rows': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 6.984H2.015V2.015h19.969v4.969zm0 2.532H2.015v4.969h19.969V9.516zm0 7.5H2.015v4.969h19.969v-4.969z"/>'
  }
})
