/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'picture_as_pdf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 11.484v-3H15v3h-.984zM3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969zM9 9.516V8.485h.984v1.031H9zm11.484-1.032v-1.5h-3v6h1.5v-1.969h1.5v-1.5h-1.5V8.484h1.5zm-3.984 3v-3q0-.609-.422-1.055T15 6.984h-2.484v6H15q.656 0 1.078-.445t.422-1.055zm-5.016-1.968V8.485q0-.609-.445-1.055t-1.055-.445H7.5v6H9v-1.969h.984q.609 0 1.055-.445t.445-1.055zm8.532-7.5q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12z"/>'
  }
})
