/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grass': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016H2.016V18h5.719q-.516-2.109-2.063-3.656t-3.656-2.063q.469-.141.961-.211T3.985 12q1.688 0 3.141.633t2.531 1.711 1.711 2.531.633 3.141zm9.984-7.735q-.469-.141-.961-.211T20.015 12q-2.203 0-4.031 1.078t-2.859 2.859q.234.469.398.984t.258 1.078q.188.984.188 2.016h8.016v-2.016h-5.766q.563-2.109 2.109-3.656t3.656-2.063zm-6.328-1.265q.563-1.547 1.617-2.836T19.734 6q-1.594.094-3 .727t-2.461 1.711-1.664 2.508-.609 3.07q1.453-1.922 3.656-3zm-4.218-2.157q-.656-1.641-1.875-2.906T6.704 3.984Q7.782 5.39 8.392 7.101t.609 3.586q0 .188-.023.328t-.023.281q.328.188.633.398t.586.445q.188-.891.492-1.711t.773-1.57z"/>'
  }
})
