/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mediation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12L18 15.984l-1.406-1.406 1.594-1.594h-5.25q-.234 2.344-1.57 4.195t-3.352 2.883q-.047.797-.445 1.477t-1.078 1.078-1.477.398q-.844 0-1.523-.422t-1.078-1.102-.398-1.477q0-.844.398-1.523t1.078-1.078 1.523-.398q.703 0 1.313.305t.984.82q1.453-.75 2.414-2.109t1.195-3.047H7.828q-.328.891-1.102 1.453T5.015 15q-.844 0-1.523-.398t-1.078-1.078-.398-1.523.398-1.523T3.492 9.4t1.523-.398q.938 0 1.711.563t1.102 1.453h3.094q-.234-1.688-1.195-3.023T7.313 5.862q-.375.516-.984.82t-1.313.305q-.844 0-1.523-.398T2.415 5.511t-.398-1.523q0-.797.398-1.477t1.078-1.102T5.016.987q.797 0 1.477.398t1.078 1.078.398 1.477q2.016 1.031 3.352 2.883t1.617 4.195h5.203l-1.547-1.594L18 8.018z"/>'
  }
})
