/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'live_help': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.047 10.266q.938-.938.938-2.25 0-1.641-1.172-2.836T12 3.985 9.187 5.18 8.015 8.016h1.969q0-.797.609-1.406t1.406-.609 1.406.609.609 1.406-.609 1.406l-1.219 1.266q-1.172 1.266-1.172 2.813v.516h1.969q0-1.594 1.172-2.859zM12.984 18v-2.016h-1.969V18h1.969zm6-15.984q.797 0 1.406.586t.609 1.383v14.016q0 .797-.609 1.406t-1.406.609H15l-3 3-3-3H5.016q-.844 0-1.43-.586T3 18V3.984q0-.844.586-1.406t1.43-.563h13.969z"/>'
  }
})
