/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radio': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 12V8.016H3.985V12h12V9.984h2.016V12h2.016zM6.984 20.016q1.219 0 2.109-.891t.891-2.109-.891-2.109-2.109-.891-2.109.891-.891 2.109.891 2.109 2.109.891zM3.234 6.141L15.89.985l.656 1.688-8.25 3.328h11.719q.844 0 1.406.586t.563 1.43v12q0 .844-.563 1.406t-1.406.563H3.984q-.844 0-1.406-.563t-.563-1.406v-12q0-1.406 1.219-1.875z"/>'
  }
})
