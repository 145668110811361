/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sentiment_very_satisfied': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 14.016h10.031q-.609 1.781-1.969 2.883t-3.047 1.102-3.047-1.102-1.969-2.883zm5.016 6q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm-5.016 7.5q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055zm7.032 0q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445-1.055-.445-.445-1.055z"/>'
  }
})
