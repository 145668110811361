/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fence': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 12V9.984h-2.016v-3l-3-3L14.015 6l-2.016-2.016L9.983 6 8.014 3.984l-3 3v3H2.998V12h2.016v2.016H2.998v1.969h2.016v4.031h13.969v-4.031h2.016v-1.969h-2.016V12h2.016zm-5.016-5.156l1.031.984v2.156h-2.016V7.828l.422-.422zm-3.984 0l.609.563.375.422v2.156h-1.969V7.829l.375-.422zm-.984 7.172V12h1.969v2.016h-1.969zm1.968 1.968V18h-1.969v-2.016h1.969zm-6-8.156l1.031-.984.984.984v2.156H6.983V7.828zm0 4.172H9v2.016H6.984V12zm0 3.984H9V18H6.984v-2.016zM17.016 18H15v-2.016h2.016V18zm0-3.984H15V12h2.016v2.016z"/>'
  }
})
