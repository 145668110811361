/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'assistant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.875 12.891L18 11.016l-4.125-1.875L12 5.016l-1.875 4.125L6 11.016l4.125 1.875L12 17.016zm5.109-10.875q.797 0 1.406.586t.609 1.383v14.016q0 .797-.609 1.406t-1.406.609H15l-3 3-3-3H5.016q-.797 0-1.406-.609t-.609-1.406V3.985q0-.797.609-1.383t1.406-.586h13.969z"/>'
  }
})
