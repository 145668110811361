/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gavel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.844 9.469l5.625 5.672-2.813 2.813-5.672-5.625zM12.328.984l5.625 5.672-2.813 2.813-5.672-5.625zM5.25 8.063L8.063 5.25l14.156 14.156-2.813 2.813zM.984 21h12v2.016h-12V21z"/>'
  }
})
