/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_missed_outgoing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 8.391l1.406-1.406L12 14.579l5.578-5.578h-4.594V6.985H21v8.016h-2.016v-4.594L12 17.391z"/>'
  }
})
