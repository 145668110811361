/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_book': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.484 14.344q1.219 0 2.531.234v1.5q-.938-.234-2.531-.234-2.813 0-4.5.984V15.14q1.781-.797 4.5-.797zm-4.5-1.875q1.969-.797 4.5-.797 1.219 0 2.531.234v1.5q-.938-.234-2.531-.234-2.813 0-4.5.984v-1.688zm4.5-1.969q-2.813 0-4.5.984V9.843q1.875-.844 4.5-.844 1.219 0 2.531.234v1.547q-1.125-.281-2.531-.281zM21 18.516V6.985q-1.547-.469-3.516-.469-3.047 0-5.484 1.5V19.5q2.438-1.5 5.484-1.5 1.828 0 3.516.516zM17.484 4.5q3.563 0 5.531 1.5v14.578q0 .188-.164.352t-.352.164q-.141 0-.234-.047-1.922-1.031-4.781-1.031-3.047 0-5.484 1.5-2.016-1.5-5.484-1.5-2.531 0-4.781 1.078-.047 0-.117.023t-.117.023q-.188 0-.352-.141t-.164-.328V5.999q2.016-1.5 5.531-1.5 3.469 0 5.484 1.5 2.016-1.5 5.484-1.5z"/>'
  }
})
