/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984L12 6h8.016q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h6z"/>'
  }
})
