/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party_mode': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.016q2.063 0 3.539-1.477T17.016 12q0-.422-.094-.984h-2.109q.188.656.188.984 0 1.219-.891 2.109T12.001 15H8.017q1.547 2.016 3.984 2.016zm0-10.032q-2.063 0-3.539 1.477T6.984 12q0 .422.094.984h2.109q-.188-.656-.188-.984 0-1.219.891-2.109T11.999 9h3.984q-1.547-2.016-3.984-2.016zm8.016-3q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h3.188l1.828-1.969h6l1.828 1.969h3.188z"/>'
  }
})
