/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unfold_more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.188L15.188 15l1.406 1.406L12 21l-4.594-4.594L8.812 15zm0-12.375L8.812 9.001 7.406 7.595 12 3.001l4.594 4.594-1.406 1.406z"/>'
  }
})
