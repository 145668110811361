/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'timelapse': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm4.219 5.765q.75.75 1.266 1.969t.516 2.25-.492 2.25-1.242 1.969Q14.533 18 12.048 18t-4.266-1.781L12.001 12V6q1.031 0 2.25.516t1.969 1.266z"/>'
  }
})
