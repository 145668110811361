/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'amp_stories': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 6H21v11.016h-2.016V6zM3 6h2.016v11.016H3V6zm3.984-2.016h10.031v15H6.984v-15z"/>'
  }
})
