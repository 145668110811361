/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'departure_board': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 3.984V8.25l2.859 1.688-.75 1.219L15 9.001V3.985h1.5zm-.516 9q2.063 0 3.539-1.453T21 8.015t-1.477-3.539-3.539-1.477-3.516 1.477-1.453 3.539 1.453 3.516 3.516 1.453zm-2.484 6q.656 0 1.078-.445T15 17.484t-.422-1.055-1.078-.445-1.078.445T12 17.484t.422 1.055 1.078.445zm-10.5-6h8.109Q9 10.968 9 8.015H3v4.969zm1.5 6q.656 0 1.078-.445T6 17.484t-.422-1.055-1.078-.445-1.078.445T3 17.484t.422 1.055 1.078.445zm11.484-18q2.906 0 4.969 2.063t2.063 4.969q0 2.625-1.734 4.57t-4.266 2.32V18q0 1.266-1.031 2.203v1.781q0 .422-.281.727t-.703.305h-.984q-.422 0-.727-.305t-.305-.727V21H5.016v.984q0 .422-.305.727t-.727.305H3q-.422 0-.703-.305t-.281-.727v-1.781Q.985 19.265.985 18V8.016q0-2.391 2.063-3.211t5.953-.82q.188 0 .609.023t.609.023Q12.328.984 15.985.984z"/>'
  }
})
