/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tour': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3.984H6.984V2.015H5.015v19.969h1.969v-7.969H21l-2.016-5.016zM15 9q0 .844-.586 1.43t-1.43.586q-.797 0-1.383-.586T11.015 9t.586-1.43 1.383-.586q.844 0 1.43.586T15 9z"/>'
  }
})
