/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_luggage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 9v.938l1.734 1.734V9h1.5v4.172l3 3V8.016q0-.844-.586-1.43T17.015 6h-2.016V3q0-.422-.305-.703t-.68-.281H9.983q-.375 0-.68.281T8.998 3v3H8.81l3 3h.938zM10.5 3.516h3V6h-3V3.516zm10.688 17.672L2.813 2.813 1.407 4.219l3.609 3.609v11.156q0 .844.586 1.43T6.985 21q0 .422.305.703t.727.281q.375 0 .68-.281T9.002 21h6q0 .422.305.703t.68.281q.422 0 .727-.281t.305-.703q.469 0 .938-.234l1.828 1.828zM8.016 18v-7.172l1.5 1.5V18h-1.5zm4.734 0h-1.5v-3.938l1.5 1.5V18z"/>'
  }
})
