/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical_split': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 5.016H21v13.969h-8.016V5.016zM3 5.016h8.016v1.969H3V5.016zm0 6V9h8.016v2.016H3zm0 7.968v-1.969h8.016v1.969H3zM3 15v-2.016h8.016V15H3z"/>'
  }
})
