/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 12.984q1.313 0 2.859.375t2.859 1.219 1.313 1.922v2.484h-6V16.5q0-2.063-1.969-3.469.328-.047.938-.047zm-7.968 0q1.313 0 2.859.375t2.836 1.219T15 16.5v2.484H.984V16.5q0-1.078 1.313-1.922t2.859-1.219 2.859-.375zm0-1.968q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.086.891.867 2.109-.867 2.109-2.086.891zm7.968 0q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891z"/>'
  }
})
