/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image_aspect_ratio': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18V6H3.985v12h16.031zm0-14.016q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031zm-8.016 6V12H9.984V9.984H12zm-3.984 0V12H6V9.984h2.016zm7.968 4.032v1.969h-1.969v-1.969h1.969zm0-4.032V12h-1.969V9.984h1.969z"/>'
  }
})
