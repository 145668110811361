/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 18V6H6v12h12zm0-14.016q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.406T18 20.014H6q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.406T6 3.984h12z"/>'
  }
})
