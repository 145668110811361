/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'forward_to_inbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3.984H3.985q-.797 0-1.383.586T2.016 6v12q0 .844.586 1.43t1.383.586h9V18h-9V8.016l8.016 4.969 8.016-4.969v4.969h1.969V6.001q0-.844-.586-1.43t-1.383-.586zM12 11.016L3.984 6h16.031zM18.984 15l4.031 3.984-4.031 4.031v-3H15v-2.016h3.984v-3z"/>'
  }
})
