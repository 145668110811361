/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'replay_circle_filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 12q0-2.484-1.758-4.242T12 6V3L8.016 6.984 12 11.015v-3q1.641 0 2.813 1.172T15.985 12t-1.172 2.813T12 15.985t-2.813-1.172T8.015 12H5.999q0 2.484 1.758 4.242T11.999 18t4.242-1.758T17.999 12zm-6-9.984q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
