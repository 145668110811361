/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more_time': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 8.016v6l4.734 2.906.797-1.219-4.031-2.391V8.015h-1.5zM17.906 12q.094.516.094.984 0 1.5-.539 2.766t-1.5 2.227-2.227 1.5-2.719.539q-1.5 0-2.766-.539t-2.227-1.5-1.5-2.227-.539-2.766q0-1.453.539-2.719t1.5-2.227 2.227-1.5 2.766-.539q1.031 0 1.969.281V4.217q-.984-.234-1.969-.234-1.875 0-3.516.703T4.616 6.631 2.694 9.49t-.68 3.492.68 3.516 1.922 2.883 2.883 1.922 3.516.68 3.492-.68 2.859-1.922 1.945-2.883.703-3.516q0-.469-.094-.984h-2.016zm2.11-6.984v-3H18v3h-3v1.969h3v3h2.016v-3h3V5.016h-3z"/>'
  }
})
