/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_camera_back': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 5.016q.797 0 1.383.586t.586 1.383v12q0 .844-.586 1.43t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.43v-12q0-.797.586-1.383t1.383-.586h3.188L9.001 3h6l1.828 2.016h3.188zm0 13.968v-12H3.985v12h16.031zm-6-6.984l-3 3.703L9 12.984l-3 4.031h12z"/>'
  }
})
