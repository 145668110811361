/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'now_wallpaper': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12.984v7.031h7.031v1.969H3.984q-.797 0-1.383-.586t-.586-1.383v-7.031h1.969zm16.032 7.032v-7.031h1.969v7.031q0 .797-.586 1.383t-1.383.586h-7.031v-1.969h7.031zm0-18q.797 0 1.383.586t.586 1.383v7.031h-1.969V3.985h-7.031V2.016h7.031zm-3 6.468q0 .609-.445 1.055t-1.055.445-1.055-.445-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055zm-7.032 4.5l3 3.703L15 14.015l3 3.984H6zm-6-9v7.031H2.015V3.984q0-.797.586-1.383t1.383-.586h7.031v1.969H3.984z"/>'
  }
})
