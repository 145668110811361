/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 6h12v12H6V6z"/>'
  }
})
