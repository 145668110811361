/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'videocam': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 10.5L21 6.516v10.969l-3.984-3.984v3.516q0 .422-.305.703t-.727.281h-12q-.422 0-.703-.281T3 17.017V6.986q0-.422.281-.703t.703-.281h12q.422 0 .727.281t.305.703v3.516z"/>'
  }
})
