/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hdr_enhanced_select': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q1.641 0 3 .797t2.18 2.156.82 3.047q0 1.641-.82 3T15 13.196t-3 .82-3-.82-2.18-2.18-.82-3q0-1.688.82-3.047T9 2.813t3-.797zm0 1.968q-1.125 0-2.016.539t-1.43 1.453-.539 2.039q0 1.078.539 1.992t1.43 1.453 2.016.539 2.016-.539 1.43-1.453.539-1.992q0-1.125-.539-2.039t-1.43-1.453T12 3.984zm.984 7.032h-1.969V9H8.999V6.984h2.016V5.015h1.969v1.969H15V9h-2.016v2.016zm11.016 9h-2.016v1.969h-1.5v-1.969h-1.969v-1.5h1.969V16.5h1.5v2.016H24v1.5zm-6-1.5q0 .422-.258.797t-.633.609L18 21.985h-1.5l-.891-1.969h-1.125v1.969h-1.5v-6H16.5q.609 0 1.055.469T18 17.485v1.031zm-1.5 0v-1.031h-2.016v1.031H16.5zM3.516 18v-2.016h1.5v6h-1.5V19.5H1.5v2.484H0v-6h1.5V18h2.016zm6.468-2.016q.609 0 1.055.469t.445 1.031v3q0 .609-.445 1.055t-1.055.445H6.515v-6h3.469zm0 4.5v-3H8.015v3h1.969z"/>'
  }
})
