/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.719 11.484q5.109 5.063 8.297 8.25L18.75 21l-3.375-3.328q-.75 1.125-1.594 2.203t-1.313 1.594l-.469.516q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445-.75-3.375q0-.516.188-1.547L2.014 4.266 3.28 3l8.344 8.344zM12 6.516q-1.078 0-1.828.844L6.938 4.172q.844-.891 2.344-1.523t2.719-.633q2.906 0 4.945 2.039T18.985 9q0 2.25-1.688 5.484l-3.609-3.656q.797-.703.797-1.828 0-1.031-.727-1.758T12 6.515z"/>'
  }
})
