/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subtitles': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 14.016V12H9.985v2.016h10.031zm0 3.984v-2.016h-4.031V18h4.031zm-6 0v-2.016H3.985V18h10.031zM3.984 12v2.016h4.031V12H3.984zm16.032-8.016q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031z"/>'
  }
})
