/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'radio_button_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm0 4.968q2.063 0 3.539 1.477T17.016 12t-1.477 3.539T12 17.016t-3.539-1.477T6.984 12t1.477-3.539T12 6.984z"/>'
  }
})
