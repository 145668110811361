/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_9_plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9V3H6.984v14.016H21v-6h-2.016v1.969h-1.969v-1.969h-2.016V9h2.016V6.984h1.969V9H21zm0-8.016q.797 0 1.406.609t.609 1.406v14.016q0 .797-.609 1.383T21 18.984H6.984q-.797 0-1.383-.586t-.586-1.383V2.999q0-.797.586-1.406T6.984.984H21zM11.016 9H12v-.984h-.984V9zm3 3q0 .844-.586 1.43t-1.43.586H9V12h3v-.984h-.984q-.844 0-1.43-.586T9 9v-.984q0-.844.586-1.43T11.016 6H12q.844 0 1.43.586t.586 1.43V12zM3 5.016V21h15.984v2.016H3q-.797 0-1.406-.609t-.609-1.406V5.017h2.016z"/>'
  }
})
