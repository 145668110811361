/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outbox': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H4.968q-.797 0-1.383.586t-.586 1.43v13.969q0 .844.586 1.43t1.383.586h14.016q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm0 12H15q0 .844-.398 1.523t-1.078 1.078-1.523.398-1.523-.398T9.4 16.523 9.002 15H4.971V5.016h14.016V15zM8.016 11.016h1.969v3h4.031v-3h1.969l-3.984-4.031z"/>'
  }
})
