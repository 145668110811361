/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pivot_table_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12.984L18 9l3.984 3.984h-3v1.594q0 1.828-1.289 3.117t-3.117 1.289h-1.594v3L9 18l3.984-3.984v3h1.594q.984 0 1.711-.703t.727-1.734v-1.594h-3zm-9 8.016q-.797 0-1.406-.609t-.609-1.406v-9h5.016v11.016h-3zM3 8.016v-3q0-.797.609-1.406t1.406-.609h3v5.016H2.999zm6.984 0V3h9q.797 0 1.406.609t.609 1.406v3H9.983z"/>'
  }
})
