/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image_search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.516 9q1.031 0 1.758-.727t.727-1.758-.727-1.781-1.758-.75-1.781.75-.75 1.781.75 1.758T15.516 9zm3.797-.094L22.407 12l-1.406 1.406-3.141-3.094q-1.172.703-2.391.703-1.875 0-3.164-1.313t-1.289-3.188 1.313-3.188 3.188-1.313 3.188 1.313 1.313 3.188q0 1.219-.703 2.391zM16.5 18H5.484l2.766-3.516 1.969 2.344 2.719-3.516zm1.5-5.016L20.016 15v5.016q0 .797-.609 1.383t-1.406.586H3.985q-.797 0-1.383-.586t-.586-1.383V6q0-.797.586-1.406t1.383-.609h5.531Q9.047 4.969 9 6.001H3.984v14.016H18v-7.031z"/>'
  }
})
