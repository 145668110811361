/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bookmark_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 18V5.016H6.985V18l5.016-2.203zm0-15q.797 0 1.383.609t.586 1.406v15.984l-6.984-3-6.984 3V5.015q0-.797.586-1.406T6.986 3h10.031z"/>'
  }
})
