/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_backpack': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.188 21.188L2.813 2.813 1.407 4.219l2.766 2.766q-.094.234-.141.492t-.047.539v12q0 .797.586 1.383t1.43.586h12q.469 0 .938-.234l.844.844zM6 14.016V12h3.188l1.969 2.016H6.001zM14.813 12L6.985 4.172V2.016h3v1.969h4.031V2.016h3v2.109q1.266.375 2.133 1.43t.867 2.461v9.141L18 15.188V12h-3.188z"/>'
  }
})
