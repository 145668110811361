/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phonelink_erase': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984.984q.797 0 1.406.609t.609 1.406v18q0 .797-.609 1.406t-1.406.609H9q-.797 0-1.406-.609t-.609-1.406v-3h2.016v2.016h9.984V3.984H9.001V6H6.985V3q0-.797.609-1.406T9 .985h9.984zm-6 7.219L9 12.187l3.984 4.031-.984.984-3.984-3.984-4.031 3.984-.984-.984 3.984-4.031-3.984-3.984.984-.984 4.031 3.984L12 7.219z"/>'
  }
})
