/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'games': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 9h5.484v6H16.5l-3-3zM9 16.5l3-3 3 3v5.484H9V16.5zM7.5 9l3 3-3 3H2.016V9H7.5zM15 7.5l-3 3-3-3V2.016h6V7.5z"/>'
  }
})
