/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_end': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9q-2.438 0-4.594.703v3.094q0 .703-.563.938-1.5.703-2.672 1.828-.281.281-.703.281t-.703-.281L.281 13.079Q0 12.798 0 12.376t.281-.703Q5.203 6.985 12 6.985t11.719 4.688q.281.281.281.703t-.281.703l-2.484 2.484q-.281.281-.703.281t-.703-.281q-1.031-.984-2.672-1.828-.563-.234-.563-.891V9.75Q14.25 9 12 9z"/>'
  }
})
