/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pages': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3q.797 0 1.406.609t.609 1.406v6h-5.016l1.031-4.031-4.031 1.031V2.999h6zm-1.968 14.016l-1.031-4.031h5.016v6q0 .797-.609 1.406T18.986 21h-6v-5.016zm-9-4.032l-1.031 4.031 4.031-1.031V21h-6q-.797 0-1.406-.609t-.609-1.406v-6h5.016zM3 5.016q0-.797.609-1.406t1.406-.609h6v5.016L6.984 6.986l1.031 4.031H2.999v-6z"/>'
  }
})
