/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_task': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 5.203L10.593 16.594l-4.266-4.219 1.453-1.406 2.813 2.813 9.984-9.984zM12 20.016q-1.641 0-3.094-.633t-2.555-1.734-1.734-2.555T3.984 12t.633-3.094 1.734-2.555 2.555-1.734T12 3.984q1.172 0 2.25.328t2.016.938l1.453-1.453q-1.219-.844-2.672-1.313T12 2.015q-2.063 0-3.867.773T4.945 4.944 2.789 8.132t-.773 3.867.773 3.867 2.156 3.188 3.188 2.156 3.867.773q1.313 0 2.508-.305t2.273-.914l-1.5-1.5q-.75.375-1.57.563T12 20.015zM18.984 15h-3v2.016h3v3H21v-3h3V15h-3v-3h-2.016v3z"/>'
  }
})
