/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_arrow_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.406 15.422L6 14.016l6-6 6 6-1.406 1.406L12 10.828z"/>'
  }
})
