/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_meals': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 14.016V6q0-.656.398-1.359t1.078-1.313 1.594-.961 1.945-.352v16.172l-2.016-2.016v-2.156h-3zm4.5 9.281L10.031 12.844q-.281.094-.516.117t-.516.023v9H6.983v-9q-1.078 0-1.992-.539t-1.453-1.43-.539-2.016V5.811L.702 3.514l1.406-1.406 19.781 19.781zM6.188 9L5.016 7.828V9h1.172zM9 2.016H6.984v2.156L9 6.188V2.016zM12.984 9V2.016h-1.969v6.141l1.828 1.875q.094-.281.117-.516T12.983 9z"/>'
  }
})
