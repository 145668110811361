/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'receipt_long': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3.516l-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5v13.969H3v3q0 .844.398 1.523t1.078 1.078 1.523.398h12q.844 0 1.523-.398t1.078-1.078.398-1.523V2.016zm-.516 15.468q0 .422-.281.727t-.703.305-.703-.305-.281-.727v-3h-9V5.015h10.969v13.969zM9 6.984h6V9H9V6.984zm6.984 0H18V9h-2.016V6.984zM9 9.984h6V12H9V9.984zm6.984 0H18V12h-2.016V9.984z"/>'
  }
})
