/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'integration_instructions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3h-4.172q-.328-.891-1.078-1.453T12 .984t-1.734.563T9.188 3H5.016q-.234 0-.422.047-.563.094-.984.563-.281.281-.469.609Q3 4.594 3 5.016v13.969q0 .422.141.797.141.328.469.656.422.422.984.516.188.047.422.047h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.985 3zm-7.968 11.156L9.61 15.609 6.001 12 9.61 8.391l1.406 1.453L8.813 12zM12 4.266q-.328 0-.539-.234t-.211-.516q0-.328.211-.539T12 2.766t.539.211.211.539q0 .281-.211.516T12 4.266zm2.391 11.343l-1.406-1.453L15.188 12l-2.203-2.156 1.406-1.453L18 12z"/>'
  }
})
