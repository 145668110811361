/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medical_services': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6h-4.031V3.984q0-.797-.586-1.383t-1.383-.586H9.985q-.797 0-1.383.586t-.586 1.383V6H3.985q-.797 0-1.383.586t-.586 1.43v12q0 .797.586 1.383t1.383.586h16.031q.797 0 1.383-.586t.586-1.383v-12q0-.844-.586-1.43T20.016 6zM9.984 3.984h4.031V6H9.984V3.984zm6 11.016h-3v3h-1.969v-3h-3v-2.016h3v-3h1.969v3h3V15z"/>'
  }
})
