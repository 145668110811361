/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_strikethrough': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 14.016V12h18v2.016H3zM5.016 3.984h13.969v3h-4.969v3H9.985v-3H5.016v-3zm4.968 15v-3h4.031v3H9.984z"/>'
  }
})
