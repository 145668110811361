/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_flat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.125 12.094q-.797.891-2.109.891-1.266 0-2.109-.844-.891-.797-.891-2.156 0-1.219.844-2.063.844-.938 2.156-.938 1.172 0 2.063.891.938.844.938 2.109 0 1.219-.891 2.109zm-5.109 1.922h19.969v1.969h-6v2.016H8.016v-2.016h-6v-1.969zm19.968-3v1.969H9v-6h9q1.641 0 2.813 1.195t1.172 2.836z"/>'
  }
})
