/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_down_off_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15q-.422 0-.727-.281t-.305-.703v-9q0-.422.305-.727t.727-.305h1.969V15h-1.969zM9.984 21.984l-.422-.469q-.563-.563-.891-1.219-.141-.328-.094-.656l.938-4.641H3.984q-.797 0-1.383-.609t-.586-1.406v-1.078q0-.469.141-.797l2.672-6.141q.609-.984 1.734-.984h8.109q.984 0 1.664.68t.68 1.664v7.828q0 .844-.609 1.453zm.891-3.703L15 14.203V6.328Q15 6 14.672 6H6.563l-2.578 5.906v1.078h5.531q.891 0 1.523.727t.398 1.664z"/>'
  }
})
