/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic_external_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.188 21.188L2.813 2.813 1.407 4.219l3.75 3.797H3.985L5.016 18H6q0 1.125.539 2.016t1.453 1.43 1.992.539q1.125 0 2.039-.539t1.453-1.43.539-2.016v-1.172l5.766 5.766 1.406-1.406zM12 18q0 .844-.586 1.43t-1.43.586q-.797 0-1.383-.586T8.015 18h.984l.563-5.625L12 14.813v3.188zm2.016-12v5.156L12 9.187V5.999q0-1.125.539-2.016t1.453-1.43 1.992-.539q1.125 0 2.039.539t1.453 1.43.539 2.016v11.156l-2.016-1.969V5.998q0-.844-.586-1.43t-1.43-.586q-.797 0-1.383.586t-.586 1.43zm-4.032-.984q0 .469-.141.867t-.375.773L5.343 2.531q.75-.516 1.641-.516.844 0 1.523.398t1.078 1.078.398 1.523z"/>'
  }
})
