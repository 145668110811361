/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'texture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.281 21L21 9.281v2.859l-8.859 8.859H9.282zM21 18.984q0 .797-.609 1.406t-1.406.609h-1.969L21 17.015v1.969zM5.016 3h1.969L3.001 6.984V5.015q0-.797.609-1.406T5.016 3zm6.843 0h2.859L2.999 14.719V11.86zm7.641.094q1.172.328 1.453 1.406L4.5 20.906q-1.078-.328-1.406-1.406z"/>'
  }
})
