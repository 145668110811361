/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_quilt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 5.016H21v6H9.984v-6zm6 12.984v-6H21v6h-5.016zm-12 0V5.016H9V18H3.984zm6 0v-6H15v6H9.984z"/>'
  }
})
