/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_queue': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18q1.219 0 2.109-.891T21.984 15t-.891-2.109T18.984 12h-1.5v-.516q0-2.297-1.594-3.891t-3.891-1.594q-1.875 0-3.328 1.125T6.702 9.983h-.703q-1.641 0-2.813 1.195t-1.172 2.836 1.172 2.813 2.813 1.172h12.984zm.375-7.969q1.922.141 3.281 1.57t1.359 3.398q0 2.063-1.477 3.539t-3.539 1.477H5.999q-2.484 0-4.242-1.758t-1.758-4.242q0-2.203 1.57-3.961t3.773-1.992q.984-1.828 2.766-2.953t3.891-1.125q2.531 0 4.711 1.781t2.648 4.266z"/>'
  }
})
