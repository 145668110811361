/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closed_caption_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.844 3.984h12.141q.844 0 1.43.586T21.001 6v12.141l-3.422-3.375q.422-.281.422-.75v-1.031h-1.5v.516h-.188l-1.828-1.828v-1.172h2.016v.516h1.5V9.986q0-.375-.305-.68t-.68-.305h-3q-.422 0-.727.305t-.305.68v.188zm12.937 18.61l-2.625-2.578H5.015q-.844 0-1.43-.586T2.999 18V5.859L1.405 4.218l1.406-1.406 18.375 18.375zm-8.765-8.766l-.844-.844h-.656v.516H7.5v-3h.188L6.422 9.234Q6 9.515 6 9.984v4.031q0 .375.305.68t.68.305h3q.422 0 .727-.305t.305-.68v-.188z"/>'
  }
})
