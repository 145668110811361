/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brightness_auto': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.297 15.984h1.922l-3.234-9h-1.969l-3.234 9h1.922l.703-1.969h3.188zm5.719-7.312L23.297 12l-3.281 3.328v4.688h-4.688L12 23.297l-3.328-3.281H3.984v-4.688L.703 12l3.281-3.328V3.984h4.688L12 .703l3.328 3.281h4.688v4.688zm-9.188 3.984L12 9l1.172 3.656h-2.344z"/>'
  }
})
