/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.953 9.094q.047.047.047.117t-.047.117l-1.172 1.5q-.094.094-.188.094l-.703-.141-.328 1.031q-.094.188-.305.188t-.305-.188l-.656-1.406-1.078-.234-2.203 5.25.797 6.375q0 .188-.188.188h-.938q-.094 0-.188-.141l-.938-3.797-.469-.797-1.172 4.594q0 .141-.188.141h-.984q-.188 0-.188-.188l1.078-6.328H7.309l-1.641 3.094.375 3.188q.094.234-.188.234h-.938q-.141 0-.188-.094L3.416 17.11l-1.594 1.828.281 2.813q.094.234-.188.234H.884q-.188 0-.188-.094l-.609-2.625.984-3.844v-6.75Q-.007 8.25-.007 7.266h12.844q2.25.047 4.453-1.547-.375-1.031.281-1.688 1.313.938 1.688 1.172.328.188.539-.07t.117-.586q-.422-1.313-2.109-1.969-.047 0-.141-.047-.563-.141-.469-.375 0-.141.188-.141 1.359.188 2.32 1.055t1.289 1.664l.188.141q.141.188.305.375t.281.539.07.727q0 .234.094.328z"/>'
  }
})
