/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_camera_front': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 10.5l3.984-3.984v10.969L18 13.501v4.5q0 .844-.586 1.43t-1.43.586h-12q-.797 0-1.383-.586t-.586-1.43v-12q0-.844.586-1.43t1.383-.586h12q.844 0 1.43.586T18 6.001v4.5zm-2.016-.797V6h-12v12h12V9.703zm-6 2.297q-.797 0-1.383-.586t-.586-1.43q0-.797.586-1.383t1.383-.586q.844 0 1.43.586T12 9.984q0 .844-.586 1.43T9.984 12zm0 .984q.516 0 1.195.141t1.313.375 1.078.609.445.891v.984H5.999V15q0-.516.422-.891t1.078-.609 1.336-.375 1.148-.141z"/>'
  }
})
