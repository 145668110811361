/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumb_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 9.984V12q0 .375-.141.75l-3.047 7.031Q19.359 21 18 21H9q-.797 0-1.406-.609t-.609-1.406V9.001q0-.797.609-1.406L14.157.986l1.078 1.078q.422.422.422 1.031v.328l-.984 4.594h6.328q.797 0 1.406.586t.609 1.383zM.984 21V9h4.031v12H.984z"/>'
  }
})
