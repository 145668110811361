/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_police': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .984L3 5.015v6q0 2.063.68 4.008t1.898 3.586 2.859 2.789T12 23.015q1.922-.469 3.563-1.617t2.859-2.789 1.898-3.586.68-4.008v-6zm2.484 11.625l.938 3.844L12 14.437l-3.422 2.016.938-3.844-3-2.578 3.938-.375L12.001 6l1.547 3.656 3.938.328z"/>'
  }
})
