/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compass_calibration': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 10.078q-1.219 0-2.695.609t-2.32 1.453L2.016 7.171Q6.188 2.999 12 2.999q5.766 0 9.984 4.125l-4.969 5.016q-2.063-2.063-5.016-2.063zm-3.984 6.938q0-1.641 1.172-2.836t2.813-1.195 2.813 1.195 1.172 2.836-1.172 2.813-2.813 1.172-2.813-1.172-1.172-2.813z"/>'
  }
})
