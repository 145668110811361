/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'library_music': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 6v14.016H18v1.969H3.984q-.797 0-1.383-.586t-.586-1.383V6h1.969zM18 6.984V5.015h-3.984v5.484q-.656-.516-1.5-.516-1.031 0-1.781.75t-.75 1.781.75 1.758 1.781.727 1.758-.727.727-1.758V6.983h3zm2.016-4.968q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 18h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h12z"/>'
  }
})
