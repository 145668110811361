/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_received': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 5.391L8.391 17.016H15v1.969H5.016V9.001h1.969v6.609L18.61 3.985z"/>'
  }
})
