/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bubble_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.406 8.813q0-2.016 1.383-3.422t3.398-1.406 3.422 1.406 1.406 3.422-1.406 3.398-3.422 1.383-3.398-1.383-1.383-3.398zM12.797 18q0-.844.586-1.43t1.43-.586 1.406.586.563 1.43-.563 1.43-1.406.586-1.43-.586-.586-1.43zm-8.813-3.609q0-1.313.938-2.25t2.297-.938q1.313 0 2.25.914t.938 2.273-.938 2.273-2.25.914q-1.359 0-2.297-.938t-.938-2.25z"/>'
  }
})
