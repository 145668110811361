/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device_hub': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 15.984H21V21h-5.016v-3.047L12 13.734l-3.984 4.219V21H3v-5.016h3.984L11.015 12V8.812q-.891-.328-1.453-1.078T8.999 6q0-1.219.891-2.109T11.999 3t2.109.891T14.999 6q0 .984-.563 1.734t-1.453 1.078V12z"/>'
  }
})
