/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera_roll': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 9V6.984H18V9h2.016zm0 9v-2.016H18V18h2.016zm-4.032-9V6.984h-1.969V9h1.969zm0 9v-2.016h-1.969V18h1.969zM12 9V6.984H9.984V9H12zm0 9v-2.016H9.984V18H12zm2.016-12.984h7.969v15h-7.969q0 .797-.609 1.383t-1.406.586H3.985q-.797 0-1.383-.586t-.586-1.383v-15q0-.797.586-1.406t1.383-.609h1.031v-.984q0-.422.281-.727T6 .985h3.984q.422 0 .727.305t.305.727v.984H12q.797 0 1.406.609t.609 1.406z"/>'
  }
})
