/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invert_colors_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.109l-2.297 2.25-1.406-1.406L12 2.25l5.672 5.672q1.781 1.781 2.203 4.289t-.609 4.711L12 9.703V5.109zm0 14.485v-4.828L7.219 9.985Q6 11.579 6 13.594q0 1.031.516 2.25t1.266 1.969q1.781 1.781 4.219 1.781zm8.672 1.265l.328.375-1.266 1.266-2.719-2.719q-2.203 1.781-5.016 1.781-1.359 0-3.023-.68t-2.648-1.664-1.664-2.625-.68-3q0-1.172.516-2.672T5.766 8.53L3 5.764l1.266-1.266q14.016 14.016 16.406 16.359z"/>'
  }
})
