/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_walk': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.797 8.906L6.984 23.015h2.109l1.828-8.016 2.063 2.016v6H15v-7.5l-2.109-2.016.609-3q2.156 2.484 5.484 2.484v-1.969q-2.906 0-4.266-2.438l-1.031-1.594q-.703-.984-1.688-.984-.141 0-.398.047t-.398.047L6 8.295v4.688h2.016V9.608l1.781-.703zM13.5 5.484q-.797 0-1.406-.586t-.609-1.383.609-1.406T13.5 1.5t1.406.609.609 1.406-.609 1.383-1.406.586z"/>'
  }
})
