/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bakery_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.781 15h-3.563l-.75-6.891q-.047-.469.234-.797t.656-.328h3.281q.375 0 .656.328t.234.797zM8.625 9.375L9.281 15H7.5l-2.109-4.078q-.469-.891.375-1.406l1.594-.891q.375-.234.797 0t.469.75zm6.75 0q.094-.516.469-.75t.797 0l1.594.891q.844.516.328 1.406L16.5 15h-1.781zM4.734 16.359q-.234.141-.563.141-.563 0-.844-.563l-.234-.469q-.234-.563.047-.984l.844-1.266q.234-.422.797-.422t.797.422l.984 1.781zm14.532 0L17.438 15q.328-.609.984-1.781.234-.422.797-.422t.797.422l.797 1.266q.328.469.094.984l-.234.469q-.234.563-.844.563-.328 0-.563-.141z"/>'
  }
})
