/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_baseball': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12q0 2.156 1.406 4.289t3.375 3.023Q15.891 21.984 12 21.984t-6.797-2.672q2.156-.938 3.469-2.906T9.985 12 8.672 7.594 5.203 4.688Q8.109 2.016 12 2.016t6.797 2.672q-2.156.938-3.469 2.906T14.015 12zm6.187-5.719Q21.984 8.859 21.984 12t-1.781 5.719q-1.828-.563-3.023-2.156T15.985 12t1.195-3.563 3.023-2.156zm-16.406 0q1.828.563 3.023 2.156T8.015 12 6.82 15.563t-3.023 2.156Q2.016 15.141 2.016 12t1.781-5.719z"/>'
  }
})
