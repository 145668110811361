/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'segment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 12.984v-1.969h12v1.969H9zM3 6h18v2.016H3V6zm6 12v-2.016h12V18H9z"/>'
  }
})
