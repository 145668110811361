/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tablet_mac': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V3h-15v15.984h15zm-7.5 4.032q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zM18.516 0q1.031 0 1.758.727t.727 1.758v19.031q0 1.031-.727 1.758t-1.758.727H4.5q-1.031 0-1.758-.727t-.727-1.758V2.485q0-1.031.727-1.758T4.5 0h14.016z"/>'
  }
})
