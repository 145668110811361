/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'untitled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6.984q4.125 0 7.055 1.477T21.985 12q0 1.547-1.664 2.789t-4.336 1.805v-2.063q1.828-.469 2.93-1.195T20.017 12q0-.563-.938-1.242T16.22 9.539 12.001 9t-4.219.539-2.859 1.219T3.985 12q0 .703 1.406 1.523T9 14.718v-2.719l3.984 3.984L9 20.014V16.78q-3.094-.516-5.039-1.805t-1.945-2.977q0-2.063 2.93-3.539t7.055-1.477z"/>'
  }
})
