/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 12.984v-1.969h12v1.969H3zM3 6h18v2.016H3V6zm0 12v-2.016h6V18H3z"/>'
  }
})
