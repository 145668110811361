/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'skip_previous': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.516 12L18 6v12zM6 6h2.016v12H6V6z"/>'
  }
})
