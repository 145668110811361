/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'maps_ugc': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12q0 1.172.258 2.25t.68 2.063L.985 23.016l6.703-1.969q.984.422 2.063.68t2.25.258q2.063 0 3.867-.773t3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156-3.867-.773zm3.984 10.968h-3v3h-1.969v-3h-3v-1.969h3v-3h1.969v3h3v1.969z"/>'
  }
})
