/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pool': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 5.484q0-1.031.727-1.758t1.758-.727 1.758.727.727 1.758-.727 1.781-1.758.75-1.758-.75-.727-1.781zM8.672 12q-.563 0-1.172-.375-.188-.141-.75-.375l3.234-3.234L9 6.985q-1.5-1.5-3.984-1.5V3.001q1.922 0 3.141.445t2.344 1.57l6.422 6.375q-.281.188-.422.234-.609.375-1.172.375-.516 0-1.125-.375-1.031-.609-2.203-.609t-2.203.609Q9.189 12 8.673 12zm13.312 4.5q-1.078 0-2.156-.656-.516-.328-1.172-.328-.609 0-1.125.328-1.078.656-2.203.656-1.078 0-2.156-.656-.516-.328-1.172-.328-.609 0-1.125.328-1.078.656-2.203.656-1.078 0-2.156-.656-.516-.328-1.172-.328-.609 0-1.125.328-1.078.656-2.203.656v-2.016q.609 0 1.125-.328 1.078-.656 2.203-.656 1.078 0 2.156.656.516.328 1.172.328.609 0 1.125-.328Q10.875 13.5 12 13.5q1.078 0 2.156.656.516.328 1.172.328.609 0 1.125-.328 1.078-.656 2.203-.656 1.078 0 2.156.656.516.328 1.172.328V16.5zm0 4.5q-1.078 0-2.156-.656-.516-.328-1.172-.328-.609 0-1.125.328-1.078.656-2.203.656-1.078 0-2.156-.656-.516-.328-1.172-.328-.609 0-1.125.328Q9.797 21 8.672 21t-2.203-.656q-.516-.328-1.125-.328-.656 0-1.172.328Q3.094 21 2.016 21v-2.016q.609 0 1.125-.328Q4.219 18 5.344 18q1.078 0 2.156.656.516.328 1.172.328.609 0 1.125-.328Q10.875 18 12 18t2.203.656q.516.328 1.125.328.656 0 1.172-.328Q17.578 18 18.656 18q1.125 0 2.203.656.516.328 1.125.328V21z"/>'
  }
})
