/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_rugby': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.219 16.219q3.047-3.047 3.234-8.016-1.641 3.609-4.641 6.609t-6.609 4.641q4.922-.141 8.016-3.234zM7.781 7.781q-3.047 3.047-3.234 8.016.703-1.5 2.063-3.445t2.578-3.164q3-3 6.609-4.641-4.922.141-8.016 3.234zm12.703-4.265q.797.797.984 3.188t-.727 5.602-3.07 5.367q-1.969 1.969-4.664 2.883t-5.32.914q-3.188 0-4.172-.984-.797-.797-.984-3.188t.727-5.602 3.07-5.367q1.969-1.969 4.664-2.883t5.32-.914q3.188 0 4.172.984z"/>'
  }
})
