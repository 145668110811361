/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'north_west': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 15h1.969V8.391L18.61 20.016l1.406-1.406L8.391 6.985H15V5.016H5.016V15z"/>'
  }
})
