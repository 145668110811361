/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audiotrack': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3h6.984v3H15v11.016h-.047q-.188 1.688-1.453 2.836T10.5 21q-1.875 0-3.188-1.313t-1.313-3.188 1.313-3.188 3.188-1.313q.656 0 1.5.281V2.998z"/>'
  }
})
