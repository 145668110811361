/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lightbulb_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.859 13.078q2.156-1.5 2.156-4.078 0-2.063-1.477-3.539t-3.539-1.477T8.46 5.461 6.983 9q0 1.078.633 2.25t1.523 1.828l.844.609v2.297h4.031v-2.297zM12 2.016q2.906 0 4.945 2.039T18.984 9q0 3.656-3 5.719v2.297q0 .422-.281.703T15 18H9q-.422 0-.703-.281t-.281-.703v-2.297q-3-2.063-3-5.719 0-2.906 2.039-4.945T12 2.016zM9 21v-.984h6V21q0 .422-.281.703t-.703.281H9.985q-.422 0-.703-.281T9.001 21z"/>'
  }
})
