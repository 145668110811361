/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_align_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3h18v2.016H3V3zm6 6V6.984h12V9H9zm-6 3.984v-1.969h18v1.969H3zm6 4.032V15h12v2.016H9zM3 21v-2.016h18V21H3z"/>'
  }
})
