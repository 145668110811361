/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sim_card_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 12.984V8.015h-1.969v4.969h1.969zm0 4.032V15h-1.969v2.016h1.969zm5.016-15q.797 0 1.406.586t.609 1.383v16.031q0 .797-.609 1.383T18 21.985H6q-.797 0-1.406-.586t-.609-1.383l.047-12 5.953-6h8.016z"/>'
  }
})
