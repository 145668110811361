/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lunch_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 15.984h19.969V18q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609T2.016 18v-2.016zM5.344 13.5q-.516 0-1.125.375-1.031.609-2.203.609v-1.969q.516 0 1.125-.375 1.078-.656 2.203-.656 1.078 0 2.156.656.609.375 1.172.375.516 0 1.125-.375 1.078-.656 2.203-.656 1.078 0 2.156.656.609.375 1.172.375.516 0 1.125-.375 1.078-.656 2.203-.656 1.078 0 2.156.656l1.172.375v1.969q-1.125 0-2.156-.609-.609-.375-1.172-.375-.516 0-1.125.375-1.031.609-2.203.609-1.125 0-2.156-.609Q12.563 13.5 12 13.5q-.516 0-1.125.375-1.031.609-2.203.609-1.125 0-2.156-.609-.609-.375-1.172-.375zm16.64-3.516H2.015q-.234-2.438 2.719-4.219T12 3.984t7.266 1.781 2.719 4.219z"/>'
  }
})
