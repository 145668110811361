/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.016V3h2.016v2.016H15zm0 7.968v-1.969h2.016v1.969H15zM18.984 21v-2.016H21V21h-2.016zm0-8.016v-1.969H21v1.969h-2.016zm0-9.984H21v2.016h-2.016V3zm0 14.016V15H21v2.016h-2.016zM15 21v-2.016h2.016V21H15zm3.984-12V6.984H21V9h-2.016zM3 21V3h2.016v18H3zm3.984-8.016v-1.969H9v1.969H6.984zm0-7.968V3H9v2.016H6.984zm0 15.984v-2.016H9V21H6.984zm4.032-8.016v-1.969h1.969v1.969h-1.969zm0-3.984V6.984h1.969V9h-1.969zm0-3.984V3h1.969v2.016h-1.969zm0 12V15h1.969v2.016h-1.969zm0 3.984v-2.016h1.969V21h-1.969z"/>'
  }
})
