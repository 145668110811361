/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_movies': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9V6.984h-2.016V9H18zm0 3.984v-1.969h-2.016v1.969H18zm0 4.032V15h-2.016v2.016H18zM8.016 9V6.984H6V9h2.016zm0 3.984v-1.969H6v1.969h2.016zm0 4.032V15H6v2.016h2.016zM18 3h2.016v18H18v-2.016h-2.016V21H8.015v-2.016H5.999V21H3.983V3h2.016v2.016h2.016V3h7.969v2.016H18V3z"/>'
  }
})
