/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 15.984h3v6h-3v-6zm15-13.593q-.094 2.203-1.125 3.82T15 8.391v13.594h-2.016v-6h-1.969v6H8.999V10.079q-.422.141-.609.328-1.406 1.125-1.406 3.094v.516H5.015v-.516q0-2.953 2.109-4.781 2.109-1.734 4.875-1.734 2.109 0 3.469-1.031 1.547-1.266 1.547-3.469v-.469h1.969v.375zM12 6q-.797 0-1.406-.609t-.609-1.406.609-1.383T12 2.016t1.406.586.609 1.383-.609 1.406T12 6z"/>'
  }
})
