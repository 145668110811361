/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'find_replace': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.641 15.141l4.828 4.875-1.453 1.453-4.875-4.828Q13.266 18 11.016 18h-.047q-1.172 0-2.625-.609t-2.297-1.453l-2.063 2.063v-6h6l-2.531 2.531q1.453 1.453 3.563 1.453 1.688 0 3.094-1.172T15.891 12h2.016q-.234 1.734-1.266 3.141zM11.016 6Q9.328 6 7.875 7.172T6.094 9.985H4.078q.375-2.531 2.344-4.266t4.594-1.734q1.172 0 2.625.609t2.297 1.453l2.063-2.063v6h-6l2.531-2.531Q13.079 6 11.016 6z"/>'
  }
})
