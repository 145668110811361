/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 2.016q-2.016 0-3.68 1.031T8.765 5.766q-1.969 1.078-3 3-1.688.891-2.719 2.555t-1.031 3.68q0 1.453.539 2.719t1.5 2.227 2.227 1.5T9 21.986q2.016 0 3.68-1.031t2.555-2.719q1.969-1.078 3-3 1.688-.891 2.719-2.555t1.031-3.68q0-1.453-.539-2.719t-1.5-2.227-2.227-1.5T15 2.016zm-6 18q-1.359 0-2.508-.68t-1.828-1.828T3.984 15q0-.844.281-1.617T5.015 12q0 1.453.539 2.719t1.5 2.227 2.227 1.5 2.719.539q-.609.469-1.383.75T9 20.016zm3-3q-1.359 0-2.508-.68t-1.828-1.828T6.984 12q0-.844.281-1.617T8.015 9q0 1.453.539 2.719t1.5 2.227 2.227 1.5 2.719.539q-.609.469-1.383.75T12 17.016zm4.688-3.328q-.844.328-1.688.328-1.359 0-2.508-.68t-1.828-1.828T9.984 9q0-.844.328-1.688.844-.328 1.688-.328 1.359 0 2.508.68t1.828 1.828.68 2.508q0 .844-.328 1.688zM18.984 12q0-1.453-.539-2.719t-1.5-2.227-2.227-1.5-2.719-.539q.609-.469 1.383-.75t1.617-.281q1.359 0 2.508.68t1.828 1.828.68 2.508q0 .844-.281 1.617T18.984 12z"/>'
  }
})
