/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_transportation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18.984h1.969V21H5.016v-2.016zm0-3.984h1.969v2.016H5.016V15zm4.968-9.984H12v1.969H9.984V5.016zm-4.968 6h1.969v1.969H5.016v-1.969zm9-2.016V3.984h-6V9H3v12h-.984V8.016h4.969V3h8.016v6h-.984zm6 8.016q.422 0 .703-.305t.281-.727-.281-.703-.703-.281-.727.281-.305.703.305.727.727.305zm-8.016 0q.422 0 .703-.305t.281-.727-.281-.703T12 15t-.703.281-.281.703.281.727.703.305zm.422-6l-1.031 3h9.234l-1.031-3h-7.172zm8.156-.375l1.406 4.125v5.531q0 .281-.211.492t-.492.211h-.609q-.281 0-.469-.234t-.188-.516v-1.266h-8.016v1.266q0 .281-.211.516t-.492.234h-.609q-.656 0-.656-.703l-.047-5.531 1.453-4.125q.234-.656.984-.656h7.172q.75 0 .984.656z"/>'
  }
})
