/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_wifi_4_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 21.469L.375 6.985Q6.047 3.001 12 3.001t11.625 3.984z"/>'
  }
})
