/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'show_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.516 18.469l-1.5-1.5 7.5-7.5L13.5 13.5l7.078-7.969 1.406 1.406L13.5 16.5l-3.984-4.031z"/>'
  }
})
