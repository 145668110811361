/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'movie_filter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.922 11.953l2.063-.938-2.063-.938-.938-2.063-.938 2.063-2.063.938 2.063.938.938 2.063zm-5.672 3.281l2.766-1.219-2.766-1.266-1.266-2.766-1.219 2.766-2.766 1.266 2.766 1.219L9.984 18zM18 3.984h3.984V18q0 .797-.586 1.406t-1.383.609H3.984q-.797 0-1.383-.609T2.015 18V6q0-.797.586-1.406t1.383-.609h1.031l1.969 3h3l-1.969-3h1.969l2.016 3h3l-2.016-3H15l2.016 3h3z"/>'
  }
})
