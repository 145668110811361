/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fastfood': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.031 17.016h15v1.969h-15v-1.969zm15-2.016H.984q0-2.297 1.547-3.844t3.75-1.922 4.43 0 3.773 1.922T16.031 15zM.984 21.984V21h15.047v.984q0 .422-.305.703t-.727.281H2.015q-.422 0-.727-.281t-.305-.703zm17.063.985v-8.016q0-2.953-2.438-5.297-1.453-1.453-4.266-2.25l-.281-2.344h4.969V.984H18v4.078h5.016l-1.688 16.453q-.094.609-.539 1.031t-1.055.422h-1.688z"/>'
  }
})
