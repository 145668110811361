/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_list_bulleted': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 5.016H21v1.969H6.984V5.016zm0 7.968v-1.969H21v1.969H6.984zm0 6v-1.969H21v1.969H6.984zm-3-2.484q.609 0 1.055.445T5.484 18t-.445 1.055-1.055.445-1.055-.445T2.484 18t.445-1.055 1.055-.445zm0-12q.609 0 1.055.422T5.484 6t-.445 1.078-1.055.422-1.055-.422T2.484 6t.445-1.078T3.984 4.5zm0 6q.609 0 1.055.422T5.484 12t-.445 1.078-1.055.422-1.055-.422T2.484 12t.445-1.078 1.055-.422z"/>'
  }
})
