/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electric_scooter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.828 15.984H15V15q0-1.125.539-2.016t1.453-1.43 1.992-.539h.75l-1.875-8.438q-.188-.703-.727-1.148T15.89.984h-3.891V3h3.891l1.406 6.234q-1.641.516-2.789 1.781t-1.43 3h-5.25q-.375-1.031-1.313-1.617t-2.109-.352q-.891.188-1.547.844t-.797 1.547q-.188.938.188 1.758t1.102 1.313T5.015 18t1.711-.563 1.102-1.453zm-2.812 0q-.422 0-.727-.281T3.984 15t.305-.703.727-.281q.375 0 .68.281t.305.703-.305.703-.68.281zM18.984 12q-.797 0-1.477.398t-1.102 1.078-.422 1.523.422 1.523 1.102 1.078 1.477.398q.844 0 1.523-.398t1.078-1.078.398-1.523-.398-1.523-1.078-1.078T18.984 12zm0 3.984q-.375 0-.68-.281T17.999 15t.305-.703.68-.281q.422 0 .727.281t.305.703-.305.703-.727.281zm-7.968 4.032H6.985l6 3V21h4.031l-6-3v2.016z"/>'
  }
})
