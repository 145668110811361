/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_rotation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 21.469l1.359-1.313 3.797 3.797L12 24q-4.688 0-8.133-3.188t-3.82-7.828h1.5q.234 2.484 1.969 4.969T7.5 21.469zm7.313-.281l6.375-6.375-12-12-6.375 6.375zM10.219 1.734l12.047 12.047q.469.422.469 1.031t-.469 1.078l-6.375 6.375q-.422.469-1.031.469t-1.078-.469L1.735 10.218q-.422-.422-.422-1.031 0-.656.422-1.078L8.11 1.734q.422-.422 1.078-.422.609 0 1.031.422zm6.281.797l-1.359 1.313L11.344.047 12 0q4.688 0 8.133 3.188t3.82 7.828h-1.5q-.281-2.813-1.875-5.063T16.5 2.531z"/>'
  }
})
