/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'point_of_sale': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 2.016H6.985q-.797 0-1.383.586t-.586 1.383v2.016q0 .844.586 1.43t1.383.586h10.031q.797 0 1.383-.586t.586-1.43V3.985q0-.797-.586-1.383t-1.383-.586zm0 3.984H6.985V3.984h10.031V6zm3 15.984H3.985q-.797 0-1.383-.586t-.586-1.383v-1.031h19.969v1.031q0 .797-.586 1.383t-1.383.586zm-1.5-11.812q-.234-.516-.727-.844T16.687 9H7.312q-.609 0-1.102.328t-.727.844L2.014 18h19.969zm-9 5.812H8.485q-.188 0-.328-.141t-.141-.328q0-.234.141-.375t.328-.141h1.031q.188 0 .328.141t.141.375q0 .188-.141.328t-.328.141zm0-1.968H8.485q-.188 0-.328-.164t-.141-.352.141-.352.328-.164h1.031q.188 0 .328.164t.141.352-.141.352-.328.164zm0-2.016H8.485q-.188 0-.328-.141t-.141-.375q0-.188.141-.328t.328-.141h1.031q.188 0 .328.141t.141.328q0 .234-.141.375T9.516 12zm3 3.984h-1.031q-.188 0-.328-.141t-.141-.328q0-.234.141-.375t.328-.141h1.031q.188 0 .328.141t.141.375q0 .188-.141.328t-.328.141zm0-1.968h-1.031q-.188 0-.328-.164t-.141-.352.141-.352.328-.164h1.031q.188 0 .328.164t.141.352-.141.352-.328.164zm0-2.016h-1.031q-.188 0-.328-.141t-.141-.375q0-.188.141-.328t.328-.141h1.031q.188 0 .328.141t.141.328q0 .234-.141.375t-.328.141zm3 3.984h-1.031q-.188 0-.328-.141t-.141-.328q0-.234.141-.375t.328-.141h1.031q.188 0 .328.141t.141.375q0 .188-.141.328t-.328.141zm0-1.968h-1.031q-.188 0-.328-.164t-.141-.352.141-.352.328-.164h1.031q.188 0 .328.164t.141.352-.141.352-.328.164zm0-2.016h-1.031q-.188 0-.328-.141t-.141-.375q0-.188.141-.328t.328-.141h1.031q.188 0 .328.141t.141.328q0 .234-.141.375t-.328.141z"/>'
  }
})
