/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'request_quote': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 2.016H6q-.844 0-1.406.586t-.563 1.383l-.047 16.031q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383v-12zM15 12h-3.984v.984h3q.375 0 .68.305t.305.727v3q0 .375-.305.68t-.68.305h-1.031v.984h-1.969v-.984H9v-2.016h3.984v-.984h-3q-.375 0-.68-.305t-.305-.68v-3q0-.422.305-.727t.68-.305h1.031V9h1.969v.984H15V12zm-2.016-3.984v-4.5l4.5 4.5h-4.5z"/>'
  }
})
