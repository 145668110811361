/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 5.016L3.985 3l16.031 15.984L18 21l-5.625-5.625L6.75 21H3v-3.75l5.625-5.625zm18.703.609q.281.281.281.703 0 .375-.281.656l-1.828 1.875-3.75-3.75 1.828-1.828Q17.25 3 17.672 3t.703.281zm-8.578 2.484l1.922-1.922 3.75 3.75-1.922 1.922z"/>'
  }
})
