/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check_circle_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm4.594 5.578L18 9l-8.016 8.016L5.015 12l1.406-1.406 3.563 3.563z"/>'
  }
})
