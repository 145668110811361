/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nightlife': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 5.016h4.969v3h-3v9q0 1.219-.891 2.109t-2.109.891-2.109-.891-.891-2.109.891-2.109 2.109-.891q.609 0 1.031.141V5.016zM10.078 9l1.406-2.016H4.5L5.906 9h4.172zM.984 5.016H15l-6 9V18h2.016v2.016h-6V18h1.969v-3.984z"/>'
  }
})
