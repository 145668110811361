/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 9V6.984h-1.969V9h1.969zm0 8.016v-6h-1.969v6h1.969zm-.984-15q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
