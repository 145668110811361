/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monochrome_photos': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18.984v-12H12v1.031q2.109 0 3.563 1.43t1.453 3.539-1.453 3.563T12 18v-1.781q-1.313 0-2.25-.938t-.938-2.297.938-2.273T12 9.797v6.422q1.313 0 2.25-.938t.938-2.297-.938-2.273T12 9.797V8.016q-2.109 0-3.563 1.43t-1.453 3.539 1.453 3.563T12 18.001v.984h8.016zm0-13.968q.797 0 1.383.586t.586 1.383v12q0 .797-.586 1.406T20.016 21H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h3.234L9 3h6l1.781 2.016h3.234z"/>'
  }
})
