/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_fire_department': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.719 17.625q-.234.234-.586.422t-.633.281q-.844.281-1.664.023t-1.383-.727q-.094-.094-.07-.188t.117-.141q.891-.281 1.406-.891t.703-1.313q.141-.656-.047-1.266t-.281-1.266q-.094-.516-.07-1.008t.211-.961q0-.094.094-.094t.141.047q.281.609.727 1.078t.938.891.891.914.492 1.148q.047.281.047.516.047.703-.234 1.406t-.797 1.125zm2.812-8.016q-.563-.516-1.172-.938t-1.172-.984q-1.125-1.125-1.477-2.625t.117-3.047q.094-.234-.07-.398t-.398-.07q-.563.234-1.055.563t-.961.656Q9.89 3.985 9.046 5.649t-.961 3.563.586 3.727q0 .094.023.188t.023.188q0 .422-.375.609t-.75-.141q-.094-.141-.141-.234-.75-.938-1.008-2.133t-.07-2.367q.047-.281-.211-.422t-.445.094q-.938 1.219-1.359 2.695t-.328 2.977q0 .422.07.867t.211.867q.328 1.125.891 2.063.891 1.5 2.391 2.508t3.234 1.242q1.828.234 3.68-.188t3.258-1.688q1.031-.984 1.617-2.273t.633-2.695-.516-2.719q-.047-.094-.07-.164t-.07-.164q-.422-.797-.938-1.453-.188-.281-.398-.516t-.492-.469z"/>'
  }
})
