/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.984q1.5 0 3.281.422t3.258 1.406 1.477 2.203v3H3.985v-3q0-1.219 1.477-2.203t3.258-1.406 3.281-.422zm0-9q1.641 0 2.813 1.195t1.172 2.836-1.172 2.813T12 12t-2.813-1.172-1.172-2.813 1.172-2.836T12 3.984zm0 10.922q-2.063 0-4.078.773t-2.016 1.336v1.078h12.188v-1.078q0-.563-2.016-1.336T12 14.906zm0-9q-.891 0-1.5.609t-.609 1.5.609 1.477 1.5.586 1.5-.586.609-1.477-.609-1.5-1.5-.609z"/>'
  }
})
