/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12.984H5.015v-1.969h13.969v1.969z"/>'
  }
})
