/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 15v-3.984H9.985V15h4.031zM18 3q1.641 0 2.813 1.172t1.172 2.813q0 2.297-1.969 3.469v8.531q0 .797-.609 1.406T18.001 21h-12q-.797 0-1.406-.609t-.609-1.406v-8.531Q2.017 9.282 2.017 6.985q0-1.641 1.172-2.813T6.002 3h12z"/>'
  }
})
