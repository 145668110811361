/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_rotate_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 4.266l3 3h-2.016v12.469h-1.969V7.266h-2.016zM9.984 14.625v-3.75L4.968 12.75zM3 12l11.016-4.734v2.063l-2.203.938v4.969l2.203.938v2.063L3 13.503v-1.5z"/>'
  }
})
