/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'traffic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9q.844 0 1.43-.586t.586-1.43-.586-1.406T12 5.015t-1.43.563-.586 1.406.586 1.43T12 9zm0 5.016q.797-.047 1.383-.633T13.969 12t-.586-1.383T12 9.984h-.047q-.844 0-1.43.586T9.937 12t.586 1.43 1.43.586H12zm0 4.968q.797-.047 1.383-.609t.586-1.359-.586-1.383T12 15h-.047q-.844 0-1.43.586t-.586 1.43.586 1.406 1.43.563H12zm8.016-9q0 1.406-.844 2.461t-2.156 1.43V15h3q0 1.406-.844 2.438t-2.156 1.406v1.172q0 .422-.305.703t-.727.281H8.015q-.422 0-.727-.281t-.305-.703v-1.172q-1.313-.375-2.156-1.406T3.983 15h3v-1.125q-1.313-.375-2.156-1.43t-.844-2.461h3V8.859Q5.67 8.484 4.827 7.453t-.844-2.438h3V3.984q0-.422.305-.703T8.015 3h7.969q.422 0 .727.281t.305.703v1.031h3q0 1.406-.844 2.438t-2.156 1.406v1.125h3z"/>'
  }
})
