/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pets': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.344 14.859l.797.797.398.422.375.445.352.445.305.469.234.516.141.539.047.563-.023.586q-.516 1.969-2.344 2.344-.328.047-2.273-.188t-3.258-.234h-.188q-1.313 0-3.258.234t-2.273.188q-1.828-.375-2.344-2.344-.141-.938.328-1.922t.867-1.406 1.43-1.453q.469-.516 1.242-1.453t1.242-1.453q.844-1.031 1.734-1.313.188-.094.328-.094.281-.047.797-.047.563 0 .797.047.141 0 .328.094.891.281 1.734 1.313.422.516 1.219 1.453t1.266 1.453zm-.328-5.343q0-1.031.727-1.781t1.758-.75 1.758.75.727 1.781-.727 1.758-1.758.727-1.758-.727-.727-1.758zm-4.5-4.032q0-1.031.727-1.758t1.758-.727 1.758.727.727 1.758-.727 1.781-1.758.75-1.758-.75-.727-1.781zm-6 0q0-1.031.727-1.758t1.758-.727 1.758.727.727 1.758-.727 1.781-1.758.75-1.758-.75-.727-1.781zm-4.5 4.032q0-1.031.727-1.781t1.758-.75 1.758.75.727 1.781-.727 1.758-1.758.727-1.758-.727-.727-1.758z"/>'
  }
})
