/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'replay_10': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.219 15.188q0 .328.469.328.234 0 .328-.094l.188-.234q.094-.188.094-.281v-2.016q-.094-.188-.094-.281t-.211-.211-.305-.117q-.141 0-.281.141l-.188.188q-.141.188-.141.281v2.016q.141.188.141.281zm1.969-.985q0 .609-.094.797l-.281.609q-.328.281-.516.281-.094 0-.305.047t-.305.047q-.422 0-.609-.094-.094-.047-.234-.141t-.234-.141q-.422-.234-.422-1.406v-.703q0-.609.094-.797l.328-.609q.281-.281.469-.281.094 0 .305-.047t.305-.047q.422 0 .609.094.094.047.258.141t.258.141.281.609q.094.281.094.797v.703zm-4.266 1.781h-.938v-3.281L9 12.984v-.703l1.781-.563h.141v4.266zM12 5.016q3.328 0 5.672 2.344t2.344 5.625q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672h2.016q0 2.484 1.781 4.242t4.219 1.758 4.219-1.758 1.781-4.242-1.781-4.242-4.219-1.758v4.031L6.986 6 12.002.984v4.031z"/>'
  }
})
