/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send_and_archive': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 20.016l-3.516-4.031H15v-3h3v3h2.016zM21 21v-9h-9v9h9zm0-11.016q.797 0 1.406.609t.609 1.406v9q0 .797-.609 1.406T21 23.014h-9q-.797 0-1.406-.609t-.609-1.406v-3.516l-7.969 3.516v-6.984l9-2.016-9-2.016V2.999L18 9.983h3z"/>'
  }
})
