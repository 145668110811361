/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 4.266L3.282 3l16.734 16.734L18.75 21l-4.031-3.984h-1.734v-1.734l-2.25-2.297H8.016v-1.969h.703L6.656 8.953q-1.172.141-1.969 1.008T3.89 12q0 1.266.914 2.18t2.18.914h4.031v1.922H6.984q-2.063 0-3.516-1.477T2.015 12q0-1.406.891-2.766t2.203-1.875zm13.968 6.75v1.969h-.188l-1.969-1.969h2.156zm1.032-4.032q2.063 0 3.516 1.477T21.985 12q0 3.047-2.719 4.453L17.813 15q.984-.234 1.641-1.078T20.11 12q0-1.266-.914-2.18t-2.18-.914h-4.031V6.984h4.031z"/>'
  }
})
