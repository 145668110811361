/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account_balance': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.484.984L21 6v2.016H2.016V6zm4.5 9h3v7.031h-3V9.984zm-13.968 12v-3H21v3H2.016zm7.968-12h3v7.031h-3V9.984zm-6 0h3v7.031h-3V9.984z"/>'
  }
})
