/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'devices': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 17.016V9.985H18v7.031h3.984zm1.032-9q.422 0 .703.281T24 9v9.984q0 .422-.281.727t-.703.305h-6q-.422 0-.727-.305t-.305-.727V9q0-.422.305-.703t.727-.281h6zM3.984 6v11.016h10.031v3H-.001v-3h2.016V6q0-.797.586-1.406t1.383-.609h18v2.016h-18z"/>'
  }
})
