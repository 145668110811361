/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drive_eta': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 9.984h13.969l-1.5-4.5H6.516zM17.484 15q.609 0 1.055-.422t.445-1.078-.445-1.078T17.484 12t-1.055.422-.445 1.078.445 1.078 1.055.422zM6.516 15q.609 0 1.055-.422t.445-1.078-.445-1.078T6.516 12t-1.055.422-.445 1.078.445 1.078T6.516 15zm12.422-9.984l2.063 6v7.969q0 .422-.281.727t-.703.305h-1.031q-.422 0-.703-.305t-.281-.727v-.984h-12v.984q0 .422-.281.727t-.703.305H3.987q-.422 0-.703-.305t-.281-.727v-7.969l2.063-6q.328-1.031 1.453-1.031h10.969q1.125 0 1.453 1.031z"/>'
  }
})
