/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 14.672L9.047 5.25q.141-.047.375-.164l.328-.164h.047l.281-.141q.047 0 .188-.047t.234-.047v-.703q0-.609.422-1.055T12 2.484t1.078.445.422 1.055v.703q2.109.516 3.305 2.25T18 11.015v3.656zm-6 7.312q-.844 0-1.43-.563t-.586-1.406h4.031q0 .844-.586 1.406t-1.43.563zM7.828 6.141q1.125 1.172 5.883 6.047T21 19.735l-1.266 1.266-2.016-2.016H3.984v-.984L6 15.985v-5.016q0-1.922.797-3.422L3.984 4.781 5.25 3.468z"/>'
  }
})
