/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blur_linear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 17.016q-.422 0-.703-.305T12 15.984t.281-.703.703-.281.727.281.305.703-.305.727-.727.305zm0-4.032q-.422 0-.703-.281T12 12t.281-.703.703-.281.727.281.305.703-.305.703-.727.281zm0-3.984q-.422 0-.703-.281T12 8.016t.281-.727.703-.305.727.305.305.727-.305.703-.727.281zm4.032 3.516q-.516 0-.516-.516t.516-.516q.469 0 .469.516t-.469.516zm0-4.032q-.516 0-.516-.469 0-.516.516-.516.469 0 .469.516 0 .469-.469.469zM3 3h18v2.016H3V3zm14.016 13.5q-.516 0-.516-.516 0-.469.516-.469.469 0 .469.469 0 .516-.469.516zM9 17.016q-.422 0-.703-.305t-.281-.727.281-.703T9 15t.703.281.281.703-.281.727-.703.305zM5.016 13.5q-.609 0-1.055-.422T3.516 12t.445-1.078 1.055-.422 1.055.422T6.516 12t-.445 1.078-1.055.422zm0-3.984q-.609 0-1.055-.445t-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445zM3 21v-2.016h18V21H3zM9 9q-.422 0-.703-.281t-.281-.703.281-.727T9 6.984t.703.305.281.727-.281.703T9 9zm0 3.984q-.422 0-.703-.281T8.016 12t.281-.703.703-.281.703.281.281.703-.281.703-.703.281zm-3.984 4.5q-.609 0-1.055-.445t-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445z"/>'
  }
})
