/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9.984V8.015h-1.969v1.969h1.969zm0 3v-1.969h-1.969v1.969h1.969zm-3-3V8.015h-1.969v1.969h1.969zm0 3v-1.969h-1.969v1.969h1.969zm0 4.032V15H8.015v2.016h7.969zm-9-7.032V8.015H5.015v1.969h1.969zm0 3v-1.969H5.015v1.969h1.969zm1.032-1.968v1.969h1.969v-1.969H8.016zm0-3v1.969h1.969V8.016H8.016zm3 3v1.969h1.969v-1.969h-1.969zm0-3v1.969h1.969V8.016h-1.969zm9-3q.797 0 1.383.586t.586 1.383v10.031q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V6.985q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
