/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_hockey': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.594 12.844L15 15.985h3.984v4.031l-4.688-.047q-.609 0-.891-.516l-1.406-3.094q-.328-.656-.891-1.922t-.703-1.594L6.327 3.984h3.375l2.297 5.203q.094-.234.305-.68t.258-.539l1.734-3.984h3.375zM21 15.984q.422 0 .703.305t.281.727v3h-1.969v-4.031h.984zm-12 0l.844-1.922 1.594 3.469-.844 1.922q-.281.516-.891.516l-4.688.047v-4.031h3.984zm-6.984 1.032q0-.422.281-.727T3 15.984h.984v4.031H2.015v-3z"/>'
  }
})
