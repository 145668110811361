/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clean_hands': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.969 5.016l.656 1.359 1.359.609-1.359.656-.656 1.359-.609-1.359-1.359-.656 1.359-.609zm-5.953 1.125V3.985h1.969q.844 0 1.547.469l1.453-1.453q-.609-.469-1.383-.727t-1.617-.258H7.501v1.969h1.5v2.156q-1.313.281-2.32 1.172t-1.43 2.203h3.984l5.766 2.156v-.656q0-1.219-.516-2.227t-1.43-1.711-2.039-.938zM.984 21.984h4.031V11.015H.984v10.969zm19.032-4.968h-7.031l-2.063-.75.328-.938 1.734.656h2.813q.516 0 .867-.328t.352-.844q0-.797-.797-1.125l-7.266-2.672H6.984v9l7.031 1.969 7.969-3q0-.797-.586-1.383t-1.383-.586zm0-3q.797 0 1.383-.586t.586-1.43q0-.422-.305-1.078T21 9.586t-.68-1.125l-.305-.445-.305.445-.703 1.125-.703 1.336L17.999 12q0 .844.586 1.43t1.43.586z"/>'
  }
})
