/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sync_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 15.984L6 12v3h15v2.016H6v3zm19.968-7.968L18 12V9H3V6.984h15v-3z"/>'
  }
})
