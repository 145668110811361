/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_merge': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 8.016l4.5-4.5 4.5 4.5h-3.516v6.375l-6 6-1.406-1.406 5.438-5.391V8.016H7.5zm9.516 12.375l-3.422-3.375L15 15.61l3.422 3.375z"/>'
  }
})
