/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'adb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9q.422 0 .703-.281t.281-.703-.281-.727T15 6.984t-.703.305-.281.727.281.703T15 9zM9 9q.422 0 .703-.281t.281-.703-.281-.727T9 6.984t-.703.305-.281.727.281.703T9 9zm7.125-4.641q2.859 2.109 2.859 5.625v1.031H5.015V9.984q0-3.516 2.859-5.625L5.765 2.25l.844-.797L8.906 3.75Q10.406 3 12 3t3.094.75l2.297-2.297.844.797zM5.016 15.984V12h13.969v3.984q0 2.906-2.039 4.969t-4.945 2.063-4.945-2.063-2.039-4.969z"/>'
  }
})
