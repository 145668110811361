/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_nature': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.188 14.578q.516-.609.469-1.336t-.609-1.289q-.609-.609-1.359-.609-.703 0-1.266.469l1.594.609q.047 0 .141.047l.375.375.047.094v.047zm-1.219 5.344q.938-.328.609-1.266l-1.172-3.141q-1.219 2.344-.703 3.797.375.984 1.266.609zm-6.281-5.625q1.453.516 3.797-.703l-3.188-1.172q-.891-.328-1.219.609-.375.891.609 1.266zM12 9.563q1.781.609 2.391 2.438h1.594v1.031h-1.359q.141 1.875-1.125 3.141l-.563.422.563 1.406q.141.422.141.984 0 .891-.563 1.734t-1.406 1.125q-.516.141-1.031.141-1.594 0-2.438-1.266-.422.141-1.125.141-1.594 0-2.766-1.172-1.641-1.641-1.031-3.891-.703-.469-1.102-1.477t-.023-1.992q.281-.797 1.125-1.383t1.734-.586q.563 0 .984.141l1.406.563.422-.563Q9 9.328 10.641 9.328q.047 0 .188.023t.188.023V8.015h.984v1.547zm6-2.579q.422 0 .703-.281T18.984 6t-.281-.703T18 5.016t-.703.281-.281.703.281.703.703.281zm3.938-2.109q.328.938-.563 1.5l-.844.516.375 1.219q.281.609-.117 1.242t-1.102.633q-.563 0-.891-.281l-.797-.703-.797.703q-.328.281-.891.281-.703 0-1.102-.633t-.117-1.242l.422-1.219-.891-.516q-.891-.563-.563-1.5.328-.891 1.266-.891h1.078l.281-.938q.234-1.031 1.313-1.031t1.313 1.031l.281.938h1.125q.844 0 1.219.891z"/>'
  }
})
