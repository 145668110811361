/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_stroller': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 18q.844 0 1.43.586t.586 1.43q0 .797-.586 1.383T6 21.985t-1.43-.586-.586-1.383q0-.844.586-1.43T6 18zM18.656 3q-.844 0-1.43.352t-1.008.82-.75.844l-3.516 4.125 5.063 5.016V6.282q.422-.516.773-.891t.867-.375q.563 0 .961.422t.398 1.031v.516h1.969v-.516q0-.938-.445-1.734t-1.195-1.266T18.655 3zm-7.968 7.688L2.813 2.813 1.407 4.219l7.969 7.969-2.672 3.141q-.281.328-.234.703t.305.656.68.281h6.656l1.172 1.172q-.563.188-.914.703t-.352 1.172q0 .797.586 1.383t1.383.586q.656 0 1.172-.352t.703-.914l1.922 1.875 1.406-1.406-4.781-4.781zm2.765-5.672q.234-.234.469-.516t.516-.563q-1.828-.891-3.891-.914t-3.891.773l4.219 4.266z"/>'
  }
})
