/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'do_not_disturb_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648q0-2.672-1.734-4.922L7.078 18.282q2.25 1.734 4.922 1.734zM3.984 12q0 2.672 1.734 4.922L16.921 5.719q-2.25-1.734-4.922-1.734-3.281 0-5.648 2.367T3.984 12zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
