/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'font_download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.938 18.516h2.109L12.938 5.485h-1.875L5.954 18.516h2.109l1.125-3h5.625zm4.078-16.5q.797 0 1.383.586t.586 1.383v16.031q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V3.985q0-.797.586-1.383t1.383-.586h16.031zM9.938 13.5l2.063-5.531 2.063 5.531H9.939z"/>'
  }
})
