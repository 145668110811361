/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_shapes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.688 12.75h2.625L12 8.906zm3.046 1.266h-3.516l-.703 1.969H7.874l3.422-9h1.406l3.375 9h-1.594zm5.25-9H21V3h-2.016v2.016zM21 21v-2.016h-2.016V21H21zm-3.984-2.016v-1.969h1.969V6.984h-1.969V5.015H6.985v1.969H5.016v10.031h1.969v1.969h10.031zM5.016 21v-2.016H3V21h2.016zM3 3v2.016h2.016V3H3zm20.016 3.984H21v10.031h2.016v6h-6v-2.016H6.985v2.016h-6v-6h2.016V6.984H.985v-6h6V3h10.031V.984h6v6z"/>'
  }
})
