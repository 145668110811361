/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 6.516q-.797 0-1.406.586t-.609 1.383.609 1.406 1.406.609 1.406-.609.609-1.406-.609-1.383-1.406-.586zm0 5.484q-1.453 0-2.484-1.031t-1.031-2.484 1.031-2.461T16.5 5.016t2.484 1.008 1.031 2.461-1.031 2.484T16.5 12zm-9-5.484q-.797 0-1.406.586t-.609 1.383.609 1.406T7.5 10.5t1.406-.609.609-1.406-.609-1.383T7.5 6.516zM7.5 12q-1.453 0-2.484-1.031T3.985 8.485t1.031-2.461T7.5 5.016t2.484 1.008 1.031 2.461-1.031 2.484T7.5 12zm14.016 5.484v-1.219q0-.469-1.664-1.125t-3.352-.656q-1.219 0-3 .563.516.609.516 1.219v1.219h7.5zm-9 0v-1.219q0-.469-1.664-1.125T7.5 14.484t-3.352.656-1.664 1.125v1.219h10.031zm3.984-4.5q2.016 0 4.266.914t2.25 2.367v2.719H.985v-2.719q0-1.453 2.25-2.367t4.266-.914q2.203 0 4.5 1.031 2.297-1.031 4.5-1.031z"/>'
  }
})
