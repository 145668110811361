/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr_code_scanner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.516 6.516v3h-3v-3h3zm1.5-1.5h-6v6h6v-6zm-1.5 9.468v3h-3v-3h3zm1.5-1.5h-6v6h6v-6zm6.468-6.468v3h-3v-3h3zm1.5-1.5h-6v6h6v-6zm-6 7.968h1.5v1.5h-1.5v-1.5zm1.5 1.5h1.5v1.5h-1.5v-1.5zm1.5-1.5h1.5v1.5h-1.5v-1.5zm-3 3h1.5v1.5h-1.5v-1.5zm1.5 1.5h1.5v1.5h-1.5v-1.5zm1.5-1.5h1.5v1.5h-1.5v-1.5zm1.5-1.5h1.5v1.5h-1.5v-1.5zm0 3h1.5v1.5h-1.5v-1.5zm4.5-10.5h-1.969v-3h-3V2.015h4.969v4.969zm0 15v-4.969h-1.969v3h-3v1.969h4.969zm-19.968 0h4.969v-1.969h-3v-3H2.016v4.969zm0-19.968v4.969h1.969v-3h3V2.016H2.016z"/>'
  }
})
