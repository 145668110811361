/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cake': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9q1.219 0 2.109.891T21 12v1.547q0 .797-.586 1.383t-1.383.586-1.359-.563l-2.156-2.156-2.156 2.156q-.563.563-1.383.563t-1.383-.563l-2.109-2.156-2.156 2.156q-.563.563-1.359.563t-1.383-.586-.586-1.383V12q0-1.219.891-2.109T6.001 9h5.016V6.984h1.969V9h5.016zm-1.406 6.984q1.031 1.031 2.438 1.031 1.031 0 1.969-.609V21q0 .422-.281.703t-.703.281H3.986q-.422 0-.703-.281T3.002 21v-4.594q.891.609 1.969.609 1.406 0 2.438-1.031l1.078-1.078 1.078 1.078q.984.984 2.438.984t2.438-.984l1.078-1.078zM12 6q-.797 0-1.406-.609t-.609-1.406q0-.516.328-1.031L12.001.001l1.688 2.953q.328.516.328 1.031 0 .844-.586 1.43t-1.43.586z"/>'
  }
})
