/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.531 17.344q-1.875 1.5-4.969 2.578T4.031 21h-.047q-.422 0-.703-.281T3 20.016V16.5q0-.422.281-.703t.703-.281q1.875 0 3.563-.563.563-.234 1.031.234l2.203 2.203q1.406-.75 2.297-1.453L1.406 4.218l1.406-1.406 18.375 18.375-1.406 1.406zm2.813-2.813l-1.453-1.406q.891-1.172 1.5-2.344l-2.203-2.203q-.422-.422-.234-1.031.563-1.688.563-3.563 0-.422.281-.703T16.501 3h3.516q.422 0 .703.281t.281.703q0 5.906-3.656 10.547z"/>'
  }
})
