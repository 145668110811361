/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 3.984h7.969V7.5l-2.813 2.859 1.219 1.219L18 8.015v-6H6v1.172l2.016 1.969V3.984zM2.109 2.109L.703 3.515l8.906 8.906L6 15.984v6h12v-1.172l2.484 2.484 1.406-1.406zm13.875 17.907H8.015V16.5l2.813-2.859 5.156 5.203v1.172z"/>'
  }
})
