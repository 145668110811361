/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_search_desktop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.609 16.781h4.781v1.828H9.609v-1.828zM20.016 18q.797 0 1.383-.586t.586-1.43V6q0-.844-.586-1.43t-1.383-.586H3.985q-.797 0-1.383.586T2.016 6v9.984q0 .844.586 1.43T3.985 18H.001v2.016h24V18h-3.984zM3.984 15.984V6h16.031v10.031zm5.11-6.046q0-.75.539-1.289t1.336-.539q.75 0 1.313.539t.563 1.289q0 .797-.563 1.336t-1.313.539q-.797 0-1.336-.539t-.539-1.336zm7.031 4.265l-2.484-2.484q.516-.797.516-1.781 0-.891-.422-1.617t-1.148-1.148-1.617-.422-1.617.422-1.172 1.148-.445 1.617.445 1.617 1.172 1.172 1.617.445q.938 0 1.734-.516l2.484 2.484zM0 0h24v24H0V0z"/>'
  }
})
