/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 18.984V7.125L9 5.016v11.859zM20.484 3Q21 3 21 3.516V18.61q0 .375-.375.469L15 21.001l-6-2.109-5.344 2.063-.141.047q-.516 0-.516-.516V5.392q0-.375.375-.469l5.625-1.922 6 2.109 5.344-2.063z"/>'
  }
})
