/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vertical_distribute': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 2.016v1.969H2.015V2.016h19.969zm-15 8.484v3h10.031v-3H6.984zm-4.968 9.516v1.969h19.969v-1.969H2.016z"/>'
  }
})
