/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elderly': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 5.484q.844 0 1.43-.586t.586-1.383q0-.844-.586-1.43t-1.43-.586-1.43.586-.586 1.43q0 .797.586 1.383t1.43.586zm6.516 7.032v10.5h-1.031v-10.5q0-.234-.141-.375T18.516 12q-.234 0-.375.141t-.141.375v.984h-.984v-.703q-1.125-.281-2.016-.938t-1.5-1.547q-.234.609-.375 1.289t-.141 1.383q0 .188.023.352t.023.352l1.969 2.813v6.516h-2.016v-5.016l-1.781-2.531-.188 3.516-3 4.031-1.594-1.219 2.578-3.469v-5.344q0-.844.141-1.688t.375-1.688l-1.5.844v3.563H5.998V9.282l5.391-3.047q.469-.234.984-.234t.984.281q.516.281.797.844l.797 1.688q.469.938 1.43 1.57t2.133.633q.609 0 1.055.422t.445 1.078z"/>'
  }
})
