/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'north_east': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 5.016v1.969h6.609L3.984 18.61l1.406 1.406L17.015 8.391V15h1.969V5.016H9z"/>'
  }
})
