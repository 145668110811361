/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_tilt_shift': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.672 19.734l1.406-1.406q1.688 1.313 3.938 1.594v2.016q-1.266-.141-2.836-.797t-2.508-1.406zm7.312.188q2.203-.281 3.891-1.594l1.453 1.406q-2.391 1.922-5.344 2.203v-2.016zm5.344-3q1.359-1.688 1.594-3.891h2.016q-.141 1.219-.797 2.789t-1.406 2.508zM15 12q0 1.219-.891 2.109T12 15t-2.109-.891T9 12t.891-2.109T12 9t2.109.891T15 12zm-10.922.984q.234 2.203 1.594 3.891l-1.406 1.453q-1.922-2.391-2.203-5.344h2.016zm1.594-5.906q-1.313 1.734-1.594 3.938H2.062q.141-1.266.797-2.836t1.406-2.508zm14.25 3.938q-.281-2.25-1.594-3.938l1.406-1.406q1.922 2.391 2.203 5.344h-2.016zm-1.594-6.75l-1.406 1.406q-1.734-1.313-3.938-1.594V2.062q1.266.141 2.836.797t2.508 1.406zm-7.312-.188q-2.25.281-3.938 1.594L5.672 4.266q2.391-1.922 5.344-2.203v2.016z"/>'
  }
})
