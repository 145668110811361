/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dehaze': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 5.484h19.969V7.5H2.016V5.484zm0 5.016h19.969v2.016H2.016V10.5zm0 5.016h19.969v1.969H2.016v-1.969z"/>'
  }
})
