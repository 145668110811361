/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upgrade': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18v2.016H8.015V18h7.969zM11.016 7.969v8.016h1.969V7.969h3l-3.984-3.984-3.984 3.984h3z"/>'
  }
})
