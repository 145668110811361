/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_indent_increase': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 12.984v-1.969H21v1.969h-9.984zm0-3.984V6.984H21V9h-9.984zM3 3h18v2.016H3V3zm8.016 14.016V15H21v2.016h-9.984zM3 8.016L6.984 12 3 15.984V8.015zM3 21v-2.016h18V21H3z"/>'
  }
})
