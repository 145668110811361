/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'laptop_mac': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.984q.422 0 .703-.281t.281-.703-.281-.703-.703-.281-.703.281-.281.703.281.703.703.281zM3.984 5.016v10.969h16.031V5.016H3.984zM20.016 18H24q0 .797-.609 1.406t-1.406.609H2.016q-.797 0-1.406-.609T.001 18h3.984q-.797 0-1.383-.609t-.586-1.406V5.016q0-.797.586-1.406t1.383-.609h16.031q.797 0 1.383.609t.586 1.406v10.969q0 .797-.586 1.406T20.016 18z"/>'
  }
})
