/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_hide': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 23.016l-3.984-4.031h7.969zm6.984-15V6h-1.969v2.016h1.969zm0 3V9h-1.969v2.016h1.969zm-3-3V6h-1.969v2.016h1.969zm0 3V9h-1.969v2.016h1.969zm0 3.984v-2.016H8.015V15h7.969zm-9-6.984V6H5.015v2.016h1.969zm0 3V9H5.015v2.016h1.969zM8.016 9v2.016h1.969V9H8.016zm0-3v2.016h1.969V6H8.016zm3 3v2.016h1.969V9h-1.969zm0-3v2.016h1.969V6h-1.969zm9-3q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406V5.015q0-.797.586-1.406T3.985 3h16.031z"/>'
  }
})
