/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'looks_two': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 11.016V9q0-.844-.586-1.43t-1.43-.586H9V9h3.984v2.016h-1.969q-.844 0-1.43.563t-.586 1.406v4.031h6V15h-3.984v-2.016h1.969q.844 0 1.43-.563T15 11.015zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
