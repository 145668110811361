/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not_interested': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.328 16.922q1.688-2.109 1.688-4.922 0-3.281-2.367-5.648t-5.648-2.367q-1.125 0-2.578.492T7.079 5.672zM12 20.016q1.125 0 2.578-.492t2.344-1.195L5.672 7.079q-1.688 2.109-1.688 4.922 0 3.281 2.367 5.648t5.648 2.367zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
