/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'input': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 15.984v-3H.985v-1.969h10.031v-3L15 11.999zM21 3q.844 0 1.43.586t.586 1.43v14.016q0 .797-.609 1.383t-1.406.586h-18q-.797 0-1.406-.586t-.609-1.383v-4.031h2.016v4.031h18V4.969h-18V9H.986V5.016q0-.797.609-1.406t1.406-.609h18z"/>'
  }
})
