/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no_drinks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.813 3h15.188v2.016l-6.188 6.938-4.969-4.969h6.703l1.781-1.969h-10.5zm13.968 19.594L18 20.813v.188H6v-2.016h5.016v-4.969L9.61 12.469l-8.203-8.25 1.406-1.406 18.375 18.375zm-3.609-3.61l-3.188-3.141v3.141h3.188z"/>'
  }
})
