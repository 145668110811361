/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airline_seat_flat_angled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.313 10.219Q6.844 10.5 6 10.5q-.797 0-1.594-.492T3.281 8.813Q3 8.344 3 7.5q0-.797.492-1.594t1.195-1.125Q5.156 4.5 6 4.5q.797 0 1.594.492t1.125 1.195Q9 6.843 9 7.5q0 .797-.492 1.594t-1.195 1.125zM1.5 12.141l.703-1.875 18.984 6.844-.656 1.875-4.547-1.594v1.594H8.015v-4.5zm20.766 2.156l-.703 1.875-12.375-4.453 2.109-5.672 8.531 3.094q1.078.375 1.875 1.477t.797 2.273q0 .797-.234 1.406z"/>'
  }
})
