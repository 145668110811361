/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 12l-3 3.984L8.015 5.015h7.969q1.031 0 1.641.844zM3.234 2.766L20.25 19.735l-1.266 1.266-1.969-2.016h-12q-.797 0-1.406-.586T3 17.016V6.985q0-.797.609-1.406L2.015 3.985z"/>'
  }
})
