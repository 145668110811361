/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 14.484l4.031-3.75-4.031-3.75V9.14q-4.922.703-6 5.859 2.016-2.719 6-2.719v2.203zM20.016 18H24v2.016H0V18h3.984q-.844 0-1.406-.586t-.563-1.43V6q0-.844.563-1.43t1.406-.586h16.031q.844 0 1.406.586T21.984 6v9.984q0 .797-.586 1.406t-1.383.609z"/>'
  }
})
