/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_awesome': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9l1.266-2.766 2.766-1.219-2.766-1.266L18.984.983l-1.219 2.766-2.766 1.266 2.766 1.219zm-7.5.516L9 3.985 6.516 9.516.985 12l5.531 2.484L9 20.015l2.484-5.531L17.015 12zm7.5 5.484l-1.219 2.766-2.766 1.219 2.766 1.266 1.219 2.766 1.266-2.766 2.766-1.266-2.766-1.219z"/>'
  }
})
