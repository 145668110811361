/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'highlight_remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016zm2.578 6l1.406 1.406L13.406 12l2.578 2.578-1.406 1.406L12 13.406l-2.578 2.578-1.406-1.406L10.594 12 8.016 9.422l1.406-1.406L12 10.594z"/>'
  }
})
