/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'laptop_chromebook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15V5.016H3.985V15h16.031zm-6 3v-.984H9.985V18h4.031zm7.968 0H24v2.016H0V18h2.016V3h19.969v15z"/>'
  }
})
