/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_delete': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 2.016h-3.516L10.5.985H5.484L4.5 2.016H.984v1.969H15V2.016zM15.984 9q-1.031 0-1.969.281V5.015h-12v12q0 .797.586 1.383t1.383.586h5.719q.797 1.781 2.508 2.906t3.773 1.125q1.453 0 2.719-.563t2.227-1.523 1.523-2.227.563-2.719-.563-2.719-1.523-2.227-2.227-1.5-2.719-.539zm0 12q-1.359 0-2.508-.68t-1.805-1.805-.656-2.531q0-1.359.656-2.508t1.805-1.805 2.508-.656q1.406 0 2.531.656t1.805 1.805.68 2.508q0 1.406-.68 2.531t-1.805 1.805-2.531.68zm.516-9H15v5.016l3.609 2.063.797-1.172-2.906-1.688V12z"/>'
  }
})
