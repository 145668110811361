/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'headset_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 1.734L22.266 21.75 21 23.016l-2.156-2.156q-.422.141-.844.141h-3v-3.984L5.812 7.783q-.797 1.453-.797 3.234v1.969h3.984v8.016h-3q-1.219 0-2.109-.891t-.891-2.109v-6.984q0-2.625 1.313-4.688L.984 3.002zM12 3.984q-2.063 0-3.797 1.125L6.75 3.703Q9.047 2.015 12 2.015q3.75 0 6.375 2.648T21 11.015v6.891l-4.922-4.922h2.906v-1.969q0-2.906-2.039-4.969T12 3.983z"/>'
  }
})
