/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cell_wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.516 8.484q.75-.75 2.086-1.289t2.414-.539 2.414.539 2.086 1.289l-1.313 1.313q-1.359-1.359-3.234-1.359T7.781 9.797zm2.531 2.579q.797-.797 1.969-.797 1.125 0 1.922.797l-1.922 1.922zm-5.109-5.11q2.906-2.906 7.078-2.906t7.078 2.906l-1.313 1.266q-2.391-2.391-5.789-2.391T5.203 7.219zm18.046 16.031H6L21.984 6v15.984z"/>'
  }
})
