/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compress': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12h16.031v2.016H3.984V12zm0-3h16.031v2.016H3.984V9zm12-5.016L12 8.015 8.016 3.984h3v-3h1.969v3h3zm-7.968 15L12 15l3.984 3.984h-3v3h-1.969v-3h-3z"/>'
  }
})
