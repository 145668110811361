/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_laundry_service': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 20.016q2.484 0 4.242-1.758T18 14.016t-1.758-4.242T12 8.016 7.758 9.774 6 14.016t1.758 4.242T12 20.016zM6.984 3.984q-.422 0-.703.305T6 5.016t.281.703.703.281.727-.281.305-.703-.305-.727-.727-.305zm3 0q-.422 0-.703.305T9 5.016t.281.703.703.281.727-.281.305-.703-.305-.727-.727-.305zM18 2.016q.844 0 1.43.563t.586 1.406v16.031q0 .844-.586 1.406t-1.43.563H6q-.844 0-1.43-.563t-.586-1.406V3.985q0-.844.586-1.406T6 2.016h12zM9.188 16.828l5.625-5.672q1.172 1.172 1.172 2.859 0 1.641-1.172 2.813T12 18t-2.813-1.172z"/>'
  }
})
