/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'do_not_disturb_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 12.984h3.469l-2.016-1.969H6.984v1.969zM2.25 2.25l19.5 19.5-1.313 1.266-2.766-2.813q-2.531 1.781-5.672 1.781-4.125 0-7.055-2.93t-2.93-7.055q0-1.313.516-2.953t1.266-2.719L.983 3.561zm14.766 8.766h-3.469L6.328 3.797Q8.859 2.016 12 2.016q4.125 0 7.055 2.93t2.93 7.055q0 1.313-.516 2.953t-1.266 2.719l-4.641-4.688h1.453v-1.969z"/>'
  }
})
