/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass_top': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 2.016v6L9.984 12 6 16.031v5.953h12v-6L14.016 12 18 8.016v-6H6zM15.984 16.5v3.516H8.015V16.5l3.984-3.984z"/>'
  }
})
