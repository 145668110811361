/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'satellite': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 18h13.969l-4.5-6-3.469 4.5-2.531-3zm0-6q2.906 0 4.945-2.063T12 4.968H9.984q0 2.063-1.453 3.539T5.015 9.984V12zm0-7.031v3.047q1.219 0 2.109-.914t.891-2.133h-3zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
