/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exposure_zero': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 10.125q0-1.875-.516-2.672-.188-.281-.656-.563-.328-.188-.844-.188t-.844.188q-.469.281-.656.563-.516.797-.516 2.672v2.672q0 2.625 1.172 3.328.328.188.844.188.984 0 1.5-.797.563-.844.563-2.719v-2.672h-.047zm-6.141.328q0-5.438 4.125-5.438 3.047 0 3.844 2.906.328 1.219.328 2.531v2.063h-.047q0 2.766-1.125 4.219-.656.75-1.313.984-.75.281-1.688.281t-1.688-.281q-.656-.234-1.313-.984-1.125-1.266-1.125-4.219v-2.063z"/>'
  }
})
