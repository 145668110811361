/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'masks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 6q-.984 0-1.688.656t-.797 1.641q-.938-.234-1.664-.68t-1.5-.773-1.852-.328-1.852.328-1.5.773-1.664.68q-.094-.938-.797-1.617T4.498 6q-1.031 0-1.758.727t-.727 1.758v.516q0 2.25.586 3.75t1.57 2.414 2.109 1.336 2.25.469q.75.469 1.641.75t1.828.281 1.828-.281 1.641-.75q1.125-.047 2.25-.469t2.109-1.336 1.57-2.414.586-3.75v-.516q0-1.031-.727-1.758T19.496 6zM3.516 9v-.516q0-.375.281-.68t.703-.305.703.305.281.68v3q0 .984.281 1.852T6.515 15q-.75-.375-1.453-1.078t-1.125-1.898-.422-3.023zm16.968 0q0 1.828-.422 3.023t-1.125 1.898-1.453 1.078q.469-.797.75-1.664t.281-1.852v-3q0-.375.281-.68t.703-.305.703.305.281.68v.516zm-9.796 1.5q-.328.188-.727.398T9 11.25v-1.031q.375-.141.656-.305t.516-.305q.422-.234.844-.422T12 8.999t.984.188.797.422q.281.141.563.305t.656.305v1.031q-.563-.141-.961-.352t-.727-.445l-.656-.328L12 9.984l-.656.141-.656.375z"/>'
  }
})
