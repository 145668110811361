/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 3.234q3.047.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484V3.232zM16.5 12q0 2.813-2.484 4.031V7.968q1.031.516 1.758 1.688T16.501 12zM3 9h3.984L12 3.984v16.031l-5.016-5.016H3v-6z"/>'
  }
})
