/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'style': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.859 19.734v-6.328l3.469 8.344H7.875q-.797 0-1.406-.609t-.609-1.406zM7.875 8.766q.422 0 .703-.305t.281-.727-.281-.703-.703-.281-.703.281-.281.703.281.727.703.305zm14.156 7.172q.141.281.141.75 0 .563-.352 1.125t-.867.75l-7.359 3.047q-.281.141-.75.141-.563 0-1.102-.352t-.773-.867L6 8.532q-.141-.281-.141-.75 0-.563.352-1.078t.867-.75l7.406-3.047q.422-.141.797-.141.516 0 1.031.352t.75.867zm-19.5 3.703q-.75-.328-1.078-1.078t0-1.5l2.438-5.859v9z"/>'
  }
})
