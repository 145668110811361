/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_to_home_screen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 15v-3.609l-5.578 5.625L3 15.61l5.578-5.625H5.015V8.016h6.984V15H9.983zM18 1.031q.797 0 1.406.586T20.015 3v18q0 .797-.609 1.406T18 23.015H8.016q-.797 0-1.406-.609T6.001 21v-3h2.016v.984h9.984V5.015H8.017v.984H6.001v-3q0-.797.609-1.406T8.016.984z"/>'
  }
})
