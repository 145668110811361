/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insert_emoticon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.484q-1.734 0-3.117-.961t-1.992-2.508H17.11q-.609 1.547-1.992 2.508t-3.117.961zm-3.516-6.468q-.609 0-1.055-.445t-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445zm7.032 0q-.609 0-1.055-.445t-.445-1.055.445-1.055 1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445zm-3.516 9q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648T12 3.986 6.352 6.353t-2.367 5.648 2.367 5.648T12 20.016zm0-18q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
