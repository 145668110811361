/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_history': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18.281q1.641 0 3.375-.938T18 15.093q-.047-1.313-2.109-2.203T12 11.999t-3.891.867T6 15.093q.891 1.313 2.625 2.25t3.375.938zm0-14.297q-1.219 0-2.109.891T9 6.984t.891 2.109T12 9.984t2.109-.891T15 6.984t-.891-2.109T12 3.984zm0-1.968q3.75 0 6.375 2.648T21 11.016q0 3.094-1.898 5.484t-4.805 3.188L12 21.985l-2.297-2.297q-2.906-.797-4.805-3.188T3 11.016q0-3.703 2.625-6.352T12 2.016z"/>'
  }
})
