/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'web': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18V9h-4.031v9h4.031zM15 12.984V9H3.984v3.984H15zM15 18v-3.984H3.984V18H15zm5.016-14.016q.797 0 1.383.609t.586 1.406v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h16.031z"/>'
  }
})
