/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'takeout_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 3.984h5.953l4.031 3.375 1.594-1.547 1.406 1.406-2.766 2.766H4.78L2.014 7.218 3.42 5.812l1.594 1.547zm-3.75 7.032h13.5l-.703 9H5.953z"/>'
  }
})
