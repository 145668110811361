/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speaker_notes_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 2.016q.797 0 1.383.586t.586 1.383v12q0 .797-.563 1.383t-1.359.633l-6.984-6.984h4.922V9.001h-6.938l-.984-.984h7.922V6.001H9.985v1.922L4.079 2.017h15.938zM6 11.016h2.016L6 9v2.016zm2.016 3V12H6v2.016h2.016zM1.266 1.734l20.719 20.719-1.266 1.266L15 18H6l-3.984 3.984V5.015L0 2.999z"/>'
  }
})
