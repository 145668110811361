/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_italic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984H18v3h-2.813L11.812 15h2.203v3H5.999v-3h2.813l3.375-8.016H9.984v-3z"/>'
  }
})
