/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account_box': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 17.016V18h12v-.984q0-1.359-2.063-2.227t-3.938-.867-3.938.867-2.063 2.227zM15 9q0-1.219-.891-2.109T12 6t-2.109.891T9 9t.891 2.109T12 12t2.109-.891T15 9zM3 5.016q0-.844.586-1.43T5.016 3h13.969q.797 0 1.406.609T21 5.015v13.969q0 .797-.609 1.406t-1.406.609H5.016q-.844 0-1.43-.586T3 18.983V5.014z"/>'
  }
})
