/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dirty_lens': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.938 18.984h7.078v-12H3.985v12h7.266l.188-1.406-.375-1.031q-.234-.141-.469 0t-.445.445-.469.586-.609.422-.773-.094q-.422-.328-.445-.563t.164-.469.422-.539.375-.703-.047-.961q-.047-.328-.422-.352t-.797 0-.773-.141-.398-.773q-.047-.375.188-.563t.539-.281.539-.234.234-.422q.047-.281-.094-.492l-.281-.422-.141-.445.281-.516q.188-.188.469-.234t.422 0q.328.141.609.422t.586.539.633.375.703-.117q.328-.188.234-.492t-.328-.656-.422-.68-.117-.609.633-.422q.609-.188.914.117t.516.797.469.938.727.586q.516.141.914-.141t.727-.727.656-.938.656-.797.68-.305.82.563q.422.609.375 1.008t-.422.703-.914.539-1.031.469-.75.539-.117.773q.094.281.352.281t.586-.164.68-.258.656.023.492.633q.188.563-.07.82t-.75.328-1.008.094-.891.094-.328.305q0 .328.352.492t.773.328.609.445-.094.891q-.375.563-.773.633t-.844-.117-.844-.422-.75-.305-.633.305q-.281.422-.141 1.055t.281 1.242zm7.078-13.968q.797 0 1.383.586t.586 1.383v12q0 .844-.586 1.43t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.43v-12q0-.797.586-1.383t1.383-.586h3.188L9.001 3h6l1.828 2.016h3.188zM18.141 18q-.375 0-.609-.234t-.234-.609.234-.609.609-.234.609.234.234.609-.234.609-.609.234z"/>'
  }
})
