/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_frames': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016H6V18h12V8.016zm2.016 12V6h-4.5l-3.469-3.516L8.531 6H3.984v14.016h16.031zm0-16.032q.797 0 1.383.609t.586 1.406v14.016q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V5.999q0-.797.586-1.406t1.383-.609h4.031L12 0l3.984 3.984h4.031z"/>'
  }
})
