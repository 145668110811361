/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subscriptions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 15.984l-6-3.234v6.516zm6-3.984v8.016q0 .797-.586 1.383t-1.383.586H3.984q-.797 0-1.383-.586t-.586-1.383V12q0-.797.586-1.406t1.383-.609h16.031q.797 0 1.383.609T21.984 12zM18 2.016v1.969H6V2.016h12zm2.016 6H3.985V6h16.031v2.016z"/>'
  }
})
