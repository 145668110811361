/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flare': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 23.016v-6h1.969v6h-1.969zm-5.391-6.047l2.156-2.156 1.406 1.406-2.156 2.156zm9.188-.75l1.406-1.406 2.156 2.156-1.406 1.406zM12 9q1.219 0 2.109.891T15 12t-.891 2.109T12 15t-2.109-.891T9 12t.891-2.109T12 9zm5.016 2.016h6v1.969h-6v-1.969zm1.359-3.985l-2.156 2.156-1.406-1.406 2.156-2.156zM12.984.984v6h-1.969v-6h1.969zM9.188 7.781L7.782 9.187 5.626 7.031l1.406-1.406zm-2.204 3.235v1.969h-6v-1.969h6z"/>'
  }
})
