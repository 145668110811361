/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pest_control': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15v-2.016h-3.047q-.094-.609-.234-1.125l2.578-1.5-.984-1.734-2.391 1.359-.469-.656-.516-.609q0-.188.023-.352t.023-.352q0-1.219-.656-2.203l1.688-1.641-1.406-1.406-1.734 1.734q-.844-.469-1.57-.492t-1.289.141-.891.352L8.391 2.766 6.985 4.172l1.688 1.641q-.656.984-.656 2.203 0 .188.023.352t.023.352q-.563.609-.984 1.266L4.688 8.627l-.984 1.734 2.578 1.5q-.141.516-.234 1.125H3.001v2.016h3.047q.094.609.234 1.125l-2.578 1.5.984 1.734 2.391-1.359q.797 1.359 2.086 2.18t2.836.82 2.836-.82 2.086-2.18l2.391 1.359.984-1.734-2.578-1.453q.141-.563.234-1.172h3.047zm-8.016 2.016h-1.969v-6h1.969v6z"/>'
  }
})
