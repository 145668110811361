/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accessible': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.844 18h2.063q-.328 1.641-1.781 2.813t-3.141 1.172q-2.063 0-3.516-1.453t-1.453-3.516q0-1.688 1.172-3.141t2.813-1.781v2.063q-.891.328-1.453 1.102t-.563 1.758q0 1.219.891 2.109t2.109.891q.984 0 1.758-.563t1.102-1.453zm-2.86-8.906q0-1.125.984-1.781t2.016-.047h.047v.047q.328.141.609.422l1.313 1.453q1.688 1.828 4.031 1.828v1.969q-2.625 0-4.969-1.922v3.422h3q.797 0 1.383.609t.586 1.406v5.484h-1.969v-4.969h-5.016q-.797 0-1.406-.609T9.984 15V9.094zm0-5.11q0-.844.586-1.406T12 2.015t1.43.563.586 1.406-.586 1.43T12 6t-1.43-.586-.586-1.43z"/>'
  }
})
