/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'work_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.063 6V3.937H9.985V6h4.078zm7.921 1.969v10.313L8.062 4.266v-.328q0-.844.563-1.406t1.406-.516h4.031q.844-.047 1.406.516t.563 1.406v2.063h4.031q.844-.047 1.406.539t.516 1.43zm1.032 13.781l-1.266 1.266-2.063-2.063H4.031q-.844 0-1.406-.586t-.563-1.43V7.968q0-.844.563-1.43t1.406-.586h.656L1.968 3.233l1.266-1.266z"/>'
  }
})
