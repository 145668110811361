/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casino': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 9q.656 0 1.078-.422T18 7.5t-.422-1.078T16.5 6t-1.078.422T15 7.5t.422 1.078T16.5 9zm0 9q.656 0 1.078-.422T18 16.5t-.422-1.078T16.5 15t-1.078.422T15 16.5t.422 1.078T16.5 18zM12 13.5q.656 0 1.078-.422T13.5 12t-.422-1.078T12 10.5t-1.078.422T10.5 12t.422 1.078T12 13.5zM7.5 9q.656 0 1.078-.422T9 7.5t-.422-1.078T7.5 6t-1.078.422T6 7.5t.422 1.078T7.5 9zm0 9q.656 0 1.078-.422T9 16.5t-.422-1.078T7.5 15t-1.078.422T6 16.5t.422 1.078T7.5 18zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
