/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_ferry': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 6v3.984l6-1.969 6 1.969V6H6zM3.938 18.984l-1.875-6.656q-.047-.094-.047-.328 0-.656.703-.938l1.266-.422V5.999q0-.797.609-1.406T6 3.984h3v-3h6v3h3q.797 0 1.406.609t.609 1.406v4.641l1.266.422q.984.328.656 1.266l-1.875 6.656h-.047q-2.297 0-4.031-1.969Q14.25 18.984 12 18.984t-3.984-1.969q-1.734 1.969-4.031 1.969h-.047zM20.016 21h1.969v2.016h-1.969q-2.109 0-4.031-.984-1.875.938-3.984.938t-3.984-.938q-1.922.984-4.031.984H2.017V21h1.969q2.156 0 4.031-1.313 1.828 1.266 3.984 1.266t3.984-1.266Q17.86 21 20.016 21z"/>'
  }
})
