/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_indent_decrease': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 12.984v-1.969H21v1.969h-9.984zm0-3.984V6.984H21V9h-9.984zM3 3h18v2.016H3V3zm0 18v-2.016h18V21H3zm0-9l3.984-3.984v7.969zm8.016 5.016V15H21v2.016h-9.984z"/>'
  }
})
