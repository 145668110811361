/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_list_numbered': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 12.984v-1.969H21v1.969H6.984zm0 6v-1.969H21v1.969H6.984zm0-13.968H21v1.969H6.984V5.016zm-4.968 6V9.985h3v.938l-1.828 2.063h1.828v1.031h-3v-.938l1.781-2.063H2.016zm.984-3v-3h-.984V3.985h1.969v4.031h-.984zm-.984 9v-1.031h3v4.031h-3v-1.031h1.969v-.469h-.984v-1.031h.984v-.469H2.016z"/>'
  }
})
