/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports_volleyball': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.938 10.828l-8.953-5.156V2.063q3.469.375 6 2.836t2.953 5.93zM8.063 21.188l8.953-5.156 3.094 1.828q-3 4.125-8.109 4.125-2.063 0-3.938-.797zm4.921-13.219l8.953 5.156q-.188 1.547-.844 2.953l-8.109-4.641V7.968zM12 13.172l3 1.734-8.953 5.109q-1.172-.844-2.156-2.203zm-.984-1.734l-3 1.734V2.859q1.453-.656 3-.797v9.375zM6 4.031v10.266l-3.094 1.828Q2.015 14.109 2.015 12q0-4.969 3.984-7.969z"/>'
  }
})
