/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nights_stay': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15.984q.797 0 1.406.609t.609 1.406-.609 1.406-1.406.609h-3q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891q2.109 0 2.813 1.969h.188zm4.125-3.89q1.547 3 4.289 4.313t4.945 1.078q-1.359 2.109-3.563 3.328t-4.781 1.219q-1.594 0-3.141-.516 2.156-1.219 2.156-3.516 0-1.266-.844-2.367t-2.063-1.477Q6.56 12 3.982 12q-1.078 0-1.969.422 0-.047-.023-.188T1.967 12q0-4.031 2.813-6.938t6.844-3.047q-.703 1.078-1.172 2.344t-.445 3.469 1.102 4.266z"/>'
  }
})
