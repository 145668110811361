/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outgoing_mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.516 11.016h.469V6.891q0-.797-.539-1.336t-1.336-.539H3.891q-.797 0-1.336.539t-.539 1.336V17.11q0 .797.539 1.336t1.336.539h9.703q-.281-.563-.445-1.195t-.164-1.289q0-1.5.75-2.766t1.992-1.992 2.789-.727zm-8.11 1.968L3.984 9.187V6.984h.234l6.188 3.703 6.328-3.703h.281V9.14zm8.578 0l-1.406 1.406 1.594 1.594H15V18h4.172l-1.594 1.594L18.984 21l4.031-3.984z"/>'
  }
})
