/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'android': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 5.016V3.985h-.984v1.031H15zm-5.016 0V3.985H9v1.031h.984zm5.532-2.86Q18 3.937 18 6.984H6q0-1.266.703-2.672t1.734-2.156L7.124.843q-.375-.375 0-.703t.703 0l1.5 1.5q1.219-.656 2.672-.656 1.219 0 2.625.656l1.5-1.5q.375-.328.703 0t0 .703zm4.968 5.86q.609 0 1.055.445t.445 1.055V16.5q0 .656-.445 1.078T20.484 18t-1.055-.422-.445-1.078V9.516q0-.609.445-1.055t1.055-.445zm-16.968 0q.609 0 1.055.445t.445 1.055V16.5q0 .656-.445 1.078T3.516 18t-1.055-.422-.445-1.078V9.516q0-.609.445-1.055t1.055-.445zM6 18V8.016h12V18q0 .422-.281.703t-.703.281h-1.031V22.5q0 .656-.445 1.078T14.485 24t-1.055-.422-.445-1.078v-3.516h-1.969V22.5q0 .656-.445 1.078T9.516 24t-1.055-.422-.445-1.078v-3.516H6.985q-.422 0-.703-.281T6.001 18z"/>'
  }
})
