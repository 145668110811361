/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k_plus2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 12.516v-1.031h-1.5v-1.5h-1.031v1.5h-1.5v1.031h1.5v1.5h1.031v-1.5h1.5zM15.984 15l-2.25-3 2.25-3H14.25l-1.734 2.25V9h-1.5v6h1.5v-2.25L14.25 15h1.734zm-6.468-.984V9.985q0-.422-.305-.703t-.727-.281H5.015v1.5h3v.984H5.999v1.031h2.016v.984h-3V15h3.469q.422 0 .727-.281t.305-.703zM18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
