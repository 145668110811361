/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_power': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 24v-2.016h2.016V24H15zm1.547-19.547q3.469 2.438 3.469 6.563 0 3.281-2.344 5.625T12 18.985t-5.672-2.344-2.344-5.625q0-1.734 1.008-3.656t2.461-2.906L8.859 5.86Q6 7.641 6 11.016q0 2.484 1.758 4.242T12 17.016t4.242-1.758T18 11.016q0-1.406-.844-2.906t-2.063-2.203zm-3.563-2.437V12h-1.969V2.016h1.969zM11.016 24v-2.016h1.969V24h-1.969zm-4.032 0v-2.016H9V24H6.984z"/>'
  }
})
