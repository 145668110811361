/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card_membership': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 9.984v-6H3.985v6h16.031zm0 5.016v-2.016H3.985V15h16.031zm0-12.984q.844 0 1.406.563t.563 1.406v11.016q0 .844-.563 1.43t-1.406.586h-4.031v4.969l-3.984-1.969-3.984 1.969v-4.969H3.986q-.844 0-1.406-.586t-.563-1.43V3.985q0-.844.563-1.406t1.406-.563h16.031z"/>'
  }
})
