/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app_registration': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 3.984h4.031v4.031H9.984V3.984zm-6 12h4.031v4.031H3.984v-4.031zm0-6h4.031v4.031H3.984V9.984zm0-6h4.031v4.031H3.984V3.984zm10.032 8.438V9.984H9.985v4.031h2.438zm6.843-1.125l-1.172-1.172q-.094-.141-.258-.141t-.305.141l-.891.891 1.781 1.734.844-.891q.141-.094.141-.281t-.141-.281zm-9.843 6.937v1.781h1.734l6.656-6.703-1.734-1.734zm4.968-14.25h4.031v4.031h-4.031V3.984z"/>'
  }
})
