/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reset_tv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9.984q.797 0 1.406.609t.609 1.406h-.047v5.016q0 .797-.586 1.383t-1.383.586h-5.016V21H8.014v-2.016H2.998q-.797 0-1.406-.586t-.609-1.383v-12q0-.797.609-1.406T2.998 3h18q.797 0 1.406.609t.609 1.406v3h-2.016v-3h-18v12h18v-5.016h-8.016v3l-3.984-3.984 3.984-4.031v3h8.016z"/>'
  }
})
