/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_bluetooth_speaker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 15.516q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.094.188.094.328 0 .469-.328.703l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563zM18 7.219v1.875l.938-.938zm0-4.313v1.875l.938-.938zm-3.281 6.61l-.703-.703L16.782 6l-2.766-2.813.703-.703 2.297 2.297V.984h.469l2.859 2.859-2.156 2.156 2.156 2.156-2.859 2.859h-.469V7.217z"/>'
  }
})
