/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'donut_small': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 14.859q1.266-.375 1.875-1.875h7.125q-.375 3.609-2.836 6.141t-6.164 2.859v-7.125zm1.875-3.843q-.563-1.5-1.875-1.875V2.016q3.703.328 6.164 2.859t2.836 6.141h-7.125zm-3.843-1.875q-.797.328-1.406 1.125T9.001 12t.609 1.734 1.406 1.125v7.125q-3.797-.375-6.398-3.234T2.016 12t2.602-6.75 6.398-3.234v7.125z"/>'
  }
})
