/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'disabled_by_default': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3v18h18V3H3zm14.016 12.609l-1.406 1.406-3.609-3.609-3.609 3.609-1.406-1.406L10.595 12 6.986 8.391l1.406-1.406 3.609 3.609 3.609-3.609 1.406 1.406L13.407 12z"/>'
  }
})
