/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elevator': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3H5.015q-.844 0-1.43.586t-.586 1.43v13.969q0 .844.586 1.43t1.43.586h13.969q.844 0 1.43-.586t.586-1.43V5.016q0-.844-.586-1.43T18.984 3zm-10.5 3q.516 0 .891.375t.375.891-.375.867-.891.352-.867-.352-.352-.867.352-.891T8.484 6zm2.532 8.016H9.985V18h-3v-3.984h-.984v-2.531q0-.797.586-1.383t1.43-.586h.984q.844 0 1.43.586t.586 1.383v2.531zm4.5 3l-2.531-4.031h5.016zm-2.532-6l2.531-4.031 2.484 4.031h-5.016z"/>'
  }
})
