/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tab_unselected': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 21v-2.016h1.969V21h-1.969zm-4.032 0v-2.016H15V21h-2.016zM21 12.984v-1.969h2.016v1.969H21zM21 21v-2.016h2.016q0 .797-.609 1.406t-1.406.609zM5.016 5.016V3h1.969v2.016H5.016zm0 15.984v-2.016h1.969V21H5.016zM9 5.016V3h2.016v2.016H9zm12 12V15h2.016v2.016H21zM21 3q.797 0 1.406.609t.609 1.406v3.984H12.984v-6H21zM3 21q-.797 0-1.406-.609t-.609-1.406h2.016v2.016zM.984 17.016V15H3v2.016H.984zM9 21v-2.016h2.016V21H9zM.984 5.016q0-.797.609-1.406t1.406-.609v2.016H.983zm0 7.968v-1.969H3v1.969H.984zM.984 9V6.984H3V9H.984z"/>'
  }
})
