/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'charging_station': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 11.016l-3 6v-4.031H9.515l3-6v4.031h1.969zM6.984.984h10.031q.797 0 1.383.586T18.984 3v18q0 .844-.586 1.43t-1.383.586H6.984q-.797 0-1.383-.586T5.015 21V3q0-.844.586-1.43T6.984.984zm0 5.016v12h10.031V6H6.984z"/>'
  }
})
