/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_voice': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9.984q0 2.531-1.758 4.453t-4.242 2.297v3.281h-1.969v-3.281q-2.484-.375-4.242-2.297T5.015 9.984h1.688q0 2.203 1.57 3.656T12 15.093t3.727-1.453 1.57-3.656h1.688zM15 24v-2.016h2.016V24H15zm-3.984 0v-2.016h1.969V24h-1.969zM12 12.984q-1.219 0-2.109-.891T9 9.984v-6q0-1.219.891-2.109T12 .984t2.109.891T15 3.984v6q0 1.219-.891 2.109T12 12.984zM6.984 24v-2.016H9V24H6.984z"/>'
  }
})
