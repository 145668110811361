/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_portrait': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 18.984V5.015H6.985v13.969h10.031zm0-15.984q.797 0 1.383.609t.586 1.406v13.969q0 .797-.586 1.406t-1.383.609H6.985q-.797 0-1.383-.609t-.586-1.406V5.015q0-.797.586-1.406T6.985 3h10.031z"/>'
  }
})
