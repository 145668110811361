/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_device_information': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 18.984V5.015H6.985v13.969h10.031zm0-17.953q.797 0 1.383.586T18.985 3v18q0 .797-.586 1.406t-1.383.609H6.985q-.797 0-1.383-.609T5.016 21V3q0-.797.586-1.406T6.985.985zm-4.032 9.985v6h-1.969v-6h1.969zm0-4.032V9h-1.969V6.984h1.969z"/>'
  }
})
