/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'celebration': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.047 5.859q.328-.328.328-.844t-.328-.891L13.453 2.53l1.078-1.078 1.594 1.594q.797.891.797 1.969 0 1.031-.797 1.922l-3.609 3.609-1.031-1.078zm2.016 6q.891-.797 1.922-.797 1.078 0 1.969.797l1.594 1.641-1.031 1.031-1.641-1.594q-.375-.375-.891-.375-.469 0-.844.375l-1.594 1.594-1.078-1.078zm-6.985-4.968q.328-.328.328-.891t-.328-.891l-.609-.563 1.078-1.078.563.563q.797.891.797 1.969 0 1.031-.797 1.922l-.563.609-1.078-1.078zm4.453 5.625l-1.078-1.031 5.625-5.625q.891-.797 1.922-.797 1.078 0 1.969.797l.563.609-1.031 1.078-.609-.609q-.375-.375-.891-.375t-.891.375zM2.016 21.984L6.985 8.015l9 9z"/>'
  }
})
