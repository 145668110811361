/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'k2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.484 15l-2.25-3 2.25-3H15.75l-1.734 2.25V9h-1.5v6h1.5v-2.25L15.75 15h1.734zM10.5 15V9h-3v1.5H9V15h1.5zm8.484-12q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969z"/>'
  }
})
