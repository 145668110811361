/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electrical_services': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 14.016q0-.422-.305-.727t-.68-.305h-2.016V15h2.016q.375 0 .68-.305t.305-.68zm-.984 3H18v1.969h2.016q.375 0 .68-.281t.305-.703-.305-.703-.68-.281zm-8.016-3H9.984V18H12q0 .844.586 1.43t1.43.586h3V12h-3q-.844 0-1.43.586T12 14.016zm-6.984-1.032q0-.797.586-1.383t1.383-.586h1.5q.984 0 1.781-.492t1.266-1.266.469-1.758-.469-1.758-1.266-1.266-1.781-.492H5.016q-.422 0-.727.305t-.305.727q0 .375.305.68T5.016 6h3.469q.656 0 1.078.445T9.985 7.5t-.422 1.055T8.485 9h-1.5q-1.078 0-1.992.539T3.54 10.992t-.539 1.992q0 1.125.539 2.039t1.453 1.453 1.992.539h2.016v-2.016H6.985q-.797 0-1.383-.586t-.586-1.43z"/>'
  }
})
