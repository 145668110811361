/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'update': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.516 8.016v4.219l3.469 2.109-.703 1.219-4.266-2.578V8.016h1.5zM21 10.125h-6.797l2.766-2.813q-2.063-2.063-4.945-2.086T7.079 7.218q-.844.844-1.453 2.273t-.609 2.602.609 2.602 1.453 2.273 2.297 1.453 2.625.609 2.648-.609 2.32-1.453q2.016-2.016 2.016-4.875h2.016q0 3.703-2.625 6.281-2.625 2.625-6.375 2.625t-6.375-2.625q-2.625-2.578-2.625-6.258t2.625-6.305q1.078-1.078 2.93-1.852t3.398-.773 3.398.773 2.93 1.852l2.719-2.813v7.125z"/>'
  }
})
