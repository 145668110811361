/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nightlight_round': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12q0-1.781.68-3.352t1.875-2.742 2.742-1.781q.469-.188.586-.586t-.094-.797-.68-.492q-.797-.188-1.664-.234t-1.805.094q-1.688.188-3.164.961T7.851 5.04 5.976 7.735t-.914 3.188q-.234 2.25.445 4.242t2.086 3.539 3.305 2.414 4.102.867q1.078 0 2.109-.234.516-.094.703-.469t.07-.797-.539-.609q-1.641-.656-2.836-1.852t-1.852-2.742-.656-3.281z"/>'
  }
})
