/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sync_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 3.984l-2.391 2.391q.984.938 1.688 2.602T20.016 12q0 2.203-1.219 4.219l-1.5-1.453Q18 13.36 18 12q0-1.031-.516-2.25t-1.266-1.969l-2.203 2.203v-6h6zM2.859 5.391l1.266-1.266 15.703 15.75-1.266 1.266-2.344-2.344q-1.125.656-2.25.938v-2.063q.375-.141.797-.375L6.702 9.234Q5.999 10.64 5.999 12q0 1.031.516 2.25t1.266 1.969l2.203-2.203v6h-6l2.391-2.391q-.984-.938-1.688-2.602T3.984 12q0-2.203 1.219-4.219zm7.125.937q-.281.094-.75.375l-1.453-1.5q1.172-.703 2.203-.938v2.063z"/>'
  }
})
