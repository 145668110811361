/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumbs_up_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 9.984q.656 0 1.078.445T24 11.484V18q0 .656-.422 1.078L18.609 24l-.797-.797q-.328-.328-.328-.797.141-.656.398-1.875t.305-1.547h-5.203q-.422 0-.703-.281T12 18v-1.266q0-.141.094-.516l2.297-5.297q.422-.938 1.359-.938h6.75zM12 6v1.266q0 .141-.094.516l-2.297 5.297q-.422.938-1.359.938H1.5q-.656 0-1.078-.445T0 12.517V6.001q0-.656.422-1.078L5.391.001l.797.797q.328.328.328.797-.141.656-.398 1.875t-.305 1.547h5.203q.422 0 .703.281t.281.703z"/>'
  }
})
