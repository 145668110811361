/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subject': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 5.016h16.031v1.969H3.984V5.016zm0 9.984v-2.016h16.031V15H3.984zm16.032-6v2.016H3.985V9h16.031zm-6 8.016v1.969H3.985v-1.969h10.031z"/>'
  }
})
