/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music_video': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 15q0-1.219.891-2.109T11.016 12q.328 0 .984.188V6h5.016v2.016h-3v7.031q0 1.219-.891 2.086T11.016 18t-2.109-.891T8.016 15zM21 18.984V5.015H3v13.969h18zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18z"/>'
  }
})
