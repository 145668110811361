/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'assistant_navigation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.563 17.016l.375-.375L12 6.985l-3.938 9.656.375.375L12 15.422zM12 .984q4.547 0 7.781 3.234t3.234 7.781-3.234 7.781T12 23.014 4.219 19.78.985 11.999t3.234-7.781T12 .984z"/>'
  }
})
