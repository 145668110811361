/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit_card': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 8.016V6H3.985v2.016h16.031zm0 9.984v-6H3.985v6h16.031zm0-14.016q.844 0 1.406.586T21.985 6v12q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586T2.016 18V6q0-.844.563-1.43t1.406-.586h16.031z"/>'
  }
})
