/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dns': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 9q.797 0 1.406-.609t.609-1.406-.609-1.383-1.406-.586-1.383.586-.586 1.383.586 1.406T6.984 9zm13.032-6q.422 0 .703.281t.281.703v6q0 .422-.281.727t-.703.305H3.985q-.422 0-.703-.305t-.281-.727v-6q0-.422.281-.703T3.985 3h16.031zM6.984 18.984q.797 0 1.406-.586t.609-1.383-.609-1.406T6.984 15t-1.383.609-.586 1.406.586 1.383 1.383.586zm13.032-6q.422 0 .703.305t.281.727v6q0 .422-.281.703t-.703.281H3.985q-.422 0-.703-.281t-.281-.703v-6q0-.422.281-.727t.703-.305h16.031z"/>'
  }
})
