/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions_subway': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 11.016V6h-5.016v5.016H18zm-1.5 6q.656 0 1.078-.445T18 15.516t-.422-1.055-1.078-.445-1.078.445T15 15.516t.422 1.055 1.078.445zm-5.484-6V6H6v5.016h5.016zm-3.516 6q.656 0 1.078-.445T9 15.516t-.422-1.055-1.078-.445-1.078.445T6 15.516t.422 1.055 1.078.445zm4.5-15q3.891 0 5.953.797t2.063 3.188v9.516q0 1.453-1.031 2.461t-2.484 1.008l1.5 1.5v.516h-12v-.516l1.5-1.5q-1.453 0-2.484-1.008t-1.031-2.461V6.001q0-2.391 2.063-3.188t5.953-.797z"/>'
  }
})
