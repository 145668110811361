/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 6.984H21V9H6.984V6.984zm0 10.032V15H21v2.016H6.984zm0-4.032v-1.969H21v1.969H6.984zM3 9V6.984h2.016V9H3zm0 8.016V15h2.016v2.016H3zm0-4.032v-1.969h2.016v1.969H3z"/>'
  }
})
