/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nature': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 16.125v3.891h6v1.969H5.015v-1.969h6v-3.938q-2.438-.375-4.148-2.391t-1.711-4.5q0-2.906 2.063-4.969t4.969-2.063 4.945 2.063 2.039 4.969q0 2.578-1.805 4.594t-4.383 2.344z"/>'
  }
})
