/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_fields': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.516 9v3h-3v6.984h-3V12h-3V9h9zM2.484 3.984h13.031v3h-5.016v12h-3v-12H2.483v-3z"/>'
  }
})
