/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel_schedule_send': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.281 14.016l.703.703-1.781 1.781 1.781 1.781-.703.703-1.781-1.781-1.781 1.781-.703-.703 1.781-1.781-1.781-1.781.703-.703 1.781 1.781zM16.5 21.984q2.297 0 3.891-1.594t1.594-3.891-1.594-3.891-3.891-1.594-3.891 1.594-1.594 3.891 1.594 3.891 3.891 1.594zM16.5 9q3.094 0 5.297 2.203T24 16.5t-2.203 5.297T16.5 24q-2.813 0-4.922-1.852t-2.484-4.617L1.031 21l-.047-6.984 9-2.016-9-2.016L1.031 3l14.25 6.094Q16.125 9 16.5 9z"/>'
  }
})
