/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 18.984v-4.969h2.016v4.969q0 .797-.609 1.406t-1.406.609H3.985q-.797 0-1.383-.609t-.586-1.406V5.015q0-.797.586-1.406T3.985 3h12v2.016h-12v13.969h14.016zm2.016-12h3V9h-3v3H18V9h-3V6.984h3v-3h2.016v3zm-10.032 0H12v9.984H9.984V6.984zm4.032 6h1.969v3.984h-1.969v-3.984zM6 9.984h2.016v6.984H6V9.984z"/>'
  }
})
