/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 17.016V6.985L15 12.001z"/>'
  }
})
