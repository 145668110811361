/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group_work': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 17.484q1.031 0 1.781-.727t.75-1.758-.75-1.758-1.781-.727-1.758.727-.727 1.758.727 1.758 1.758.727zM9.516 8.016q0 1.031.727 1.758t1.758.727 1.758-.727.727-1.758-.727-1.781-1.758-.75-1.758.75-.727 1.781zm-1.5 9.468q1.031 0 1.758-.727t.727-1.758-.727-1.758-1.758-.727-1.781.727-.75 1.758.75 1.758 1.781.727zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
