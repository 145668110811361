/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'voice_over_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.063 2.016q2.906 3.047 2.906 7.055t-2.906 6.914l-1.641-1.594Q20.485 12 20.485 8.93t-2.063-5.32zm-3.282 3.328q1.5 1.641 1.5 3.703t-1.5 3.563l-1.688-1.688q.656-.891.656-1.945t-.656-1.945zM4.266 3L21 19.734 19.734 21l-2.953-2.953q.094.234.164.469t.07.469v2.016H.984v-2.016q0-1.781 2.742-2.883T8.999 15q.516 0 1.148.07t1.359.211 1.406.328 1.336.469 1.172.609l-4.313-4.313q-.984.609-2.109.609-1.641 0-2.813-1.172T5.013 8.998q0-1.125.609-2.109L2.997 4.264zm8.718 6.188L8.812 5.016H9q1.641 0 2.813 1.172t1.172 2.813v.188z"/>'
  }
})
