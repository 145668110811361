/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outbond': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zm1.875 9.515L8.906 16.5 7.5 15.094l4.969-4.969-2.109-2.109h5.625v5.625z"/>'
  }
})
