/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fit_screen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016v7.969H6V8.016h12zM6.984 18v2.016h-3q-.797 0-1.383-.609t-.586-1.406v-2.016h1.969v2.016h3zm13.032-2.016h1.969V18q0 .797-.586 1.406t-1.383.609h-3v-2.016h3v-2.016zM3.984 8.016H2.015V6q0-.797.586-1.406t1.383-.609h3v2.016h-3v2.016zm13.032-4.032h3q.797 0 1.383.609t.586 1.406v2.016h-1.969V5.999h-3V3.983z"/>'
  }
})
