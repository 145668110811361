/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recent_actors': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.516 17.016v-.75q0-1.031-1.547-1.641t-2.953-.609-2.953.609-1.547 1.641v.75h9zm-4.5-9.282q-.891 0-1.57.68t-.68 1.57.68 1.57 1.57.68 1.57-.68.68-1.57-.68-1.57-1.57-.68zm6-2.718q.422 0 .703.281T15 6v12q0 .422-.281.703t-.703.281h-12q-.422 0-.727-.281T.984 18V6q0-.422.305-.703t.727-.281h12zm3 13.968V5.015h1.969v13.969h-1.969zM21 5.016h2.016v13.969H21V5.016z"/>'
  }
})
