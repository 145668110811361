/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery_full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.656 3.984q.563 0 .961.398t.398.961v15.328q0 .563-.398.938t-.961.375H8.343q-.563 0-.961-.375t-.398-.938V5.343q0-.563.398-.961t.961-.398h1.641V2.015h4.031v1.969h1.641z"/>'
  }
})
