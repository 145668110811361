/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 12.984v-6h-1.969v6h1.969zM12 17.297q.516 0 .914-.398t.398-.914-.398-.891-.914-.375-.914.375-.398.891.398.914.914.398zM15.75 3L21 8.25v7.5L15.75 21h-7.5L3 15.75v-7.5L8.25 3h7.5z"/>'
  }
})
