/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'king_bed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9.984v-3h-5.016v3H18zm-6.984 0v-3H6v3h5.016zm9 0q.797 0 1.383.609t.586 1.406v5.016h-1.313l-.656 1.969h-1.031l-.656-1.969H5.673l-.656 1.969H3.986l-.656-1.969H2.017v-5.016q0-.797.586-1.406t1.383-.609v-3q0-.797.609-1.383t1.406-.586h12q.797 0 1.406.586t.609 1.383v3z"/>'
  }
})
