/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monetization_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.406 18.094q3.141-.609 3.141-3.141 0-2.859-4.219-3.938-2.625-.656-2.625-1.922 0-.703.633-1.172t1.758-.469q2.109 0 2.203 1.875h1.969q-.094-2.672-2.859-3.375V3.983h-2.672v1.969q-1.359.281-2.227 1.102T7.641 9.14q0 2.625 4.172 3.656 2.672.609 2.672 2.156 0 .609-.539 1.102t-1.852.492q-2.484 0-2.672-1.875H7.453q.141 2.672 3.281 3.375v1.969h2.672v-1.922zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
