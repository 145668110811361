/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'deck': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.078 15.984L19.875 12l1.922.375L21 16.734v5.25h-2.016V18h-1.969v3.984h-2.016v-6h4.078zM4.125 12l.797 3.984H9v6H6.984V18H5.015v3.984H2.999v-5.25l-.797-4.359zm17.859-3h-9v12.984h-1.969V9h-9l9.984-6.984z"/>'
  }
})
