/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inventory': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 6.984v-3H3.985v3h16.031zM15 14.016V12H9v2.016h6zm5.016-12q.75 0 1.359.586t.609 1.383v3.047q0 1.125-.984 1.688v11.297q0 .797-.656 1.383t-1.359.586H5.016q-.703 0-1.359-.586t-.656-1.383V8.72q-.984-.563-.984-1.688V3.985q0-.797.609-1.383t1.359-.586h16.031z"/>'
  }
})
