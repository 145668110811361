/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'electric_car': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.938 2.016q-.188-.469-.563-.75t-.891-.281H6.515q-.516 0-.891.281t-.563.75l-2.063 6v7.969q0 .422.305.727t.68.305h1.031q.375 0 .68-.305t.305-.727v-.984h12v.984q0 .422.305.727t.68.305h1.031q.375 0 .68-.305t.305-.727V8.016zM6.516 12q-.656 0-1.078-.445T5.016 10.5t.422-1.055T6.516 9q.609 0 1.055.445t.445 1.055-.445 1.055T6.516 12zm10.968 0q-.609 0-1.055-.445t-.445-1.055.445-1.055T17.484 9q.656 0 1.078.445t.422 1.055-.422 1.055-1.078.445zM5.016 6.984l1.5-4.5h10.969l1.5 4.5H5.016zm1.968 13.032h4.031V18l6 3h-4.031v2.016z"/>'
  }
})
