/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'not_listed_location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.891 12.891q0-.938 1.313-2.109t1.313-2.297q0-1.453-1.031-2.461t-2.484-1.008-2.484 1.008-1.031 2.461h1.781q0-.703.516-1.219t1.219-.516 1.219.516.516 1.219q0 .563-.398 1.031t-.914.773-.914.961-.398 1.641h1.781zm0 2.859v-1.734H11.11v1.734h1.781zM12 2.016q2.906 0 4.945 2.039T18.984 9q0 1.453-.727 3.328t-1.758 3.516-2.039 3.07-1.711 2.273l-.75.797q-.281-.328-.75-.867t-1.688-2.156-2.133-3.141-1.664-3.445T5.014 9q0-2.906 2.039-4.945t4.945-2.039z"/>'
  }
})
