/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gradient': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 11.016v-6H5.015v6h1.969v1.969H9v2.016h2.016v-2.016h1.969v2.016h2.016v-2.016h2.016v-1.969h1.969zM17.016 18v-2.016H15V18h2.016zm-4.032 0v-2.016h-1.969V18h1.969zM9 18v-2.016H6.984V18H9zm9.984-15q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969zm-12 6H9v2.016H6.984V9zM15 9h2.016v2.016H15V9zm-3.984 0h1.969v2.016h2.016v1.969h-2.016v-1.969h-1.969v1.969H9v-1.969h2.016V9zm6 3.984V15h1.969v-2.016h-1.969zm-10.032 0H5.015V15h1.969v-2.016z"/>'
  }
})
