/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_ethernet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.766 5.484L23.204 12l-5.438 6.516-1.547-1.266L20.578 12l-4.359-5.25zm-6.75 7.5v-1.969h1.969v1.969h-1.969zm6-1.968v1.969H15v-1.969h2.016zM6.984 12.984v-1.969H9v1.969H6.984zm.797-6.234L3.422 12l4.359 5.25-1.547 1.266L.796 12l5.438-6.516z"/>'
  }
})
