/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'now_widgets': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.641 1.688l5.672 5.672-5.672 5.625H21v8.016h-8.016v-8.016h3.656L11.015 7.36v3.656H2.999V3h8.016v4.359zM3 21v-8.016h8.016V21H3z"/>'
  }
})
