/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'looks_6': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9V6.984h-3.984q-.844 0-1.43.586T9 9v6q0 .844.586 1.43t1.43.586h1.969q.844 0 1.43-.586t.586-1.43v-2.016q0-.844-.586-1.406t-1.43-.563h-1.969V8.999H15zm3.984-6q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969zm-7.968 12v-2.016h1.969V15h-1.969z"/>'
  }
})
