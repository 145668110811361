/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'redo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.422 10.594l3.563-3.609v9h-9l3.656-3.609q-2.25-1.875-5.156-1.875-2.391 0-4.617 1.594t-2.977 3.891l-2.344-.75q1.031-3.188 3.773-5.203t6.164-2.016q3.984 0 6.938 2.578z"/>'
  }
})
