/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flash_auto': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.828 7.641h2.344L18 3.985zm2.156-5.625l3.234 9h-1.922L19.593 9h-3.188l-.703 2.016H13.78l3.234-9h1.969zM3 2.016h9.984l-3.984 9h3.984L6 23.016v-9H3v-12z"/>'
  }
})
