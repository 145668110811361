/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'note_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 9h5.531l-5.531-5.484V9zm3 6.984v-1.969h-3v-3h-1.969v3h-3v1.969h3v3h1.969v-3h3zM14.016 2.016l6 6v12q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383l.047-16.031q0-.797.586-1.383t1.383-.586h8.016z"/>'
  }
})
