/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'countertops': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9.984v-3q0-.797-.398-1.477t-1.078-1.102-1.523-.422-1.523.422T12.4 5.507t-.398 1.477h2.016q0-.375.281-.68t.703-.305.703.305.281.68v3H8.017q.797 0 1.383-.586t.586-1.383V3.984h-6v4.031q0 .797.586 1.383t1.43.586H2.018V12h1.969v8.016h16.031V12h1.969V9.984h-3.984zM12.984 18h-1.969v-6h1.969v6z"/>'
  }
})
