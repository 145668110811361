/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'portrait': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18.984V5.015H5.015v13.969h13.969zm0-15.984q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h13.969zM16.5 16.266v.75h-9v-.75q0-1.031 1.547-1.641T12 14.016t2.953.609 1.547 1.641zM12 12.234q-.938 0-1.594-.68t-.656-1.57.656-1.57T12 7.734t1.594.68.656 1.57-.656 1.57-1.594.68z"/>'
  }
})
