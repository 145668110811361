/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transform': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 8.016V6h6q.797 0 1.406.609t.609 1.406v6h-2.016v-6h-6zm12 9.984H18v2.016h2.016l-3 3-3-3h1.969V18H8.016q-.797 0-1.406-.609t-.609-1.406V8.016H2.017V6h3.984V3.984H3.985l3-3 3 3H8.016v12h13.969V18z"/>'
  }
})
