/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_capslock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 18v-2.016h12V18H6zm6-9.609l-4.594 4.594L6 11.579l6-6 6 6-1.406 1.406z"/>'
  }
})
