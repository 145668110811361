/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_horizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 21v-2.016H21V21h-2.016zM15 21v-2.016h2.016V21H15zm-3.984-3.984V15h1.969v2.016h-1.969zM18.984 9V6.984H21V9h-2.016zm0-6H21v2.016h-2.016V3zM3 12.984v-1.969h18v1.969H3zM11.016 21v-2.016h1.969V21h-1.969zm7.968-3.984V15H21v2.016h-2.016zM12.984 3v2.016h-1.969V3h1.969zm0 3.984V9h-1.969V6.984h1.969zM17.016 3v2.016H15V3h2.016zM9 3v2.016H6.984V3H9zM5.016 3v2.016H3V3h2.016zm1.968 18v-2.016H9V21H6.984zM3 17.016V15h2.016v2.016H3zM5.016 6.984V9H3V6.984h2.016zM3 21v-2.016h2.016V21H3z"/>'
  }
})
