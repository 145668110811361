/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'push_pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9V3.984h1.031q.375 0 .68-.281T18 3q0-.422-.305-.703t-.68-.281H6.984q-.375 0-.68.281T5.999 3q0 .422.305.703t.68.281h1.031V9q0 .844-.422 1.523t-1.102 1.078-1.477.398v2.016h5.953v6.984l.984.984 1.031-.984v-6.984h6v-2.016q-.797 0-1.477-.398t-1.102-1.078T15.981 9z"/>'
  }
})
