/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_16_9': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15.984V8.015H5.015v7.969h13.969zm0-9.984q.797 0 1.406.609t.609 1.406v7.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 15.984V8.015q0-.797.609-1.406T5.015 6h13.969z"/>'
  }
})
