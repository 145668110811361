/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.594 8.578L18 9.984l-6 6-6-6 1.406-1.406L12 13.172z"/>'
  }
})
