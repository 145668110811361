/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flash_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 2.016h10.031l-4.031 7.969h4.031l-7.031 12v-9h-3V2.016z"/>'
  }
})
