/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tablet_android': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.266 18.984V3H4.735v15.984h14.531zm-5.25 3V21H9.985v.984h4.031zM18 0q1.219 0 2.109.891T21 3v18q0 1.219-.891 2.109T18 24H6q-1.219 0-2.109-.891T3 21V3q0-1.219.891-2.109T6 0h12z"/>'
  }
})
