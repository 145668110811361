/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'room_preferences': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 11.25V6h3v3.984h1.969v-6h-4.969V3h-9v15.984H3V21h9.281q-.609-.844-.938-1.852t-.328-2.133q0-1.781.82-3.281t2.18-2.484zm-4.032-.234H12v1.969H9.984v-1.969zm11.719 5.343l1.125-.984-.984-1.734-1.453.469q-.469-.375-1.078-.609l-.328-1.5h-1.969l-.328 1.5q-.609.234-1.078.609l-1.453-.469-.984 1.734 1.125.984q-.094.609 0 1.266l-1.125.984.984 1.734 1.453-.469q.469.375 1.078.609l.328 1.5h1.969l.328-1.453q.609-.234 1.078-.656l1.453.516.984-1.734-1.125-1.031q.047-.375.047-.633t-.047-.633zM18 18.984q-.844 0-1.43-.586t-.586-1.383q0-.844.586-1.43t1.43-.586 1.43.586.586 1.43q0 .797-.586 1.383t-1.43.586z"/>'
  }
})
