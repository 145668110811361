/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ramen_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 3l-12 1.406V6h12v.984h-12V12h12q0 2.719-1.664 4.945t-4.336 3.305v1.734H8.015V20.25q-2.672-1.078-4.336-3.305T2.015 12h3V3.984l16.969-1.969v.984zM6 4.875V6h.984V4.781zm0 2.109V12h.984V6.984H6zM9 12V6.984h-.984V12H9zm0-6V4.547l-.984.094V6H9z"/>'
  }
})
