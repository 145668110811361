/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi_tethering': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3q4.125 0 7.055 2.906t2.93 7.078q0 2.719-1.336 5.039t-3.633 3.633l-1.031-1.734q1.828-1.078 2.93-2.93t1.102-4.008q0-3.281-2.344-5.625t-5.672-2.344-5.672 2.344-2.344 5.625q0 2.203 1.078 4.031t2.906 2.906l-.984 1.734q-2.297-1.313-3.633-3.633t-1.336-5.039q0-4.172 2.93-7.078t7.055-2.906zm6 9.984q0 1.641-.82 3.023T15 18.187l-.984-1.734q1.969-1.172 1.969-3.469 0-1.641-1.172-2.813T12 8.999t-2.813 1.172-1.172 2.813q0 2.297 1.969 3.469L9 18.187q-1.359-.797-2.18-2.18T6 12.984Q6 10.5 7.758 8.742T12 6.984t4.242 1.758T18 12.984zm-6-1.968q.797 0 1.406.586t.609 1.383-.609 1.406T12 15t-1.406-.609-.609-1.406.609-1.383T12 11.016z"/>'
  }
})
