/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'foundation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 12h3L12 3l-9.984 9h3v3H3v2.016h2.016v3h1.969v-3h4.031v3h1.969v-3h4.031v3h1.969v-3h2.016V15h-2.016v-3zm-12 3v-4.828l4.031-3.563V15H6.984zm6 0V6.609l4.031 3.563V15h-4.031z"/>'
  }
})
