/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wb_incandescent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.25 18.141l1.406-1.359 1.781 1.781-1.406 1.406zm2.766-7.641h3v2.016h-3V10.5zM15 6.328q1.359.797 2.18 2.156t.82 3q0 2.484-1.758 4.242T12 17.484t-4.242-1.758T6 11.484q0-1.641.82-3T9 6.328V1.5h6v4.828zM3.984 10.5v2.016h-3V10.5h3zm7.032 11.953V19.5h1.969v2.953h-1.969zm-7.453-3.89l1.781-1.828 1.406 1.406-1.781 1.828z"/>'
  }
})
