/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'south': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 15l-1.406-1.406-4.594 4.594V2.016h-1.969v16.172l-4.594-4.594L5.015 15l6.984 6.984z"/>'
  }
})
