/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus_one': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.484 6.094l4.5-1.078V18h-1.969V7.406l-2.531.516V6.094zm-4.5 1.922V12h4.031v2.016H9.984V18H8.015v-3.984H3.984V12h4.031V8.016h1.969z"/>'
  }
})
