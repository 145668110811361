/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flaky': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.063 17.578l-2.438-2.391 1.078-1.078 1.359 1.359 2.484-2.484 1.031 1.078-3.516 3.516zM12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zM7.359 6.281l1.406 1.406 1.406-1.406 1.031 1.078-1.406 1.406 1.406 1.406-1.031 1.031-1.406-1.406-1.406 1.406-1.078-1.031 1.406-1.406-1.406-1.406zM12 20.016q-1.641 0-3.117-.633t-2.602-1.664L17.719 6.281q1.031 1.125 1.664 2.602T20.016 12t-.633 3.094-1.734 2.555-2.555 1.734-3.094.633z"/>'
  }
})
