/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'touch_app': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.844 15.891q.891.422.891 1.359v.188l-.75 5.297q-.047.563-.469.914t-.984.352h-6.797q-.609 0-1.031-.422L4.735 18.61l.797-.797q.328-.328.797-.328.047 0 .117.023t.117.023l3.422.703V7.5q0-.656.445-1.078T11.485 6t1.055.422.445 1.078v6h.797q.141 0 .516.094zM9 11.25Q6.984 9.937 6.984 7.5q0-1.875 1.313-3.188t3.188-1.313 3.188 1.313T15.986 7.5q0 2.484-1.969 3.75V7.5q0-1.031-.75-1.758t-1.781-.727-1.758.727T9.001 7.5v3.75z"/>'
  }
})
