/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'http': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.516 11.484V10.5H19.5v.984h2.016zm0-2.484q.609 0 1.055.445t.445 1.055v.984q0 .609-.445 1.055t-1.055.445H19.5V15H18V9h3.516zm-9 1.5V9h4.5v1.5h-1.5V15h-1.5v-4.5h-1.5zm-5.532 0V9h4.5v1.5h-1.5V15h-1.5v-4.5h-1.5zm-2.484.516V9H6v6H4.5v-2.484H2.484V15h-1.5V9h1.5v2.016H4.5z"/>'
  }
})
