/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping_bag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 6h-2.016q0-1.125-.539-2.016t-1.43-1.43-2.016-.539-2.016.539-1.43 1.43T8.014 6H5.998q-.844 0-1.43.586t-.586 1.43v12q0 .797.586 1.383t1.43.586h12q.844 0 1.43-.586t.586-1.383v-12q0-.844-.586-1.43T17.998 6zM9.984 9.984q0 .422-.281.727T9 11.016t-.703-.305-.281-.727V8.015h1.969v1.969zm2.016-6q.844 0 1.43.586T14.016 6H9.985q0-.844.586-1.43t1.43-.586zm3.984 6q0 .422-.281.727t-.703.305-.703-.305-.281-.727V8.015h1.969v1.969z"/>'
  }
})
