/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brightness_low': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18q2.484 0 4.242-1.758T18 12t-1.758-4.242T12 6 7.758 7.758 6 12t1.758 4.242T12 18zm8.016-2.672v4.688h-4.688L12 23.297l-3.328-3.281H3.984v-4.688L.703 12l3.281-3.328V3.984h4.688L12 .703l3.328 3.281h4.688v4.688L23.297 12z"/>'
  }
})
