/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_restaurant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.859 11.531l-1.453 1.453 6.891 6.891-1.406 1.406L12 14.39l-6.891 6.891-1.406-1.406 9.75-9.75Q12.89 9 13.289 7.5t1.57-2.672q1.453-1.453 3.234-1.664t2.859.867.867 2.883-1.664 3.258q-1.172 1.172-2.672 1.547t-2.625-.188zm-6.75 1.828L3.89 9.14Q2.718 7.968 2.718 6.327T3.89 3.514l7.031 6.984z"/>'
  }
})
