/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'space_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9h2.016v6H3.985V9h2.016v3.984h12V9z"/>'
  }
})
