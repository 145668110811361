/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label_important_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.516 17.016H15L18.516 12 15 6.984H6.516L9.985 12zM15 18.984H3L7.5 12 3 5.016h12q1.031 0 1.641.844L21 12.001l-4.359 6.141q-.609.844-1.641.844z"/>'
  }
})
