/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.047 11.25q.938-.938.938-2.25 0-1.641-1.172-2.813T12 5.015 9.187 6.187 8.015 9h1.969q0-.797.609-1.406t1.406-.609 1.406.609T14.014 9t-.609 1.406l-1.219 1.266q-1.172 1.266-1.172 2.813v.516h1.969q0-1.547 1.172-2.813zm-2.063 7.734v-1.969h-1.969v1.969h1.969zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
