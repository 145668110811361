/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invert_colors_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 19.594V5.11L7.781 9.329Q6 11.11 6 13.595q0 2.438 1.781 4.219T12 19.595zm5.672-11.672q2.344 2.344 2.344 5.648t-2.344 5.648T12 21.562q-1.359 0-3.023-.68t-2.648-1.664q-2.344-2.344-2.344-5.648t2.344-5.648l5.672-5.672z"/>'
  }
})
