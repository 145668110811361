/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 8.016V3.985h-4.031v4.031h4.031zm0 6V9.985h-4.031v4.031h4.031zm0 6v-4.031h-4.031v4.031h4.031zm-6-12V3.985H9.985v4.031h4.031zm0 6V9.985H9.985v4.031h4.031zm0 6v-4.031H9.985v4.031h4.031zm-6-12V3.985H3.985v4.031h4.031zm0 6V9.985H3.985v4.031h4.031zm0 6v-4.031H3.985v4.031h4.031zm12-18q.797 0 1.383.586t.586 1.383v16.031q0 .797-.586 1.383t-1.383.586H3.985q-.797 0-1.383-.586t-.586-1.383V3.985q0-.797.586-1.383t1.383-.586h16.031z"/>'
  }
})
