/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fiber_manual_record': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 12q0-3.281 2.367-5.648t5.648-2.367 5.648 2.367T20.014 12t-2.367 5.648-5.648 2.367-5.648-2.367T3.984 12z"/>'
  }
})
