/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'equalizer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9h4.031v11.016h-4.031V9zm-12 11.016V12h4.031v8.016H3.984zm6 0V3.985h4.031v16.031H9.984z"/>'
  }
})
