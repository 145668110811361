/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_size': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 12V9h9v3H9v6.984H6V12H3zm6-8.016h12.984v3h-4.969v12h-3v-12H8.999v-3z"/>'
  }
})
