/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'launch': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 3H21v6.984h-2.016V6.421l-9.797 9.797-1.406-1.406 9.797-9.797h-3.563V2.999zm4.968 15.984V12H21v6.984q0 .797-.609 1.406t-1.406.609H5.016q-.844 0-1.43-.586T3 18.983V5.014q0-.844.586-1.43t1.43-.586H12v2.016H5.016v13.969h13.969z"/>'
  }
})
