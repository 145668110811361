/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 9V5.016H5.016V9H15zm-3 9.984q1.219 0 2.109-.891T15 15.984t-.891-2.109T12 12.984t-2.109.891T9 15.984t.891 2.109 2.109.891zM17.016 3L21 6.984v12q0 .797-.609 1.406t-1.406.609H5.016q-.844 0-1.43-.586T3 18.983V5.014q0-.844.586-1.43t1.43-.586h12z"/>'
  }
})
