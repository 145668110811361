/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'multiple_stop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 3.984L21 8.015l-3.984 3.984v-3h-4.031V6.983h4.031v-3zm-7.032 3q-.375 0-.68.305t-.305.727q0 .375.305.68t.68.305q.422 0 .727-.305t.305-.68q0-.422-.305-.727t-.727-.305zM6 6.984q-.422 0-.703.305t-.281.727q0 .375.281.68T6 9.001t.703-.305.281-.68q0-.422-.281-.727T6 6.984zm.984 10.032h4.031V15H6.984v-3L3 15.984l3.984 4.031v-3zm7.032 0q.375 0 .68-.305t.305-.727q0-.375-.305-.68t-.68-.305q-.422 0-.727.305t-.305.68q0 .422.305.727t.727.305zm3.984 0q.422 0 .703-.305t.281-.727q0-.375-.281-.68T18 14.999t-.703.305-.281.68q0 .422.281.727t.703.305z"/>'
  }
})
