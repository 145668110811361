/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'taxi_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 8.016q0-1.641-.703-3.07t-1.945-2.414-2.836-1.359q-1.594-.328-3.141.023t-2.766 1.336-1.922 2.484H6.984v1.969H4.5q-.469 0-.867.281t-.539.75l-2.109 6v7.969q0 .422.305.727t.727.305h.984q.422 0 .703-.305t.281-.727v-.984h12v.984q0 .422.305.727t.727.305h.984q.422 0 .703-.305t.281-.727v-7.688q1.828-.844 2.93-2.555t1.102-3.727zM4.5 8.484h4.547q.141 2.672 2.063 4.5H3.001zM4.5 18q-.609 0-1.055-.445T3 16.5t.445-1.055T4.5 15t1.055.445T6 16.5t-.445 1.055T4.5 18zm11.016 0q-.656 0-1.078-.445t-.422-1.055.422-1.055T15.516 15q.609 0 1.055.445t.445 1.055-.445 1.055-1.055.445zm2.906-5.625l-.375.188q-.234.094-.422.188l-.469.094-.188.047q-2.156.469-3.938-.891-1.781-1.313-2.016-3.516v-.938l.047-.188v-.281l.047-.188.047-.234.094-.234.047-.141q.422-1.125 1.266-1.922t1.969-1.148 2.344-.164q1.172.234 2.109.938t1.477 1.758T21 7.97q0 1.406-.68 2.578t-1.898 1.828zM15 3.984h2.016V9H15V3.984zm0 6h2.016V12H15V9.984z"/>'
  }
})
