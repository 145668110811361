/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book_online': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.359 20.016l.563 1.969H5.016q-.844 0-1.43-.586T3 20.016V6q0-.844.586-1.43t1.43-.586H6V2.015h2.016v1.969h7.969V2.015h2.016v1.969h.984q.844 0 1.43.586T21.001 6v8.906l-2.016-.563V9.984H5.016v10.031h8.344zm8.344 1.265l-3.234-3.234L21 17.016 14.016 15l1.969 6.984 1.078-2.484 3.234 3.188zM12 17.016V12H6.984v5.016H12z"/>'
  }
})
