/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_format': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6l-1.875 5.016h3.75zm-2.484 6.797L8.578 15H6.515l4.734-11.016h1.5L17.483 15H15.42l-.938-2.203H9.513zm-4.5 4.219h13.969v1.969H5.016v-1.969z"/>'
  }
})
