/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'support': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zm7.453 7.125l-2.766 1.125q-.375-1.031-1.148-1.805t-1.805-1.148l1.125-2.766q1.594.609 2.789 1.805t1.805 2.789zM12 15q-.844 0-1.523-.398t-1.078-1.078-.398-1.523.398-1.523T10.477 9.4 12 9.002t1.523.398 1.078 1.078.398 1.523-.398 1.523-1.078 1.078T12 15zM9.141 4.547l1.172 2.766q-1.031.375-1.828 1.148t-1.172 1.852L4.547 9.141q.609-1.594 1.805-2.789t2.789-1.805zM4.547 14.859l2.766-1.125q.375 1.031 1.148 1.805t1.852 1.148l-1.172 2.766q-1.594-.609-2.789-1.805t-1.805-2.789zm10.312 4.594l-1.125-2.766q1.031-.375 1.805-1.148t1.148-1.852l2.766 1.172q-.609 1.594-1.805 2.789t-2.789 1.805z"/>'
  }
})
