/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_in_ar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 21.984v-1.969h3v-3h1.969v3.469q0 .609-.445 1.055t-1.055.445h-3.469zm-10.032 0H3.515q-.609 0-1.055-.445t-.445-1.055v-3.469h1.969v3h3v1.969zM17.016 2.016h3.469q.609 0 1.055.445t.445 1.055v3.469h-1.969v-3h-3V2.016zm-10.032 0v1.969h-3v3H2.015V3.516q0-.609.445-1.055t1.055-.445h3.469zm6 15.234l4.031-2.297v-4.594l-4.031 2.297v4.594zM12 10.922l3.984-2.297L12 6.281 8.016 8.625zm-5.016 4.031l4.031 2.297v-4.594l-4.031-2.297v4.594zm11.25-7.359q.75.469.75 1.313v6.328q0 .844-.75 1.313l-5.484 3.188q-.75.469-1.5 0l-5.484-3.188q-.75-.469-.75-1.313V8.907q0-.844.75-1.313l5.484-3.188q.375-.188.75-.188t.75.188z"/>'
  }
})
