/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2.016l7.5 18.281-.703.703L12 18l-6.797 3-.703-.703z"/>'
  }
})
