/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_display': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9v6q1.219 0 2.109-.891T15 12t-.891-2.109T12 9zm-3.984 6.984V13.5l-1.5-1.5 1.5-1.5V8.016H10.5l1.5-1.5 1.5 1.5h2.484V10.5l1.5 1.5-1.5 1.5v2.484H13.5l-1.5 1.5-1.5-1.5H8.016zM21 19.031V4.968H3v14.063h18zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18z"/>'
  }
})
