/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_cafe': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.984 18.984h16.031V21H3.984v-2.016zM20.016 8.016v-3H18v3h2.016zm0-5.016q.844 0 1.406.586t.563 1.43v3q0 .844-.563 1.406t-1.406.563H18v3q0 1.641-1.172 2.836t-2.813 1.195h-6q-1.641 0-2.836-1.195t-1.195-2.836V3.001h16.031z"/>'
  }
})
