/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'build': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.688 18.984q.328.188.305.633t-.398.773l-2.297 2.297q-.703.703-1.406 0l-9.094-9.094q-1.688.703-3.586.305t-3.305-1.805q-1.5-1.5-1.875-3.563t.563-3.844l4.406 4.313 3-3-4.313-4.313Q6.469.842 8.532 1.17t3.563 1.828q1.406 1.406 1.805 3.305t-.305 3.586z"/>'
  }
})
