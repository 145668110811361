/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'post_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15H15v2.016H6.984V15zm0-3H15v2.016H6.984V12zm0-3H15v2.016H6.984V9zm12-6.984v3h3v1.969h-3v3h-1.969v-3h-3V5.016h3v-3h1.969zm-1.968 17.203V12h1.969v6.984q0 .797-.586 1.406t-1.383.609h-12q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586H12v1.969H5.016v12.234h12z"/>'
  }
})
