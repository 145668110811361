/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tonality': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.734 14.016q.094-.281.188-1.031h-6.938v1.031h6.75zm-1.5 3q.563-.797.703-1.031h-5.953v1.031h5.25zm-5.25 2.906q1.5-.188 2.906-.938h-2.906v.938zm0-9.938v1.031h6.938q-.094-.75-.188-1.031h-6.75zm0-3v1.031h5.953q-.141-.234-.703-1.031h-5.25zm0-2.906v.938h2.906q-1.406-.75-2.906-.938zm-1.968 15.844V4.078q-2.953.375-4.992 2.625T3.985 12t2.039 5.297 4.992 2.625zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
