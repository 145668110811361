/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alternate_email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15q1.219 0 2.109-.891T15 12t-.891-2.109T12 9t-2.109.891T9 12t.891 2.109T12 15zm0-12.984q4.125 0 7.055 2.93t2.93 7.055v1.453q0 1.5-1.008 2.531t-2.461 1.031q-1.828 0-2.953-1.5-1.5 1.5-3.563 1.5t-3.539-1.477T6.984 12t1.477-3.539T12 6.984t3.539 1.477T17.016 12v1.453q0 .609.445 1.078t1.055.469 1.055-.469.445-1.078V12q0-3.281-2.367-5.648t-5.648-2.367-5.648 2.367T3.986 12t2.367 5.648 5.648 2.367h5.016v1.969h-5.016q-4.125 0-7.055-2.93t-2.93-7.055 2.93-7.055 7.055-2.93z"/>'
  }
})
