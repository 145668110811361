/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'undo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.516 8.016q3.422 0 6.141 2.016t3.797 5.203l-2.344.75q-.797-2.438-2.883-3.961t-4.711-1.523q-2.906 0-5.156 1.875l3.656 3.609h-9v-9l3.563 3.609q2.953-2.578 6.938-2.578z"/>'
  }
})
