/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove_moderator': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.688 16.594L6.516 3.422 12 .984l9 4.031v6q0 2.859-1.313 5.578zm2.578 5.156L21 23.016l-3.422-3.422Q15.14 22.266 12 23.016q-3.844-.938-6.422-4.383T3 11.016v-6L.984 3 2.25 1.734l5.203 5.203 6.094 6.07 5.203 5.18z"/>'
  }
})
