/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 9H21v2.016h-2.016V9zm1.032 6.516q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.188.609-.234 1.031l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563zm-3-6.516v2.016H15V9h2.016zm-4.032 0v2.016h-1.969V9h1.969z"/>'
  }
})
