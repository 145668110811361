/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_line_spacing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 12.984v-1.969h12v1.969h-12zm0 6v-1.969h12v1.969h-12zm0-13.968h12v1.969h-12V5.016zM6 6.984v10.031h2.484l-3.469 3.469-3.516-3.469h2.484V6.984H1.499l3.516-3.469 3.469 3.469H6z"/>'
  }
})
