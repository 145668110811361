/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'assignment_late': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.016q.422 0 .703-.305t.281-.727-.281-.703T12 3t-.703.281-.281.703.281.727.703.305zm.984 9v-6h-1.969v6h1.969zm0 3.984v-2.016h-1.969V18h1.969zm6-15q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h4.172q.328-.891 1.078-1.453t1.734-.563 1.734.563T14.811 3h4.172z"/>'
  }
})
