/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_atm': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 18V6H3.985v12h16.031zm0-14.016q.844 0 1.406.586T21.985 6v12q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586T2.016 18V6q0-.844.563-1.43t1.406-.586h16.031zm-9 13.032v-1.031H9v-1.969h3.984v-1.031h-3q-.422 0-.703-.281T9 12.001v-3q0-.422.281-.703t.703-.281h1.031V6.986h1.969v1.031H15v1.969h-3.984v1.031h3q.422 0 .703.281t.281.703v3q0 .422-.281.703t-.703.281h-1.031v1.031h-1.969z"/>'
  }
})
