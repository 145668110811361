/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label_important': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.516 18.984L8.344 12 3.516 5.016h10.969q1.031 0 1.641.844l4.359 6.141-4.359 6.141q-.609.844-1.641.844H3.516z"/>'
  }
})
