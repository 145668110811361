/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trip_origin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 18q2.484 0 4.242-1.758T18 12t-1.758-4.242T12 6 7.758 7.758 6 12t1.758 4.242T12 18zm-9.984-6q0-4.125 2.93-7.055t7.055-2.93 7.055 2.93T21.986 12t-2.93 7.055-7.055 2.93-7.055-2.93T2.016 12z"/>'
  }
})
