/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit_attributes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.219 14.438l4.219-4.219-.656-.656-3.563 3.516-1.828-1.875-.703.703zm10.406-7.454q1.781 0 3.07 1.477T21.984 12t-1.289 3.539-3.07 1.477H6.375q-1.781 0-3.07-1.477T2.016 12t1.289-3.539 3.07-1.477h11.25z"/>'
  }
})
