/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_iphone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 18V3.984h-9V18h9zm-4.5 3.984q.609 0 1.055-.445t.445-1.055-.445-1.055-1.055-.445-1.055.445-.445 1.055.445 1.055 1.055.445zm4.032-21q1.031 0 1.758.75t.727 1.781v16.969q0 1.031-.727 1.781t-1.758.75H7.5q-1.031 0-1.758-.75t-.727-1.781V3.515q0-1.031.727-1.781T7.5.984h8.016z"/>'
  }
})
