/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'replay_5': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.813 13.594q-.328.141-.328.188l-.094.141h-.703l.234-2.203h2.391v.703h-1.734l-.094.891q.094 0 .094-.094 0-.047.07-.07t.07-.07h.375q.375 0 .516.141.047.047.188.141t.188.141q.422.422.422 1.078 0 .422-.094.516-.047.047-.141.234t-.188.281-.211.164-.164.117q-.094.094-.609.094-.422 0-.516-.094-.047-.047-.211-.094t-.258-.094q-.422-.234-.422-.891h.797q0 .469.609.469.188 0 .281-.094l.234-.188q.094-.188.094-.281v-.609l-.094-.188-.234-.234q-.188-.094-.281-.094h-.188zM12 5.016q3.328 0 5.672 2.344t2.344 5.625q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672h2.016q0 2.484 1.781 4.242t4.219 1.758 4.219-1.758 1.781-4.242-1.781-4.242-4.219-1.758v4.031L6.986 6 12.002.984v4.031z"/>'
  }
})
