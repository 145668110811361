/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'screen_lock_rotation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.781 2.484V3h3.422v-.516q0-.703-.492-1.195T18.516.797t-1.219.492-.516 1.195zM15.984 9q-.422 0-.703-.281T15 8.016V3.985q0-.422.281-.703t.703-.281v-.516q0-1.031.75-1.758T18.515 0t1.758.727T21 2.485v.516q.422 0 .703.281t.281.703v4.031q0 .422-.281.703T21 9h-5.016zm-7.5 11.484l1.313-1.313 3.797 3.797-.656.047q-4.688 0-8.133-3.188t-3.82-7.828h1.5q.234 2.484 1.992 4.969t4.008 3.516zM23.25 12.75q.469.422.469 1.055t-.469 1.102l-6.375 6.328q-.422.469-1.031.469t-1.078-.469l-12-12q-.469-.469-.469-1.078 0-.563.469-1.031L9.094.751q.422-.422 1.078-.422t1.078.422l2.438 2.438-1.406 1.406-2.109-2.063-5.672 5.625 11.344 11.344 5.625-5.672-2.203-2.203 1.406-1.406z"/>'
  }
})
