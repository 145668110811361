/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone_locked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.219 3.984v-.469q0-.703-.516-1.219t-1.219-.516-1.195.516-.492 1.219v.469h3.422zm.797 0q.422 0 .703.305t.281.727V9q0 .422-.281.703t-.703.281H15q-.422 0-.703-.281T14.016 9V5.016q0-.422.281-.727T15 3.984v-.469q0-1.031.727-1.781t1.758-.75 1.781.75.75 1.781v.469zm0 11.532q.422 0 .703.281T21 16.5v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3.001 3.985q0-.422.281-.703t.703-.281h3.516q.422 0 .703.281t.281.703v.047q0 1.922.563 3.516.094.188.094.328 0 .469-.328.703l-2.203 2.203q.938 1.781 2.883 3.727t3.727 2.883l2.203-2.203q.328-.328.703-.328.141 0 .328.094 1.688.563 3.563.563z"/>'
  }
})
