/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swipe': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.109 3.891l1.875-1.875v4.969h-4.969l2.063-2.063q-.797-1.031-2.719-1.734T12 2.485t-4.359.703-2.719 1.734l2.063 2.063H2.016V2.016l1.875 1.875q1.125-1.313 3.305-2.109t4.805-.797 4.805.797 3.305 2.109zm-.375 12.375v.188l-.75 5.25q-.094.563-.492.938t-.961.375h-6.797q-.563 0-1.031-.469l-4.969-4.922.797-.797q.328-.328.797-.328h.234l3.422.75V6.517q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055v6h.797q.141 0 .516.094l4.547 2.25q.891.422.891 1.406z"/>'
  }
})
