/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'create': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828Q17.25 3 17.672 3t.703.281l2.344 2.344q.281.281.281.703t-.281.703zM3 17.25L14.063 6.187l3.75 3.75L6.75 21H3v-3.75z"/>'
  }
})
