/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spa': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.469 9.609Q13.5 10.687 12 12.281q-1.5-1.594-3.469-2.672.141-1.828 1.172-4.055t2.344-3.539q3.141 3.141 3.422 7.594zm-13.453.375q3.188 0 5.813 1.547t4.172 3.938q1.547-2.391 4.172-3.938t5.813-1.547q0 3.938-2.227 7.078t-5.789 4.406q-.797.281-1.969.516-.984-.141-1.969-.516-3.563-1.266-5.789-4.406T2.016 9.984z"/>'
  }
})
