/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby_changing_station': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 8.016v1.969h-3L8.297 8.813l-1.313 3.938v9.234H3v-9.984L4.594 7.36q.281-.844 1.078-1.195t1.641.023l4.125 1.828h2.578zm-6-7.032q-.844 0-1.43.586T6 3t.586 1.43 1.43.586q.797 0 1.383-.586T9.985 3t-.586-1.43T8.016.984zm.984 18h12v-1.969H9v1.969zm10.5-3q.609 0 1.055-.422T21 14.484q0-.609-.445-1.055t-1.055-.445-1.055.445T18 14.484q0 .656.445 1.078t1.055.422zM12.984 12q0-.422-.281-.703T12 11.016H9v1.969h2.016v1.031q0 .797.586 1.383t1.383.586h2.016q.844 0 1.43-.586t.586-1.383v-3h-2.016v1.969h-2.016v-.984z"/>'
  }
})
