/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blur_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 13.5q.516 0 .516.516 0 .469-.516.469t-.516-.469q0-.516.516-.516zm3 3.516q.422 0 .703.281t.281.703-.281.703-.703.281-.703-.281T5.016 18t.281-.703.703-.281zm3.984 3.468q.516 0 .516.516t-.516.516q-.469 0-.469-.516t.469-.516zM3 9.516q.516 0 .516.469 0 .516-.516.516t-.516-.516q0-.469.516-.469zm3 3.468q.422 0 .703.305t.281.727-.281.703T6 15t-.703-.281-.281-.703.281-.727.703-.305zm15 .516q.516 0 .516.516 0 .469-.516.469t-.516-.469q0-.516.516-.516zM9.984 17.016q.422 0 .727.281t.305.703-.305.703-.727.281-.703-.281T9 18t.281-.703.703-.281zM2.484 5.25L3.75 3.984 20.016 20.25l-1.313 1.266-3.75-3.797Q15 17.813 15 18q0 .422-.281.703t-.703.281-.727-.281-.305-.703.305-.703.727-.281q.188 0 .281.047l-2.813-2.813q-.094.516-.516.891t-.984.375q-.609 0-1.055-.445t-.445-1.055q0-.563.375-.984t.891-.516L6.937 9.703q.047.094.047.281 0 .422-.281.727T6 11.016t-.703-.305-.281-.727.281-.703T6 9l.281.047zm11.532 15.234q.469 0 .469.516t-.469.516q-.516 0-.516-.516t.516-.516zM18 6.984q-.422 0-.703-.281T17.016 6t.281-.703.703-.281.703.281.281.703-.281.703-.703.281zm0 4.032q-.422 0-.703-.305t-.281-.727.281-.703T18 9t.703.281.281.703-.281.727-.703.305zM18 15q-.422 0-.703-.281t-.281-.703.281-.727.703-.305.703.305.281.727-.281.703T18 15zM9.984 6.984q-.422 0-.703-.281T9 6t.281-.703.703-.281.727.281.305.703-.305.703-.727.281zM21 10.5q-.516 0-.516-.516 0-.469.516-.469t.516.469q0 .516-.516.516zM9.984 3.516q-.469 0-.469-.516t.469-.516q.516 0 .516.516t-.516.516zm4.032 0q-.516 0-.516-.516t.516-.516q.469 0 .469.516t-.469.516zm-.235 7.968q-.469-.047-.844-.422t-.422-.844v-.234q0-.609.445-1.055t1.055-.445 1.055.445.445 1.055-.445 1.055-1.055.445h-.234zm.235-4.5q-.422 0-.727-.281T12.984 6t.305-.703.727-.281.703.281T15 6t-.281.703-.703.281z"/>'
  }
})
