/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hdr_on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 13.5v-3h-1.969v3h1.969zm0-4.5q.609 0 1.055.445t.445 1.055v3q0 .609-.445 1.055T12.984 15H9.515V9h3.469zm-6.468 2.016V9h1.5v6h-1.5v-2.484H4.5V15H3V9h1.5v2.016h2.016zm12.984.468V10.5h-2.016v.984H19.5zm1.5 0q0 .938-.891 1.406L21 14.999h-1.5l-.891-2.016h-1.125v2.016h-1.5v-6H19.5q.609 0 1.055.445T21 10.499v.984z"/>'
  }
})
