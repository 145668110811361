/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tty': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 3.984h1.969V6h-1.969V3.984zm-1.032 3H15V9h-2.016V6.984zm-1.968-3h1.969V6h-1.969V3.984zM18 9h-2.016V6.984H18V9zm.984-3h-1.969V3.984h1.969V6zM21 9h-2.016V6.984H21V9zm.984-3h-1.969V3.984h1.969V6zm-7.359 8.391l-2.531 2.531q-3.797-2.203-6-6l2.531-2.531Q9 8.016 8.906 7.5l-.75-3.703q-.047-.328-.328-.563T7.172 3H3.047q-.422 0-.727.305t-.305.727q.141 2.156.75 4.172t1.688 3.797q1.172 2.063 2.836 3.727t3.727 2.836q1.781 1.031 3.773 1.664t4.195.727q.422.047.727-.258t.305-.727v-4.172q0-.328-.234-.609t-.563-.328l-3.703-.75q-.516-.094-.891.281zm-.609-4.407h1.969V12h-1.969V9.984zm-3 0h1.969V12h-1.969V9.984zM18.984 12h-1.969V9.984h1.969V12zm3 0h-1.969V9.984h1.969V12z"/>'
  }
})
