/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scanner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 17.016V15H9v2.016h9.984zm-12 0V15H5.015v2.016h1.969zm12.797-6.328q.516.141.867.68T21 12.516V18q0 .797-.609 1.406t-1.406.609H5.016q-.797 0-1.406-.609T3.001 18v-3.984q0-.797.609-1.406t1.406-.609h12.563L3.516 6.892l.703-1.875z"/>'
  }
})
