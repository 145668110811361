/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rule': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.547 11.016l-3.563-3.563 1.406-1.406 2.156 2.109 4.219-4.219 1.406 1.406zm-5.531-4.032h-9V9h9V6.984zM21 13.406L19.594 12l-2.578 2.578L14.391 12l-1.406 1.406 2.625 2.578-2.625 2.625 1.406 1.406 2.625-2.625 2.578 2.625L21 18.609l-2.578-2.625zM11.016 15h-9v2.016h9V15z"/>'
  }
})
