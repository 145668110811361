/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car_rental': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 5.016q.422 0 .703-.305T9 3.984t-.281-.703T8.016 3t-.727.281-.305.703.305.727.727.305zM10.828 3h8.156v2.016H18v1.969h-2.016V5.016h-5.156q-.703 1.969-2.813 1.969-1.219 0-2.109-.891t-.891-2.109.891-2.109T8.015.985q.891 0 1.711.586t1.102 1.43zM6.281 14.016h11.438l-1.313-4.031H7.64zM16.219 18q.516 0 .867-.352t.352-.867-.352-.867-.867-.352-.867.352-.352.867.352.867.867.352zm-8.438 0q.516 0 .867-.352T9 16.781t-.352-.867-.867-.352-.867.352-.352.867.352.867.867.352zm8.625-9q.656 0 .938.703l1.641 4.969v6.844q0 .469-.469.469h-1.031q-.469 0-.469-.469v-1.5H6.985v1.5q0 .469-.469.469H5.485q-.469 0-.469-.516v-6.797l1.641-4.969Q6.891 9 7.595 9h8.813z"/>'
  }
})
