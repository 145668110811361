/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_car_wash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 12.984h13.969l-1.5-4.5H6.516zM17.484 18q.609 0 1.055-.422t.445-1.078-.445-1.078T17.484 15t-1.055.422-.445 1.078.445 1.078 1.055.422zM6.516 18q.609 0 1.055-.422t.445-1.078-.445-1.078T6.516 15t-1.055.422-.445 1.078.445 1.078T6.516 18zm12.422-9.984l2.063 6v7.969q0 .422-.281.727t-.703.305h-1.031q-.422 0-.703-.305t-.281-.727v-.984h-12v.984q0 .422-.281.727t-.703.305H3.987q-.422 0-.703-.305t-.281-.727v-7.969l2.063-6q.328-1.031 1.453-1.031h10.969q1.125 0 1.453 1.031zm-11.954-3q-.609 0-1.055-.445t-.445-1.055q0-.422.375-1.102t.75-1.148l.375-.469q1.5 1.734 1.5 2.719 0 .609-.445 1.055t-1.055.445zm5.016 0q-.656 0-1.078-.445T10.5 3.516q0-.422.375-1.102t.75-1.148L12 .797q1.5 1.734 1.5 2.719 0 .609-.422 1.055T12 5.016zm5.016 0q-.609 0-1.055-.445t-.445-1.055q0-.422.375-1.102t.75-1.148l.375-.469q1.5 1.734 1.5 2.719 0 .609-.445 1.055t-1.055.445z"/>'
  }
})
