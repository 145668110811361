/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icecream': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.844 12.984q-1.172-.094-2.016-.961t-.844-2.039q0-1.078.727-1.922t1.805-1.031q.188-2.109 1.758-3.563t3.727-1.453 3.727 1.453 1.758 3.563q1.078.188 1.805 1.031t.727 1.922q0 1.172-.82 2.039t-1.992.961L12.05 23.015zm1.969-.562l3.234 6.188 3.188-6.188q-.047-.047-.164-.141t-.164-.141q-1.359.844-2.906.844t-2.906-.844q-.047.047-.094.117t-.094.117-.094.047z"/>'
  }
})
