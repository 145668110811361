/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder_shared': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 17.016v-1.031q0-.891-1.383-1.43t-2.602-.539-2.602.539-1.383 1.43v1.031h7.969zM15 9q-.797 0-1.406.609t-.609 1.406.609 1.383 1.406.586 1.406-.586.609-1.383-.609-1.406T15 9zm5.016-3q.797 0 1.383.609t.586 1.406v9.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.406t1.383-.609h6L12.001 6h8.016z"/>'
  }
})
