/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attach_money': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.813 10.922q1.031.281 1.711.563t1.43.773 1.148 1.242.398 1.781q0 1.453-.961 2.367t-2.555 1.195v2.156h-3v-2.156q-1.547-.328-2.555-1.313t-1.102-2.531H8.53q.188 2.109 2.953 2.109 1.453 0 2.086-.539t.633-1.242q0-1.688-3-2.438-4.688-1.078-4.688-4.125 0-1.359.984-2.32t2.484-1.289V2.999h3v2.203q1.547.375 2.367 1.406t.867 2.391h-2.203q-.094-2.109-2.531-2.109-1.219 0-1.945.516T8.81 8.765q0 1.359 3 2.156z"/>'
  }
})
