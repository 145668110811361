/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.797 12.984h4.219v-1.969H6.797q-.281-.891-1.055-1.453t-1.758-.563q-.797 0-1.477.398t-1.102 1.078-.422 1.523.422 1.523 1.102 1.078 1.477.398q.984 0 1.758-.563t1.055-1.453zm-2.813 0q-.375 0-.68-.281T2.999 12t.305-.703.68-.281q.422 0 .727.281t.305.703-.305.703-.727.281zM23.016 12l-4.031-3v2.016h-4.031q-.188-1.922-1.031-3.563t-2.18-2.859-3.07-1.898-3.656-.68v1.969q1.641 0 3.094.633t2.555 1.734 1.711 2.555.609 3.094-.609 3.094-1.711 2.555-2.555 1.734-3.094.633v1.969q1.922 0 3.656-.68t3.07-1.898 2.18-2.859 1.031-3.563h4.031v2.016z"/>'
  }
})
