/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_bottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 15v2.016H3V15h2.016zM3 21v-2.016h18V21H3zm2.016-9.984v1.969H3v-1.969h2.016zM18.984 9V6.984H21V9h-2.016zm0-6H21v2.016h-2.016V3zM5.016 6.984V9H3V6.984h2.016zm13.968 10.032V15H21v2.016h-2.016zm0-4.032v-1.969H21v1.969h-2.016zM17.016 3v2.016H15V3h2.016zm-4.032 0v2.016h-1.969V3h1.969zm4.032 8.016v1.969H15v-1.969h2.016zm-4.032-4.032V9h-1.969V6.984h1.969zM5.016 3v2.016H3V3h2.016zm7.968 8.016v1.969h-1.969v-1.969h1.969zM9 3v2.016H6.984V3H9zm3.984 12v2.016h-1.969V15h1.969zM9 11.016v1.969H6.984v-1.969H9z"/>'
  }
})
