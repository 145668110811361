/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.016 9H9l5.016-5.016v16.031L9 14.999H5.016v-6zm13.5 3q0 2.766-2.531 4.031V7.968q1.031.516 1.781 1.711t.75 2.32z"/>'
  }
})
