/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card_travel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.016 14.016v-6h-3v1.969H15V8.016H9v1.969H6.984V8.016h-3v6h16.031zm0 4.968v-1.969H3.985v1.969h16.031zM9 3.984V6h6V3.984H9zM20.016 6q.844 0 1.406.586t.563 1.43v10.969q0 .844-.563 1.43t-1.406.586H3.985q-.844 0-1.406-.586t-.563-1.43V8.016q0-.844.563-1.43T3.985 6h3V3.984q0-.844.586-1.406t1.43-.563h6q.844 0 1.43.563t.586 1.406V6h3z"/>'
  }
})
