/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 3H21v6h-8.016V3zm0 18v-9.984H21V21h-8.016zM3 21v-6h8.016v6H3zm0-8.016V3h8.016v9.984H3z"/>'
  }
})
