/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 16.172L19.594 5.578 21 6.984l-12 12-5.578-5.578L4.828 12z"/>'
  }
})
