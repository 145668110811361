/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop_3_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 18V6H5.015v12h13.969zm0-14.016q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.406t-1.406.609H5.015q-.797 0-1.406-.609T3 17.999v-12q0-.797.609-1.406t1.406-.609h13.969z"/>'
  }
})
