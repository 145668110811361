/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'miscellaneous_services': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.156 13.688l1.406-2.391q.141-.281-.094-.469l-1.453-1.125q.047-.375.047-.703t-.047-.703l1.453-1.125q.234-.234.094-.469l-1.406-2.438q-.141-.234-.422-.141L12 4.827q-.563-.422-1.172-.703l-.281-1.828q-.047-.281-.328-.281H7.406q-.281 0-.328.281l-.281 1.875q-.656.234-1.172.656l-1.734-.703q-.281-.094-.422.188L2.063 6.703q-.188.281.047.469l1.5 1.125q-.047.375-.047.703t.047.703l-1.5 1.125q-.234.234-.047.469l1.406 2.438q.141.234.422.141l1.734-.703q.563.422 1.172.703l.281 1.828q.047.281.328.281h2.813q.281 0 .328-.281l.281-1.875q.656-.234 1.172-.656l1.734.703q.281.094.422-.188zm-5.343-2.672q-.844 0-1.43-.586T6.797 9t.586-1.43 1.43-.586 1.43.586.586 1.43-.586 1.43-1.43.586zm13.125 7.64l-.984-.703q0-.141.023-.234t.023-.234q0-.234-.047-.422l.938-.75q.188-.141.047-.281l-.891-1.547q-.047-.188-.281-.094l-1.078.422q-.375-.281-.797-.422l-.141-1.172q-.047-.234-.234-.234h-1.781q-.188 0-.234.188l-.141 1.219q-.234.047-.422.164t-.328.258l-1.125-.469q-.188-.047-.281.094l-.891 1.594q-.094.141.047.281l.938.75q-.047.188-.047.422t.047.422l-.938.75q-.141.141-.047.281l.891 1.547q.094.188.281.094l1.125-.422q.328.281.75.422l.141 1.172q.047.188.234.188h1.781q.234 0 .234-.188l.188-1.172.375-.188.375-.234 1.125.422q.141.094.234-.094l.938-1.547q.094-.141-.047-.281zm-4.313.188q-.563 0-.961-.398t-.398-.961.398-.961.961-.398.961.398.398.961-.398.961-.961.398z"/>'
  }
})
