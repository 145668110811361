/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quickreply': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 3.984q0-.797-.586-1.383t-1.383-.586H3.984q-.797 0-1.383.586t-.586 1.383v18L5.999 18h9V9.984h6.984v-6zm.516 12h-2.203L21.985 12h-4.969v6h1.969v5.016z"/>'
  }
})
