/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linear_scale': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 9.516q1.031 0 1.758.727t.727 1.758-.727 1.758-1.758.727q-1.688 0-2.297-1.5h-2.906q-.609 1.5-2.297 1.5t-2.297-1.5H6.797q-.609 1.5-2.297 1.5-1.031 0-1.758-.727t-.727-1.758.727-1.758T4.5 9.516q1.688 0 2.297 1.5h2.906q.609-1.5 2.297-1.5t2.297 1.5h2.906q.609-1.5 2.297-1.5z"/>'
  }
})
