/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.609 10.781q2.25 4.359 6.609 6.609l2.203-2.203q.469-.469 1.031-.234 1.688.563 3.563.563.422 0 .703.281t.281.703v3.516q0 .422-.281.703t-.703.281q-7.031 0-12.023-4.992T3 3.985q0-.422.281-.703t.703-.281H7.5q.422 0 .703.281t.281.703q0 1.875.563 3.563.188.609-.234 1.031z"/>'
  }
})
