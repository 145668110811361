/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr_code_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 21h-2.016v-2.016H15V21zm-2.016-6.984h-1.969v4.969h1.969v-4.969zM21 12h-2.016v3.984H21V12zm-2.016-2.016h-1.969V12h1.969V9.984zM6.984 12H5.015v2.016h1.969V12zM5.016 9.984H3V12h2.016V9.984zM12 5.016h2.016V3H12v2.016zM4.5 4.5v3h3v-3h-3zM9 9H3V3h6v6zm-4.5 7.5v3h3v-3h-3zM9 21H3v-6h6v6zm7.5-16.5v3h3v-3h-3zM21 9h-6V3h6v6zm-2.016 9.984v-3H15V18h2.016v3H21v-2.016h-2.016zM17.016 12h-4.031v2.016h4.031V12zm-4.032-2.016h-6V12H9v2.016h2.016V12h1.969V9.984zM14.016 9V6.984H12V5.015H9.984v3.984h4.031zM6.75 5.25h-1.5v1.5h1.5v-1.5zm0 12h-1.5v1.5h1.5v-1.5zm12-12h-1.5v1.5h1.5v-1.5z"/>'
  }
})
