/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_pharmacy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 14.016V12h-3V9h-1.969v3h-3v2.016h3v3h1.969v-3h3zm5.016-9v1.969l-2.016 6 2.016 6v2.016H3v-2.016l2.016-6-2.016-6V5.016h12.703L17.156.985l2.344.891-1.125 3.141H21z"/>'
  }
})
