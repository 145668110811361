/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause_presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 8.016H15v7.969h-2.016V8.016zM9 8.016h2.016v7.969H9V8.016zM21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999H3q-.797 0-1.406-.609t-.609-1.406V5.015q0-.797.609-1.406T3 3h18zm0 16.078V5.015H3v14.063h18z"/>'
  }
})
