/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 15H9v2.016H6.984V15zm0-3.984H9v1.969H6.984v-1.969zm0-4.032H9V9H6.984V6.984zM11.016 15h6v2.016h-6V15zm0-3.984h6v1.969h-6v-1.969zm0-4.032h6V9h-6V6.984zM20.109 3q.328 0 .609.258t.281.633V20.11q0 .328-.281.609t-.609.281H3.89q-.375 0-.633-.281t-.258-.609V3.891Q2.999 3 3.89 3h16.219zm-1.125 2.016H5.015v13.969h13.969V5.016z"/>'
  }
})
