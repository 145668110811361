/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_awesome_motion': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 2.016H3.985q-.797 0-1.383.586t-.586 1.383v10.031h1.969V3.985h10.031V2.016zM18 6H8.016q-.844 0-1.43.586T6 8.016V18h2.016V8.016H18V6zm2.016 3.984H12q-.844 0-1.43.586T9.984 12v8.016q0 .797.586 1.383t1.43.586h8.016q.797 0 1.383-.586t.586-1.383V12q0-.844-.586-1.43t-1.383-.586z"/>'
  }
})
