/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speaker_phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 20.016V12H9v8.016h6zm-.141-9.985q.469 0 .797.328t.328.797v9.703q0 .469-.328.797t-.797.328H9.14q-.469 0-.797-.328t-.328-.797v-9.703q0-.469.328-.82t.797-.352zM12 .984q1.875 0 4.125.938t3.609 2.297l-1.406 1.406Q15.703 3 12 3T5.672 5.625L4.266 4.219Q7.5.985 12 .985zM6.984 7.078Q9.047 5.015 12 5.015t5.016 2.063l-1.453 1.406Q14.11 7.031 12 7.031T8.437 8.484z"/>'
  }
})
