/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pest_control_rodent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.328 17.391l-2.391-2.156q.234-1.172-.211-2.133t-1.313-1.523-1.898-.563q-.469 0-1.055.164t-1.148.586-.938 1.102-.375 1.617q0 .75.281 1.383t.75 1.102l-.703.703q-.609-.609-.961-1.43t-.352-1.758q0-1.266.656-2.297t1.688-1.641q-1.125-.563-2.297-.563t-2.203.516-1.734 1.383-.984 1.992q-.938-.281-1.547-1.078t-.609-1.781q0-.844.422-1.523t1.102-1.078 1.477-.398h2.531q1.031 0 1.758-.75t.727-1.781-.727-1.758-1.758-.727h-1.5q-.422 0-.727.305t-.305.68q0 .422.305.727t.727.305h1.5q.188 0 .328.141t.141.328q0 .234-.141.375t-.328.141H6.985q-1.359 0-2.508.68T2.672 8.488t-.656 2.531q0 1.172.516 2.203t1.43 1.734 2.039.938v.047q0 1.406.68 2.555t1.828 1.828 2.555.68h8.859q.703 0 1.195-.375t.727-.961.117-1.195-.633-1.078zM18 18.984q-.422 0-.703-.281T17.016 18t.281-.703.703-.281.703.281.281.703-.281.703-.703.281z"/>'
  }
})
