/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attach_email': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 9.984v-6q0-.797-.586-1.383t-1.43-.586H3q-.844 0-1.406.586t-.563 1.383l-.047 12q0 .844.586 1.43T3 18h11.016v-5.016q0-.797.398-1.477t1.078-1.102 1.523-.422h3.984zm-9.984 1.032L3 6V3.984L11.016 9l7.969-5.016V6zm9.984 3V18q0 .844-.586 1.43t-1.43.586q-.797 0-1.383-.586T17.015 18v-4.5q0-.188.141-.352t.328-.164q.234 0 .375.164T18 13.5V18h2.016v-4.5q0-1.031-.75-1.758t-1.781-.727-1.758.727T15 13.5V18q0 1.125.539 2.016t1.453 1.43 1.992.539q1.125 0 2.039-.539t1.453-1.43.539-2.016v-3.984h-2.016z"/>'
  }
})
