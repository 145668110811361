/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_list_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.266 5.672q-.281-.328-.281-.656 0-.422.305-.727t.727-.305h13.969q.422 0 .727.305t.305.727q.047.281-.234.609l-5.766 7.359v6q0 .422-.281.727t-.703.305h-2.016q-.422 0-.727-.305t-.305-.727v-6Q4.361 5.812 4.267 5.671z"/>'
  }
})
