/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home_filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3l8.016 6v12H15v-6.984H9V21H3.984V9z"/>'
  }
})
