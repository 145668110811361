/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_top': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 12.984v-1.969h2.016v1.969H15zM18.984 21v-2.016H21V21h-2.016zM11.016 9V6.984h1.969V9h-1.969zM15 21v-2.016h2.016V21H15zm3.984-3.984V15H21v2.016h-2.016zM3 3h18v2.016H3V3zm15.984 9.984v-1.969H21v1.969h-2.016zm0-3.984V6.984H21V9h-2.016zm-7.968 8.016V15h1.969v2.016h-1.969zM3 9V6.984h2.016V9H3zm0 3.984v-1.969h2.016v1.969H3zM3 21v-2.016h2.016V21H3zm0-3.984V15h2.016v2.016H3zM11.016 21v-2.016h1.969V21h-1.969zm0-8.016v-1.969h1.969v1.969h-1.969zm-4.032 0v-1.969H9v1.969H6.984zm0 8.016v-2.016H9V21H6.984z"/>'
  }
})
