/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal_cellular_4_bar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.016 21.984L21.985 2.015v19.969H2.016z"/>'
  }
})
