/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile_friendly': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.031 13.453l5.906-5.906 1.266 1.266-7.219 7.172-3.797-3.797 1.266-1.266zM18.984.984q.797 0 1.406.609t.609 1.406v18q0 .797-.609 1.406t-1.406.609H9q-.797 0-1.406-.609t-.609-1.406v-3h2.016v2.016h9.984V3.984H9.001V6H6.985V3q0-.797.609-1.406T9 .985h9.984z"/>'
  }
})
