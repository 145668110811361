/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file_present': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.984 9.984V15q0 1.641-1.172 2.813t-2.813 1.172-2.813-1.172T8.014 15V8.484q0-1.031.727-1.758t1.758-.727h.281q.984.094 1.594.867t.609 1.758v6.375h-1.969V8.483q0-.469-.516-.469t-.516.469v6.516q0 .797.609 1.406t1.406.609 1.406-.609.609-1.406V9.983h1.969zM6 20.016h12v-12h-3.984V3.985H6v16.031zm9-18l5.016 4.969v13.031q0 .797-.609 1.383t-1.406.586h-12q-.797 0-1.406-.586t-.609-1.383V3.985q0-.797.609-1.383t1.406-.586h9z"/>'
  }
})
