/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device_thermostat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 5.016v6h1.969V9h-.984v-.984h.984V6h-.984v-.984h.984q0-.422-.281-.727t-.703-.305-.703.305-.281.727zM15 12.984q2.016 1.453 2.016 4.031 0 2.063-1.477 3.516T12 21.984t-3.539-1.453-1.477-3.516q0-2.578 2.016-4.031V5.015q0-1.219.891-2.109T12 2.015t2.109.891T15 5.015v7.969z"/>'
  }
})
