/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3L2.016 21h19.969zm.984 5.906l5.625 10.078h-5.625V8.906zm-1.968 0v10.078H5.391z"/>'
  }
})
