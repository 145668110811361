/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigate_next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.984 6l6 6-6 6-1.406-1.406L13.172 12 8.578 7.406z"/>'
  }
})
