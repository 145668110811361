/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_mall': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.984q2.063 0 3.539-1.453t1.477-3.516H15q0 1.219-.891 2.109T12 11.015t-2.109-.891T9 8.015H6.984q0 2.063 1.477 3.516T12 12.984zM12 3q-1.219 0-2.109.891T9 6h6q0-1.219-.891-2.109T12 3zm6.984 3q.797 0 1.406.609t.609 1.406v12q0 .797-.609 1.383t-1.406.586H5.015q-.797 0-1.406-.586T3 20.015v-12q0-.797.609-1.406T5.015 6h1.969q0-2.063 1.477-3.539T12 .984t3.539 1.477T17.016 6h1.969z"/>'
  }
})
