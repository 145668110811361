/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'desktop_access_disabled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 15.984h9.891L3 6.093v9.891zM14.906 18h-.891v2.016h1.969v1.969H8.015v-1.969h1.969V18H3q-.797 0-1.406-.609t-.609-1.406V4.079l-.984-.984L1.22 1.782Q2.345 2.907 8.345 8.86t9.141 9.141l4.734 4.688-1.313 1.313zm8.11-2.016q0 .797-.609 1.406t-1.406.609h-.984l-2.016-2.016h3v-12h-15L3.985 2.014h17.016q.797 0 1.406.586t.609 1.383v12z"/>'
  }
})
