/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.266 3L21 19.734 19.734 21l-4.172-4.172q-1.031.656-2.578.891V21h-1.969v-3.281q-2.484-.375-4.242-2.273t-1.758-4.43h1.688q0 2.203 1.57 3.633T12 16.079q1.172 0 2.297-.516l-1.641-1.641q-.375.094-.656.094-1.219 0-2.109-.891T9 11.016v-.75l-6-6zM15 11.156L9 5.203v-.188q0-1.219.891-2.109T12 2.015t2.109.891T15 5.015v6.141zm3.984-.14q0 1.734-.891 3.281l-1.219-1.266q.422-.938.422-2.016h1.688z"/>'
  }
})
