/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'motion_photos_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.859 20.859L3.14 3.14 1.874 4.453l1.922 1.875q-.844 1.219-1.313 2.648t-.469 3.023q0 2.063.773 3.867t2.156 3.188 3.188 2.156 3.867.773q1.594 0 3.023-.469t2.648-1.266l1.875 1.875zM12 20.016q-1.641 0-3.094-.633t-2.555-1.734-1.734-2.555T3.984 12q0-1.172.328-2.25t.891-1.969l1.5 1.453q-.328.609-.516 1.313T5.999 12q0 1.641.82 3t2.18 2.18 3 .82q.75 0 1.453-.188t1.313-.516l1.453 1.5q-.891.563-1.969.891t-2.25.328zM6.328 3.75q1.219-.797 2.648-1.266t3.023-.469q2.063 0 3.867.773t3.188 2.156 2.156 3.188.773 3.867q0 1.594-.469 3.023t-1.266 2.648l-1.453-1.453q.563-.891.891-1.969t.328-2.25q0-1.641-.633-3.094t-1.734-2.555-2.555-1.734-3.094-.633q-1.172 0-2.25.328t-1.969.891zM18 12q0 .75-.188 1.453t-.516 1.313L9.233 6.703q.609-.328 1.313-.516t1.453-.188q1.641 0 3 .82t2.18 2.18.82 3z"/>'
  }
})
