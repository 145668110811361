/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'verified': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.016 12l-2.438-2.813.328-3.656-3.609-.844-1.875-3.188L12 2.952 8.578 1.499 6.703 4.687l-3.609.797.328 3.703L.984 12l2.438 2.813-.328 3.656 3.609.844 1.875 3.188L12 21.048l3.422 1.453 1.875-3.188 3.609-.844-.328-3.656zm-12.938 4.734L6.281 12.89l1.5-1.453 2.297 2.344 5.859-5.906 1.5 1.5z"/>'
  }
})
