/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_week': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 5.016q.422 0 .727.281t.305.703v12q0 .422-.305.703t-.727.281h-3q-.422 0-.703-.281T9 18V6q0-.422.281-.703t.703-.281h3zm7.032 0q.422 0 .703.281T21 6v12q0 .422-.281.703t-.703.281h-3q-.422 0-.727-.281T15.984 18V6q0-.422.305-.703t.727-.281h3zM6 5.016q.422 0 .703.281T6.984 6v12q0 .422-.281.703T6 18.984H3q-.422 0-.703-.281T2.016 18V6q0-.422.281-.703T3 5.016h3z"/>'
  }
})
