/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'border_all': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 11.016v-6h-6v6h6zm0 7.968v-6h-6v6h6zm-7.968-7.968v-6h-6v6h6zm0 7.968v-6h-6v6h6zM3 3h18v18H3V3z"/>'
  }
})
