/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 8.016q0-1.125.539-2.039t1.453-1.453 1.992-.539q1.125 0 2.039.539t1.453 1.453.539 2.039q0 1.078-.539 1.992t-1.453 1.453T9.984 12q-1.078 0-1.992-.539t-1.453-1.453T6 8.016zm4.359 6q-.844-.047-1.922.094t-2.203.469-2.086.82-1.547 1.148T2.015 18v2.016h9.516q-.938-1.031-1.242-2.086t-.281-1.922.164-1.406.188-.586zM19.453 18q.563-.938.563-2.016t-.539-1.992-1.453-1.453T15.985 12q-1.078 0-1.992.539t-1.453 1.453-.539 1.992q0 1.125.539 2.039t1.453 1.453 1.992.539q.563 0 1.078-.164t.938-.398l2.578 2.531 1.406-1.406zm-3.469 0q-.797 0-1.383-.586t-.586-1.43q0-.797.586-1.383t1.383-.586q.844 0 1.43.586T18 15.984q0 .844-.586 1.43t-1.43.586z"/>'
  }
})
