/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard_voice': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.297 12h1.688q0 2.531-1.758 4.43t-4.242 2.273v3.281h-1.969v-3.281q-2.484-.375-4.242-2.273T5.016 12h1.688q0 2.203 1.57 3.656t3.727 1.453 3.727-1.453T17.298 12zM12 15q-1.219 0-2.109-.891T9 12V6q0-1.219.891-2.109T12 3t2.109.891T15 6v6q0 1.219-.891 2.109T12 15z"/>'
  }
})
