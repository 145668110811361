/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'volume_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3.984v4.219L9.891 6.094zM4.266 3L21 19.734 19.734 21l-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016H2.999v-6h4.734L2.999 4.264zm14.718 9q0-2.391-1.383-4.219t-3.586-2.484V3.234q3.047.656 5.016 3.117T21 11.999q0 2.203-1.031 4.172l-1.5-1.547q.516-1.266.516-2.625zM16.5 12q0 .422-.047.609l-2.438-2.438V7.968q1.031.516 1.758 1.688T16.5 12z"/>'
  }
})
