/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'videogame_asset': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 12q.656 0 1.078-.422T21 10.5t-.422-1.078T19.5 9t-1.078.422T18 10.5t.422 1.078T19.5 12zm-3.984 3q.609 0 1.055-.422t.445-1.078-.445-1.078T15.516 12t-1.055.422-.445 1.078.445 1.078 1.055.422zm-4.5-2.016v-1.969h-3v-3H6v3H3v1.969h3v3h2.016v-3h3zM21 6q.797 0 1.406.609t.609 1.406v7.969q0 .797-.609 1.406T21 17.999H3q-.797 0-1.406-.609t-.609-1.406V8.015q0-.797.609-1.406T3 6h18z"/>'
  }
})
