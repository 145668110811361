/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_library': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 8.016q-1.219 0-2.109-.891T9 5.016t.891-2.109T12 2.016t2.109.891T15 5.016t-.891 2.109T12 8.016zm0 3.515q3.75-3.516 9-3.516v10.969q-5.203 0-9 3.563-3.797-3.563-9-3.563V8.015q5.25 0 9 3.516z"/>'
  }
})
