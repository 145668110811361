/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_comment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.016 11.016V9h-4.031V5.016h-1.969V9H6.985v2.016h4.031V15h1.969v-3.984h4.031zm4.968-7.032v18L18 18H3.984q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h16.031q.797 0 1.383.586t.586 1.383z"/>'
  }
})
