/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reorder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 5.016h18v1.969H3V5.016zm0 6V9h18v2.016H3zm0 7.968v-1.969h18v1.969H3zM3 15v-2.016h18V15H3z"/>'
  }
})
