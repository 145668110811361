/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto_awesome_mosaic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 5.016v13.969q0 .844.586 1.43t1.43.586h6v-18h-6q-.844 0-1.43.586T3 5.017zM18.984 3h-6v8.016H21v-6q0-.844-.586-1.43T18.984 3zm-6 18h6q.844 0 1.43-.586t.586-1.43v-6h-8.016V21z"/>'
  }
})
