/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_size_select_small': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 3v2.016H5.015V3h1.969zm4.032 0v2.016H9V3h2.016zM3 11.016v1.969H.984v-1.969H3zM3 3v2.016H.984q0-.75.633-1.383T3 3zm15.984 15.984V21h-1.969v-2.016h1.969zm0-15.984v2.016h-1.969V3h1.969zM15 18.984V21h-2.016v-2.016H15zm-12-12V9H.984V6.984H3zM3 21q-.797 0-1.406-.609t-.609-1.406v-3.984h10.031v6H3zM21 3q.75 0 1.383.633t.633 1.383H21V3zm2.016 3.984V9H21V6.984h2.016zM15 3v2.016h-2.016V3H15zm8.016 15.984q0 .75-.633 1.383T21 21v-2.016h2.016zm0-7.968v1.969H21v-1.969h2.016zm0 3.984v2.016H21V15h2.016z"/>'
  }
})
