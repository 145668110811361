/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.016q2.063 0 3.539-1.477T17.016 12t-1.477-3.539T12 6.984 8.461 8.461 6.984 12t1.477 3.539T12 17.016zm-3-15h6l1.828 1.969h3.188q.797 0 1.383.609T21.985 6v12q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609T2.016 18V6q0-.797.586-1.406t1.383-.609h3.188zM8.813 12q0-1.313.938-2.25t2.25-.938 2.25.938.938 2.25-.938 2.25-2.25.938-2.25-.938T8.813 12z"/>'
  }
})
