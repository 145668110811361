/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mode_comment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.984 3.984v18L18 18H3.984q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h16.031q.797 0 1.383.586t.586 1.383z"/>'
  }
})
