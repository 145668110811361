/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vpn_key': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.984 14.016q.797 0 1.406-.609t.609-1.406-.609-1.406-1.406-.609-1.383.609-.586 1.406.586 1.406 1.383.609zm5.672-4.032h10.359v4.031h-2.016v3.984h-3.984v-3.984h-4.359q-.609 1.641-2.273 2.813T6.985 18q-2.484 0-4.242-1.758T.985 12t1.758-4.242T6.985 6q1.734 0 3.398 1.172t2.273 2.813z"/>'
  }
})
