/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'format_align_center': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 3h18v2.016H3V3zm3.984 3.984h10.031V9H6.984V6.984zm-3.984 6v-1.969h18v1.969H3zM3 21v-2.016h18V21H3zm3.984-6h10.031v2.016H6.984V15z"/>'
  }
})
