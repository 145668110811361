/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_attraction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.563 16.781l-1.078-4.078 3.281-2.719-4.219-.234L12 5.812 10.453 9.75l-4.266.234 3.328 2.719-1.078 4.078L12 14.484zM20.016 12q0 .797.586 1.406t1.383.609v3.984q0 .797-.586 1.406t-1.383.609H3.985q-.797 0-1.383-.609t-.586-1.406v-3.984q.844 0 1.406-.586t.563-1.43q0-.797-.586-1.406t-1.383-.609V6q0-.797.586-1.406t1.383-.609h16.031q.797 0 1.383.609T21.985 6v3.984q-.797 0-1.383.609t-.586 1.406z"/>'
  }
})
