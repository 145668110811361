/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mark_chat_read': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.344 20.016l-3.563-3.563 1.406-1.406 2.156 2.109 4.219-4.219 1.453 1.406zm-5.344-3q0-1.453.539-2.719t1.5-2.227 2.227-1.523 2.719-.563q.844 0 1.594.188t1.406.516V3.985q0-.797-.586-1.383t-1.383-.586H3.985q-.797 0-1.383.586t-.586 1.383v18L6 18.001h6q0-.234.047-.516-.047-.234-.047-.469z"/>'
  }
})
