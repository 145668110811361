/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.297 11.016h1.688q0 2.531-1.758 4.43t-4.242 2.273V21h-1.969v-3.281q-2.484-.375-4.242-2.273t-1.758-4.43h1.688q0 2.203 1.57 3.633t3.727 1.43 3.727-1.43 1.57-3.633zm-5.297 3q-1.219 0-2.109-.891T9 11.016v-6q0-1.219.891-2.109T12 2.016t2.109.891T15 5.016v6q0 1.219-.891 2.109T12 14.016z"/>'
  }
})
