/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new_releases': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.984 12.984v-6h-1.969v6h1.969zm0 4.032V15h-1.969v2.016h1.969zM23.016 12l-2.438 2.766.328 3.703-3.609.797-1.875 3.188L12 21.001l-3.422 1.453-1.875-3.141-3.609-.844.328-3.703L.984 12l2.438-2.813-.328-3.656 3.609-.797 1.875-3.188L12 2.999l3.422-1.453 1.875 3.188 3.609.797-.328 3.703z"/>'
  }
})
