/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'perm_contact_calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 18v-.984q0-1.359-2.063-2.227t-3.938-.867-3.938.867-2.063 2.227V18h12zM12 6q-1.219 0-2.109.891T9 9t.891 2.109T12 12t2.109-.891T15 9t-.891-2.109T12 6zm6.984-3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609H5.015q-.844 0-1.43-.586t-.586-1.43V5.014q0-.844.586-1.43t1.43-.586h.984V.982h2.016v2.016h7.969V.982H18v2.016h.984z"/>'
  }
})
