/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_circle_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3.984q1.641 0 3.094.633t2.555 1.734 1.734 2.555.633 3.094-.633 3.094-1.734 2.555-2.555 1.734-3.094.633-3.094-.633-2.555-1.734-1.734-2.555T3.984 12t.633-3.094 1.734-2.555 2.555-1.734T12 3.984zm0-1.968q-2.063 0-3.867.773T4.945 4.945 2.789 8.133 2.016 12t.773 3.867 2.156 3.188 3.188 2.156 3.867.773 3.867-.773 3.188-2.156 2.156-3.188.773-3.867-.773-3.867-2.156-3.188-3.188-2.156T12 2.016zM12.984 12V8.016h-1.969V12h-3l3.984 3.984L15.983 12h-3z"/>'
  }
})
