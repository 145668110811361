/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji_people': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.891 8.109l4.453 4.5-1.406 1.406L15 10.031v11.953h-2.016v-6h-1.969v6H8.999V8.718Q6.796 8.062 5.39 6.21T3.984 2.015H6q0 2.063 1.453 3.516t3.516 1.453h2.578q1.219 0 2.344 1.125zM9.984 3.984q0-.844.586-1.406T12 2.015t1.43.563.586 1.406-.586 1.43T12 6t-1.43-.586-.586-1.43z"/>'
  }
})
