/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_forward_ios': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.859 4.125l2.156-2.109L17.999 12l-9.984 9.984-2.156-2.109L13.781 12z"/>'
  }
})
