/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'agriculture': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 12q.703 0 1.336.211t1.148.539V8.016q0-.844-.586-1.43T20.015 6h-6.328l-1.031-1.078 1.406-1.406-.703-.703-3.563 3.516.75.75 1.406-1.406 1.031 1.031v2.297q0 .844-.586 1.43t-1.383.586h-.563q.703.797 1.125 1.805t.422 2.18q0 .234-.023.492t-.07.492h3.141q.141-1.125.75-2.016t1.57-1.43 2.133-.539zm0 .984q-.984 0-1.758.492t-1.266 1.266-.492 1.758.492 1.758 1.266 1.266 1.758.492 1.758-.492 1.266-1.266.492-1.758-.492-1.758-1.266-1.266-1.758-.492zm0 5.016q-.609 0-1.055-.445T18 16.5t.445-1.055T19.5 15t1.055.445T21 16.5t-.445 1.055T19.5 18zM3.984 9H9q0-.844-.586-1.43t-1.43-.586h-3q-.375 0-.68.305t-.305.727q0 .375.305.68t.68.305zm5.86 4.828l-.188-.469.938-.375q-.703-1.594-2.344-2.438l-.375.891-.469-.188.422-.891Q6.937 9.983 6 9.983q-.375 0-.773.07t-.727.211l.328.891-.469.188-.375-.938q-1.594.703-2.438 2.344l.891.375-.188.469-.891-.422q-.375.891-.375 1.828 0 .375.07.773t.211.727l.891-.328.188.469-.938.375q.703 1.594 2.344 2.438l.375-.891.469.188-.422.891q.891.375 1.828.375.375 0 .773-.07t.727-.211l-.328-.891.469-.188.375.938q1.594-.703 2.438-2.344l-.891-.375.188-.469.891.422q.375-.891.375-1.828 0-.375-.07-.773t-.211-.727zm-2.672 3.938q-.797.328-1.57.211t-1.406-.586-.961-1.219q-.328-.797-.211-1.57t.586-1.406 1.219-.961q.797-.328 1.57-.211t1.406.586.961 1.219q.328.797.211 1.57t-.586 1.406-1.219.961z"/>'
  }
})
