/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cast_connected': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406T21 20.999h-6.984v-2.016H21V5.014H3v3H.984v-3q0-.797.609-1.406t1.406-.609h18zM.984 9.984q4.547 0 7.781 3.234t3.234 7.781H9.983q0-3.75-2.648-6.375T.983 11.999V9.983zm18-3v10.031h-5.625q-.938-2.953-3.164-5.203t-5.18-3.188V6.983h13.969zm-18 7.032q2.906 0 4.969 2.039T8.016 21H6q0-2.063-1.477-3.539T.984 15.984v-1.969zm0 3.984q1.219 0 2.109.891T3.984 21h-3v-3z"/>'
  }
})
