/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'local_shipping': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 18.516q.656 0 1.078-.445t.422-1.055-.422-1.055T18 15.516t-1.078.445-.422 1.055.422 1.055 1.078.445zm1.5-9h-2.484V12h4.453zm-13.5 9q.656 0 1.078-.445t.422-1.055-.422-1.055T6 15.516t-1.078.445-.422 1.055.422 1.055T6 18.516zm14.016-10.5l3 3.984v5.016H21q0 1.219-.891 2.109T18 20.016t-2.109-.891T15 17.016H9q0 1.219-.891 2.109T6 20.016t-2.109-.891T3 17.016H.984V6q0-.797.609-1.406t1.406-.609h14.016v4.031h3z"/>'
  }
})
