/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swap_vertical_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.484 15H15v-3.984h-2.016V15H10.5l3.516 3.516zM6.516 9H9v3.984h2.016V9H13.5L9.984 5.484zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055T12 21.986t-7.055-2.93-2.93-7.055 2.93-7.055T12 2.016z"/>'
  }
})
