/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file_copy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.016 12H19.5l-5.484-5.484V12zM15 5.016l6 6V21q0 .797-.609 1.406t-1.406.609H7.969q-.797 0-1.383-.609T6 21V6.984q0-.797.609-1.383t1.406-.586h6.984zm.984-4.032V3h-12v14.016H2.015V3q0-.797.586-1.406T3.984.985h12z"/>'
  }
})
