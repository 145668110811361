/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hourglass_full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 2.016h12v6L14.016 12 18 15.984v6H6v-6L9.984 12 6 8.016v-6z"/>'
  }
})
