/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'connect_without_contact': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.016 14.016H9q0-1.875.703-3.516t1.945-2.859 2.859-1.922 3.492-.703v1.969q-1.453 0-2.719.563t-2.227 1.523-1.5 2.227-.539 2.719zm6.984-3V9q-1.359 0-2.508.68t-1.828 1.805-.68 2.531H15q0-.844.398-1.523t1.078-1.078 1.523-.398zM6.984 3.984q0-.797-.586-1.383t-1.383-.586q-.844 0-1.43.586t-.586 1.383q0 .844.586 1.43T5.015 6q.797 0 1.383-.586t.586-1.43zm4.454.516H9.469q-.188 1.078-1.008 1.781t-1.945.703h-3q-.656 0-1.078.445t-.422 1.055v2.531h6V8.718q1.359-.422 2.32-1.57T11.438 4.5zm7.546 12.516q.844 0 1.43-.586T21 15t-.586-1.43-1.43-.586q-.797 0-1.383.586T17.015 15t.586 1.43 1.383.586zm1.5.984h-3q-1.125 0-1.945-.727t-1.008-1.758h-1.969q.141 1.5 1.102 2.648t2.32 1.57v2.25h6v-2.484q0-.609-.422-1.055t-1.078-.445z"/>'
  }
})
