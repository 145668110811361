/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snippet_folder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.891 10.5l1.594 1.641v3.375h-3V10.5h1.406zm6.093-2.484V18q0 .844-.586 1.43t-1.383.586H3.984q-.797 0-1.383-.586T2.015 18V6q0-.844.586-1.43t1.383-.586h6L12 6h8.016q.797 0 1.383.586t.586 1.43zm-3 3.468L16.5 9h-3.516v8.016h6v-5.531z"/>'
  }
})
