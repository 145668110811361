/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 8.016V6H6v2.016h12zm0 3V9H6v2.016h12zm0 3V12H6v2.016h12zm3.984-10.032v18L18 18H3.984q-.797 0-1.383-.609t-.586-1.406v-12q0-.797.586-1.383t1.383-.586h16.031q.797 0 1.383.586t.586 1.383z"/>'
  }
})
