/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings_input_hdmi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.016 3.984v3h1.969V5.015h1.031v1.969h1.969V5.015h1.031v1.969h1.969v-3H8.016zm9.984 3h.984v6l-3 6v3H8.015v-3l-3-6v-6h.984v-3q0-.797.609-1.383t1.406-.586h7.969q.797 0 1.406.586t.609 1.383v3z"/>'
  }
})
