/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'live_tv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 9.984l6.984 4.031L9 17.999V9.983zm12 10.032v-12H3v12h18zM21 6q.844 0 1.43.586t.586 1.43v12q0 .797-.609 1.383t-1.406.586h-18q-.797 0-1.406-.586t-.609-1.383v-12q0-.844.586-1.43T3.002 6h7.594L7.315 2.719l.703-.703L12.002 6l3.984-3.984.703.703L13.408 6h7.594z"/>'
  }
})
