/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compare': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 3q.797 0 1.406.609t.609 1.406v13.969q0 .797-.609 1.406t-1.406.609h-4.969v-9l4.969 6V5.015h-4.969V2.999h4.969zm-9 15v-6l-4.969 6h4.969zm0-15V.984H12v22.031H9.984v-2.016H5.015q-.797 0-1.406-.609T3 18.984V5.015q0-.797.609-1.406T5.015 3h4.969z"/>'
  }
})
