/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'desktop_windows': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15.984v-12H3v12h18zm0-13.968q.797 0 1.406.586t.609 1.383v12q0 .797-.609 1.406T21 18h-6.984v2.016h1.969v1.969H8.016v-1.969h1.969V18H3.001q-.797 0-1.406-.609t-.609-1.406v-12q0-.797.609-1.383t1.406-.586h18z"/>'
  }
})
